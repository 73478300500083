import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Card, Col, message, Row, Spin, Typography, Button } from 'antd';
import { getDailyAppointmentData, getAppointmentStatuses } from 'api/dashboard';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import TodayStatistics from 'components/Dashboard/TodayStatistics';
import { Column } from '@ant-design/plots';
import _ from 'lodash';
import { serverDateToUi } from 'utils/date-formatter';
import { Days } from 'utils/enums';
import { getAllLocations } from 'api/locations';
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';

const { Text } = Typography;

const Dashboard = () => {
    const [params, setParams] = useState({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [clinicColor, setClinicColor] = useState([]);
    const [weeklyData, setWeeklyData] = useState([]);
    const [fetching, setFetching] = useState(true);

    const [dailyAverages, setDailyAverages] = useState(null);
    const [customFilters, setCustomFilters] = useState([]);

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [facilityValue, setFacilityValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [clinicValue, setClinicValue] = useState(null);
    const [locations, setLocations] = useState([]);

    const setLocationParams = (data) => {
        const locationObj = getLocationValues(data);
        if (locationObj.facility) {
          setFacilityValue(locationObj.facility);
        }
        if (locationObj.department) {
          setDepartmentValue(locationObj.department);
        }
        if (locationObj.clinic) {
          setClinicValue(locationObj.clinic);
        }
        if(locationObj.department) {
            const paramName = 'locationId';
            const filters = [...customFilters];
            const hospitalFilter = filters.filter(
                (filter) => filter.paramName === paramName
            )[0];

            if (hospitalFilter) {
                hospitalFilter.value = locationObj.department;
            } else {
                filters.push({ paramName: paramName, value: locationObj.department });
            }
            setCustomFilters(filters);
        }
      }

    const fetchLocationsData = async () => {
        let res = await getAllLocations();
        setLocations(res.data.data);
        setLocationParams(res.data.data);
    }

    const renderWeekDataRef = useRef(false);

    const checkForLocationAll = (params) => {
        let newParams = { ...params };

        if (params?.locationId === undefined) {
            delete newParams.locationId
            params?.clinicId && delete params.clinicId
            setParams({ ...params, locationId: 'ALL' })
        }

        if (params?.locationId === 'ALL') {
            delete newParams.locationId
            newParams.clinicId && delete newParams.clinicId
        }

        if (params?.clinicId && params?.locationId !== 'ALL') {
            newParams.locationId = params.clinicId;
        }

        return newParams;
    }

    // const { isLoading, error, refetch } = useQuery('getDashboardSummary', async () => {
    //     if (!_.isEmpty(params)) {
    //         let newParams = checkForLocationAll(params);
    //         newParams.clinicId && delete newParams.clinicId
    //         let res = await getDashboardSummary(newParams);
    //     }
    // }, {
    //     refetchInterval: REFETCH_INTERVAL_IN_MILLISECONDS
    // });

    const uiState = useSelector(state => state.ui);

    const ref = useRef(null);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        // if (customFilters?.length > 0) {
        let date = endDate ? new Date(endDate) : new Date();
        setEndDate(formatDate(moment(date).add(3, 'days')));
        setStartDate(formatDate(moment(date).add(-3, 'days')));
        // }
        fetchLocationsData();
    }, []);

    useEffect(() => {
        if (uiState?.locations?.length > 0) {
            let newParams = params;
            newParams.start = moment().format('YYYY-MM-DD');
            newParams.end = moment().format('YYYY-MM-DD');
            setParams(newParams);
        }
    }, [uiState, uiState.locations]);

    useEffect(() => {
        if (startDate && endDate && customFilters?.length > 0) {
            fetchAppointmentStatuses();
            setLoading(true);
            setData([]);
            fetchWeeklyAppointmentData();
            setFetching(false);
        };
    }, [startDate, endDate, customFilters]);

    const fetchAppointmentStatuses = () => {
        getAppointmentStatuses({ departmentId: customFilters[0].value })
            .then(res => {
                setLoading(false);
                setWeeklyData(res?.data);
            }).catch(err => {
                setLoading(false);
                message.error(err.toString());
            });
    };

    useEffect(() => {
        if (weeklyData) {
            let recordCount = {
                "timeInQueueLength": _.filter(weeklyData, function(item) { if (item?.stats?.timeInQueueSec > 0 ) return item }).length,
                "waitingForConsultLength":_.filter(weeklyData, function(item) { if (item?.stats?.waitingForConsultSec > 0 ) return item }).length,
                "timeInConsultLength":_.filter(weeklyData, function(item) { if (item?.stats?.timeInConsultSec > 0 ) return item }).length,
            }
            let average = {
                "CONFIRMED": _.sumBy(weeklyData, (item) => item?.stats?.CONFIRMED ?? 0),
                "UNCONFIRMED": _.sumBy(weeklyData, (item) => item?.stats?.UNCONFIRMED ?? 0),
                "CHECKED_IN": _.sumBy(weeklyData, (item) => item?.stats?.CHECKED_IN ?? 0),
                "REQUEST_RESCHEDULE": _.sumBy(weeklyData, (item) => item?.stats?.REQUEST_RESCHEDULE ?? 0),
                "START_CONSULT": _.sumBy(weeklyData, (item) => item?.stats?.START_CONSULT ?? 0),
                "NO_SHOW": _.sumBy(weeklyData, (item) => item?.stats?.NO_SHOW ?? 0),
                "CHECKED_OUT": _.sumBy(weeklyData, (item) => item?.stats?.CHECKED_OUT ?? 0),
                "CANCELLED": _.sumBy(weeklyData, (item) => item?.stats?.CANCELLED ?? 0),
                "timeInQueueSec": _.sumBy(weeklyData, (item) => item?.stats?.timeInQueueSec ?? 0) / recordCount.timeInQueueLength,
                "waitingForConsultSec": _.sumBy(weeklyData, (item) => item?.stats?.waitingForConsultSec ?? 0) / recordCount.waitingForConsultLength,
                "timeInConsultSec": _.sumBy(weeklyData, (item) => item?.stats?.timeInConsultSec ?? 0) / recordCount.timeInConsultLength,
            };
            setDailyAverages(average);
        }
    }, [weeklyData])

    useEffect(() => {
        let color = getUniqueListBy(data, 'clinicName');
        setClinicColor(color);
    }, [data])

    const fetchWeeklyAppointmentData = () => {
        getDailyAppointmentData({ start: startDate, end: endDate, departmentId: customFilters[0].value })
            .then(res => {
                setLoading(false);
                let arr = [];
                res?.data && res?.data?.length > 0 && res.data.map(item => {
                    // if (item.stats && (item.stats.length ?? 0)) {
                    let date = moment(item.date);
                    item.stats.map(subItem => {
                        arr.push({
                            "date": `${serverDateToUi(item.date)}\n${Days[date.day()]}`,
                            "clinic": subItem.clinic ?? {},
                            "total": subItem.total ?? 0,
                            "clinicName": subItem.clinic.name ?? ''
                        })
                    })

                    if (item?.stats.length === 0) {
                        arr.push({
                            "date": `${serverDateToUi(item.date)}\n${Days[date.day()]}`,
                        })
                    }

                    // }
                })
                setData(arr);

            }).catch(err => {
                setLoading(false);
                message.error(err.toString());
            })
    }

    const getUniqueListBy = (arr, key) => {
        let clinicArr = [];
        arr &&
            arr.length > 0 &&
            [...new Map(arr?.map((item) => [item[key], item])).values()].map(
                (item) => {
                    let obj = item?.clinic?.metadata ? item?.clinic?.metadata : [];
                    if (item?.total === null) {
                        clinicArr.push('#cccfff');
                    } else {
                       obj.length > 0  && clinicArr.push(
                            (obj && (obj.find((f) => f.key === 'DashboardColour')) !== undefined)
                                ? obj.find((f) => f.key === 'DashboardColour')?.value
                                : '#fcba03'
                        );
                    }
                }
            );
        return clinicArr;
    };

    const config = {
        data: data.length > 0 ? data : [],
        isStack: true,
        xField: 'date',
        yField: 'total',
        seriesField: 'clinicName',
        color: clinicColor,
        legend: { position: 'top' },
        label: {

            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };

    const viewLastWeek = () => {
        let date = new Date(startDate)
        setEndDate(formatDate(moment(date).add(-1, 'days')));
        setStartDate(formatDate(moment(date).add(-7, 'days')));
        setFetching(true);
    }

    const viewNextWeek = () => {
        let date = new Date(endDate);
        setStartDate(formatDate(moment(date).add(1, 'days')));
        setEndDate(formatDate(moment(date).add(7, 'days')));
        setFetching(true);
    }

    const showAnimation = !renderWeekDataRef;
    return (
        <>
            <Spin spinning={loading}>
                <Row>
                    <Col>
                        <LocationSelect
                            locationData={locations}
                            facilityValue={facilityValue}
                            departmentValue={departmentValue}
                            clinicValue={clinicValue}
                            width='275px'
                            direction='horizontal'
                            onChange={(value, isDepartment, action, locationObj) => {
                                if(locationObj?.department) {
                                    const paramName = 'locationId';
                                    const filters = [...customFilters];
                                    const hospitalFilter = filters.filter(
                                        (filter) => filter.paramName === paramName
                                    )[0];
                        
                                    if (hospitalFilter) {
                                        hospitalFilter.value = locationObj.department;
                                    } else {
                                        filters.push({ paramName: paramName, value: locationObj.department });
                                    }
                                    setCustomFilters(filters);
                                } else {
                                    setCustomFilters([]);
                                }
                            }}
                            showSearchValue={true}
                            isClinicVisible={false}
                            removeAllOption={true}
                        />
                    </Col>
                </Row>
                {customFilters && customFilters?.length > 0 && <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card style={{ height: '100%' }}
                            title={
                                <Text level={2} style={{ fontSize: 25, marginLeft: 10 }}>
                                    Expected Patients
                                </Text>
                            }>
                            <div style={{
                                marginBottom: '15px'
                            }} >
                                <Button
                                    id="DashboardPreviousDaysButton"
                                    type='primary'
                                    onClick={() => viewLastWeek()}
                                    disabled={fetching}
                                    style={{
                                        borderRadius: '4px'
                                    }}
                                >
                                    <ArrowLeftOutlined /> Previous 7 Days
                                </Button>
                                <Button
                                    id="DashboardNextDaysButton"
                                    type='primary'
                                    onClick={() => viewNextWeek()}
                                    disabled={fetching}
                                    style={{
                                        float: 'right',
                                        borderRadius: '4px',
                                    }}
                                >
                                    Next 7 Days <ArrowRightOutlined />
                                </Button>
                            </div>
                            <Column
                                {...config}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <Card
                            bodyStyle={{
                                padding: 0
                            }}
                            title={
                                <Text level={2} style={{ fontSize: 25, marginLeft: 10 }}>
                                    Today
                                </Text>
                            }
                            style={{ height: '100%' }}>
                            <TodayStatistics
                                title="All"
                                color={'#6395F9'}
                                showAnimation={showAnimation}
                                averages={dailyAverages}
                            />
                        </Card>
                    </Col>
                    {
                        console.log(dailyAverages)}

                    {weeklyData && (weeklyData?.length > 0) && weeklyData?.map((data, index) =>
                        <Col xs={24} sm={24} md={24} lg={12} key={index} >
                            <Card
                                bodyStyle={{
                                    padding: 0
                                }}
                                style={{ height: '100%' }}
                            >
                                {
                                    <TodayStatistics
                                        title={data?.clinic?.name}
                                        color={data?.clinic?.metadata && data?.clinic?.metadata.find(f => f.key === 'DashboardColour') ? data?.clinic?.metadata.find(f => f.key === 'DashboardColour')?.value : '#6395F9'}
                                        showAnimation={showAnimation}
                                        data={data ? data : {}}
                                    />
                                }
                            </Card>
                        </Col>
                    )
                    }
                </Row>
                }
                <br />
            </Spin>
        </>
    );
};

export default Dashboard;