import React, { useEffect, useState } from 'react';

import 'App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sb-admin-2.scss'
import 'style.scss'
import 'antd/dist/antd.css';
import MSAuthPage from 'components/landingRoute/MsAuthPage';
import GlobalStyle from 'theme';
import { useSelector } from 'react-redux';
import { getBrandingByTenant, getBrandingLogoByTenant, updateThemeSettings } from 'api/branding';
import { useDispatch } from 'react-redux';
import { useAuthContext } from "hooks";
import { setBrandingTenantUniqueId } from 'redux/actions/branding';
import { setTenantBranding, setTenantBrandingLogo } from 'redux/actions/tenant';
import { createGoogleFontLinkTag } from 'utils';

function App() {
    const [landing, setLanding] = useState(<MSAuthPage />);
    const dispatch = useDispatch();

    const tenant = useSelector(state => state.tenant);
    const { branding } = tenant;

    const buildGoogleFontApiSource = (typography) => {
        if (typography?.fontUrl) {
            createGoogleFontLinkTag(typography.fontUrl);
        }
    }

    const setPageTitle = () => {
        if (branding.page.appTitle) {
            document.title = 'Admin Portal - ' + branding.page.appTitle
        }
    }

    // determine auth type
    useEffect(() => {
        getBrandingByTenant(tenant.uniqueId)
            .then(response => {
                const responseData = response.data.data;
                const branding = JSON.parse(responseData.theme);

                if (branding) {
                    if (!branding?.theme?.palette?.status) {
                        updateThemeSettings('Default', tenant.branding).then(() => {
                            getBrandingByTenant(tenant.uniqueId).then((response) => {
                                buildGoogleFontApiSource(branding.theme.typography);
                                const responseData = response.data.data;
                                const branding = JSON.parse(responseData.theme);
                                if (branding) {
                                    dispatch(setTenantBranding(branding));
                                }
                            })
                        });
                    }
                    else {
                        buildGoogleFontApiSource(branding.theme.typography);

                        if (branding) {
                            // dispatch(setBrandingTenantUniqueId(tenant.uniqueId));
                            dispatch(setTenantBranding(branding));
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error);

                if (branding) {
                    // dispatch(setBrandingTenantUniqueId(tenant.uniqueId));
                    dispatch(setTenantBranding(branding));
                }
            });
        // todo for multi auth, create auth page that allows them
        //  to select which auth and redirects to respective auth
        setLanding(<MSAuthPage />)
    }, []);

    useEffect(() => {
        setPageTitle();
    }, [branding.page]);

    return <>
        <GlobalStyle branding={branding} />
        {landing}
    </>;
}

export default App;