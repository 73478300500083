import React, {useEffect, useState} from 'react';
import {Button, Spin} from 'antd';
import {useAuthContext} from 'hooks/index';
import { Container, Col, Row } from 'react-bootstrap';
import {GrWindows} from 'react-icons/all';
import {LoadingOutlined} from '@ant-design/icons';
import { useSelector } from 'react-redux';

import 'styles/MSLanding.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/NSWHLogo.scss';
import {getBrandingLogoByTenant} from "api/branding";
import {DEFAULT_LOGO, POWERED_BY_LOGO} from "../../api/config";

export default ({ isLoading }) => {
    const { login } = useAuthContext();
    const tenantState = useSelector((state) => state.tenant);
    const loaderIcon = <LoadingOutlined spin/>
    const [currentLogo, setCurrentLogo] = useState(DEFAULT_LOGO);

    useEffect(()=> {
      if (tenantState.uniqueId) {
        setCurrentLogo(getBrandingLogoByTenant(tenantState.uniqueId))
      } else {
        setCurrentLogo(DEFAULT_LOGO)
      }
    }, [tenantState.uniqueId])

    return (
      <>
        <Container className='Admin-Landing-Container'>
          <Row className='Container-Header'>
            <Col>
              <img className='NSWH-Logo' src={currentLogo} onError={() => setCurrentLogo(DEFAULT_LOGO)}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 style={{fontSize: '3rem'}}>{tenantState.branding.page.appTitle || 'Digital Patient Journey'}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              {
                isLoading ? <Spin indicator={loaderIcon}/> :
                  <Button
                    id='signinButton'
                    type='primary'
                    style={{fontSize: '2rem', height: 'auto'}}
                    size='large'
                    onClick={login}>
                    <GrWindows className='font-larger'/> &nbsp; Sign In with Microsoft
                  </Button>
              }
            </Col>
          </Row>
        </Container>
        <div className='PoweredBy'>
          <div className='text-right'>
            <small>
              <a href='https://fivefaces.com.au' target="_blank">
                <img src={POWERED_BY_LOGO}
                     alt='Five Faces Logo'
                     style={{height: '30px', width: 'auto'}}
                />
              </a>
            </small>
          </div>
        </div>
      </>
    );
}