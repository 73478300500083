import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { insertSpaceBeforeCapitalLetter } from 'utils';

export default ({ label = '', value = '#ddd', onChangeComplete, ...props }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(value);

    useEffect(
        () => {
            setColor(value);
        },
        [value]
    );

    const styles = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '36px',
                borderRadius: '2px',
                background: `${color}`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '999999999',
                marginTop: '-30px',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const handleClick = () => {
        if (!props.isReadOnly) {
            setDisplayColorPicker(!displayColorPicker);
        }
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = color => {
        setColor(color.hex);
    };

    const handleChangeComplete = (color, event) => {
        if (onChangeComplete) {
            onChangeComplete(color);
        }
    };

    return (
        <div
            style={{
                margin: '5px',
            }}
        >
            <div id={`BrandingLogo${insertSpaceBeforeCapitalLetter(label)?.replace(" ","")}`} style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            <p style={{ textTransform: 'uppercase', fontSize: '10px', fontWeight: '700' }}>
                {insertSpaceBeforeCapitalLetter(label)}
            </p>
            {displayColorPicker
                ? <div style={styles.popover}>
                      <div style={styles.cover} onClick={handleClose} />
                      <SketchPicker color={color} onChange={handleChange} onChangeComplete={handleChangeComplete} disable />
                  </div>
                : null}
        </div>
    );
};
