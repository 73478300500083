import { Menu, Dropdown ,Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {useMediaQuery, useTheme} from "@material-ui/core";
import { CaretDownOutlined } from '@ant-design/icons'

const StyledMenuItem = styled(Menu.Item)`
    & .anticon {
        font-size: 20px !important;
    }
`;

const ActionMenuDropDown = ({ menus, className, disabled }) => {
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderMenus = (
        <Menu>
            { menus.map((menu,index) =>
                !menu.hide && index > 0 &&
                <>
                    {
                        menu.dividerBefore && <Menu.Divider />
                    }
                    <StyledMenuItem
                        id={menu?.id ?? ''}
                        key={index}
                        icon={menu.icon}
                        onClick={() =>  !menu.link ? menu.onClick() : history.push(menu.link)}
                    >
                        {menu.label}
                    </StyledMenuItem>
                </>
            )}
        </Menu>
    );

    return (
      <>
        {menus && menus.length > 1 ? (
          <Dropdown.Button
            className = {className}
            id={menus[0]?.id}
            type='primary'
            trigger={fullScreen ? ['click'] : ['hover']}
            overlay={renderMenus}
            icon={<CaretDownOutlined id='ActionDropDownHoverIcon' />}
            onClick={() => !disabled ?  menus[0]?.onClick(): null}
          >
            {menus[0]?.label}
          </Dropdown.Button>
        ) : (
          <Button
            className = {className}
            type='primary'
            id={menus[0]?.id}
            onClick={() => menus[0]?.onClick()}
          >
            {menus[0]?.label}
          </Button>
        )}
      </>
    );
}

export default ActionMenuDropDown;