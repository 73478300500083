import React, {useRef, useEffect, useState} from "react";
import {Typography, Modal, Row, Col, message} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import * as yup from "yup";
import titleize from 'titleize';
import { CloseOutlined } from "@ant-design/icons";


const {Title} = Typography;

const entity = `metadata`;


const CreateMetadataModal = ({visible, setVisibility, icon, locationId, onSuccessAdd, onCancel, name,metadata,   ...props}) => {
  const formikRef = useRef(null);

  const schema = yup.object().shape({
    name: yup.string().test('name', 'Name already exists', (value) => {
      if(metadata.length !== 0) {
        for(let i=0; i<metadata.length; i++) {
          if(metadata[i].key === value) {
            return false;
          }
        }
      }
      return true;
    }).required("Please enter a name."),
    value: yup
      .string()
      .min(2, "Too short.")
      .max(255, "Too long.")
      .required("Please enter a value."),
  });
  const initialValues = {
    name: name ? name : '',
    value: ""
  };

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])
  const [metadataValue, setMetadataValue] = useState('');

  async function create(values) {
    try {
      let entity = {
        key: values.name,
        value: metadataValue,
      }
     
      onSuccessAdd(entity);
      message.success(`${titleize('metadata')} successfully sent.`);
      setVisibility(false)
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0"
                    style={{display: "flex", alignItems: "center"}}>{icon}{!name ? `Add ${titleize(entity)}` : `Re: ${name}` }</Title>}
      visible={visible}
      onCancel={() => { setVisibility(false); onCancel(); }}
      closeIcon={(<CloseOutlined id='closeCreateMetadataModal' />)}
      footer={null}
      destroyOnClose={true}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values => create(values)}
        render={() => (
          <Form layout="vertical" colon={false}>
            { !name &&
              <Form.Item label="Name" name="name" className="mb-4">
                <Input
                id='createMetaDataNameInput'
                  name="name"
                />
              </Form.Item>
            }
            <Form.Item label="Value" name="value" className="mb-4">
              {/*<Input.TextArea*/}
              {/*  name="message"*/}
              {/*  autoSize={{ minRows: 3, maxRows: 10 }}*/}
              {/*/>*/}
              <Input.TextArea
               id='createMetaDataValueTextarea'
                // autoSize={{
                //   minRows: 5,
                //   maxRows: 7,
                // }}
                  name="value"
                style={{width: '100%'}}
                onChange={e => setMetadataValue(e.target.value)}
              />
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton  id='createMetaDataResetButton'>Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton  id='createMetaDataCreateButton'>Create</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateMetadataModal;
