import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const locationApi = createAxios({
    baseURL: BASE_API_URL + '/locations'
});

export const getLocations = (params) => {
    return locationApi.get('/location', {
        params: params
    });
}

export const getLocationTypeList = () => {
    return locationApi.get('/list/type', {
    });
}

export const getLocation = id => {
    return locationApi.get(`/${id}`)
}

export const createLocation = (location, moduleName = '') => {
    return locationApi.post(moduleName === 'Department'? '?addReception=true' : '', location)
}

export const updateLocation = (id, location) => {
    return locationApi.put(`/${id}`, location)
}

export const getAllLocations = () => {
    return locationApi.get('/user-tree');
}

export const getUserLocations = () => {
    return locationApi.get('/user-tree');
}


export const getLocationTree = () => {
    return locationApi.get(`/tree`)
}

export const getLocationTreeDatatable = (params) => {

    return locationApi.get(`/`, {
        params: {
            page: params.page,
            draw: 1,
            start: params.start,
            length: params.length,
            search: params.search,
            sortColumn: params.sortColumn,
            sortOrder: params.sortOrder,
            sortDirection: params.sortDirection,
            appointmentId: params.appointmentId,
            facility: params.facility,
            department: params.department,
            clinic: params.clinic,
        }
    });
}

export const getLocationChildren = id => {
    return locationApi.get(`/tree/${id}`)
}

export const deleteLocation = id => {
    // return locationApi.delete(`${id}`);
    // this is how it was defined in the api...
    return locationApi.delete(`${id}?id=${id}`);
}

export const getLocationPreferences = (id) => {
    return locationApi.get(`/${id}`)
}

export const updateLocationPreferences = (preference) => {
    return locationApi.put(`/updateMessagepreferences`, preference)
}
