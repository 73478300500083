import { Col, Row } from 'react-bootstrap';
import { Button, Popover, Spin } from "antd";
import FilterComponent from "./FilterComponent";
import { FiFilter } from "react-icons/all";
import _ from 'lodash';
import { useSelector } from 'react-redux';

const DataTableFilter = ({ filterState: { filters, setFilters }, filtersConfig, data = [], filterDisplayColumn = '', filterOnChange }) => {

    const isLoading = useSelector(state => state.filterLoading);
    const content =
    <Spin spinning={isLoading}>
        <div>
            <Row>
                <Col xs={6}>
                    <strong>Filters</strong>
                </Col>
                <Col xs={6}>
                    <Button
                        style={{ float: 'right' }}
                        type='link'
                        id='dataTableClear'
                        onClick={() => {
                            const newValue = { ...filters };
                            Object.keys(newValue).forEach(prop => newValue[prop].value = null);
                            setFilters(newValue);
                            filterOnChange && filterOnChange({},'', {}, true)
                        }}
                    >Clear</Button>
                </Col>
            </Row><hr />
            {
                filtersConfig && filtersConfig.map((filterConfig, index) =>
                    filterConfig && !filterConfig.hidden
                        ? <Row key={filterConfig.paramName} className='mb-2'>
                            <Col xs={12} sm={3}>
                                <span>{filterConfig.label}</span>
                            </Col>
                            <Col xs={12} sm={9}>
                                <FilterComponent
                                    value={filters[filterConfig.paramName]}
                                    component={filterConfig.component}
                                    onChange={value => {
                                        let displayText = '';
                                        if (filterOnChange !== undefined) {
                                            displayText = filterOnChange(filterConfig, value, filters)
                                        }

                                        if (data && data?.length > 0) {

                                            const filteredData = data[0].find((item) => item.id === value);
                                            displayText = _.get(filteredData, filterDisplayColumn[0]);
                                        }

                                        const newValue = { ...filters };
                                        filterConfig?.isDisplayName ? newValue[filterConfig.paramName] = {
                                            value,
                                            label: filterConfig.label,
                                            beforeRequest: filterConfig.beforeRequest,
                                            displayFormat: filterConfig.displayFormat,
                                            displayText: displayText,
                                            isDisplayName: true
                                        } : newValue[filterConfig.paramName] = {
                                            value,
                                            label: filterConfig.label,
                                            beforeRequest: filterConfig.beforeRequest,
                                            displayFormat: filterConfig.displayFormat,
                                        };

                                        setFilters(newValue);
                                    }}
                                />
                            </Col>
                        </Row>
                        : null
                )
            }
        </div>
    </Spin>


    return (
        filtersConfig && filtersConfig.length > 0
            ? <>
                <Popover
                    trigger='click'
                    content={content}
                    placement='bottomRight'
                >
                    <Button  id='dataTableFilter' type='link'><FiFilter /> Filters</Button>
                </Popover>
            </>
            : null
    );
}

export default DataTableFilter;