import {useState, useEffect, useRef} from 'react';
import {Select} from 'antd';
import styled from 'styled-components';


const StyledDiv = styled.div`
    &:hover {
        color: #0170fe
    }
`;

const SelectCell = ({
                          editable,
                          options,
                          dataIndex,
                          record,
                          index,
                          handleSave,
                          children,
                          modules,
                          ...restProps
                      }) => {
    const [isEditing, setEditing] = useState(false);
    const [value, setValue] = useState([null, null]);
    const [opts, setOpts] = useState([]);
    const [defaultValue, setDefaultValue] = useState(children);
    const selectRef = useRef();

    useEffect(() => {
        if(options) {
            const arr = options[modules[record.key]]?.map(i => i);
            arr?.push({display: 'No Access', value: 'NO_ACCESS'});
            setOpts(arr);
        }
    }, [options])

    useEffect(() => {
        setValue([null, opts?.filter(i => i.display === children[1])[0]]);
    }, [opts])

    useEffect(() => {
        if(isEditing) {
            selectRef.current.focus();
        }
    }, [isEditing])

    const toggleEdit = () => {
        if(editable) {
            const _editing = isEditing;
            setEditing(!_editing);
        }
    }

    const valueOnChange = (value) => {
        const val = opts?.filter(i => i.value === value)[0];
        setValue([null, val]);
        setDefaultValue([null, val.display]);
        handleSave(val)
    }

    return (
        <td {...restProps}>
            {
                !editable? children:
                !isEditing?
                    <StyledDiv style={{cursor: 'pointer', padding: '5px'}} onClick={toggleEdit}>
                        {defaultValue}
                    </StyledDiv>
                    :
                    <Select value={value[1]}
                            style={{width: '100%'}}
                            onChange={valueOnChange}
                            onBlur={toggleEdit}
                            defaultOpen={true}
                            ref={selectRef}>
                        {opts?.map((item) => <Select.Option key={item.value} value={item.value}>{item.display}</Select.Option>)}
                    </Select>
            }
        </td>
    );

}

export default SelectCell;