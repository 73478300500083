import React, { useState, useEffect } from 'react';
import { Select, Space } from 'antd';

type direction = 'horizontal' | 'vertical';

export const getLocationValues = (tree: any) => {
    let locationObj: any = {
        clinic: null,
        department: null,
        facility: null
    }
    let item: any = tree;
    if(item && item?.length > 0) {
        let filteredFacility: any = item.filter((it: any) => it.allowance === 'ALLOWED');
        if (filteredFacility.length > 0) {
            locationObj.facility = filteredFacility[0].id;
        }
        else {
            locationObj.facility = item[0].id;
            let filteredDepartment = item[0].children?.filter((it: any) => it.allowance === 'ALLOWED');
            if (filteredDepartment.length > 0) {
                locationObj.department = filteredDepartment[0].id;
            }
            else {
                if(item[0].children && item[0].children?.length > 0) {
                    locationObj.department = item[0].children[0].id;
                    if(item[0].children[0]?.children && item[0].children[0].children?.length > 0) {
                        let filteredClinic = item[0].children[0].children.filter((it: any) => it.allowance === 'ALLOWED');
                        if (filteredClinic.length > 0) {
                            locationObj.clinic = filteredClinic[0].id;
                        }
                    }
                } 
            }
        }
    }
    return locationObj;
}

export interface LocationSelectProps {
    locationData: Array<any>;
    onChange: any;
    isClinicVisible: boolean;
    hideLabel?: boolean;
    width: any;
    removeAllOption?: boolean;
    direction?: direction;
    clinicLabel?: any;
    showSearchValue?: boolean | undefined;
    id?: any;
    onBlur?: any;
    onFocus?: any;
    onSearch?: any;
    clinicValue?: any;
    departmentValue?: any;
    facilityValue?: any;
}

const LocationSelect: React.ForwardRefRenderFunction<unknown, LocationSelectProps> = (props) => {
    const { Option } = Select;

    const [facilityArray, setFacilitArray] = useState<Array<any>>([]);
    const [departmentArray, setDepartmentArray] = useState<Array<any>>([]);
    const [clinicArray, setClinicArray] = useState<Array<any>>([]);

    const [facilityValue, setFacilityValue] = useState<any>(props?.facilityValue ?? null);
    const [departmentValue, setDepartmentValue] = useState<any>(props?.departmentValue ?? null);
    const [clinicValue, setClinicValue] = useState<any>(props?.clinicValue ?? null);

    const {
        showSearchValue = false
    } = props;

    useEffect(() => {
        if (props?.locationData?.length > 0) {
            setFacilitArray(props?.locationData);
            if (props?.facilityValue) {
                let filteredFacility: any = props?.locationData.filter((it: any) => it.id === props?.facilityValue);
                if (filteredFacility[0].children) {
                    if(props?.departmentValue) {
                        setDepartmentArray(filteredFacility[0].children);
                        let filteredDepartment: any = filteredFacility[0].children?.filter((it: any) => it.id === props?.departmentValue);
                        if (props?.clinicValue) {
                            setClinicArray(filteredDepartment[0].children);
                        }
                        else {
                            if(!props?.removeAllOption) {
                                setClinicArray([...filteredDepartment[0].children, {id: 'ALL', name: 'All'}]);
                            }
                            else {
                                setClinicArray(filteredDepartment[0].children);
                            }
                        } 
                    }
                    if(!props?.departmentValue) {
                        if(!props?.removeAllOption) {
                            setDepartmentArray([...filteredFacility[0].children, {id: 'ALL', name: 'All'}]);
                        } else {
                            setDepartmentArray(filteredFacility[0].children);
                        }
                    }
                }
            }
        }
        setFacilityValue(props?.facilityValue);
        setDepartmentValue(props?.departmentValue);
        setClinicValue(props?.clinicValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.locationData]);

    const onChange = (value: any, isDepartment: boolean, action: any) => {
        let locationValue: any = '';
        let locationValueObj: any = {
            facility: undefined,
            department: undefined,
            clinic: undefined
        };
        let tempAction = action;
        if (value === 'ALL' && action === 3) {
            props.onChange(departmentValue, isDepartment, action - 1, null);
            return;
        }
        if (value === 'ALL' && action === 2) {
            setClinicValue(null);
            setClinicArray([]);
            props.onChange(facilityValue, isDepartment, action - 1, null);
            return;
        }
        if (value === 'ALL' && action === 1) {
            setDepartmentValue(null);
            setClinicValue(null);
            setDepartmentArray([]);
            setClinicArray([]);
            props.onChange(undefined, isDepartment, action, null);
            return;
        }
        if (action === 1) {
            locationValueObj['facility'] = value;
            setDepartmentValue(null);
            setClinicValue(null);
            setClinicArray([]);
            let tempDepartmentArr: any = facilityArray?.filter((item: any) => item?.id && item.id === value);
            if (tempDepartmentArr && tempDepartmentArr?.length > 0) {
                if (tempDepartmentArr[0]?.children && tempDepartmentArr[0]?.children?.length > 0) {
                    if(tempDepartmentArr[0].allowance !== 'ALLOWED') {
                        setDepartmentArray(tempDepartmentArr[0].children);
                        let tempAllowed = tempDepartmentArr[0].children.filter((it) => it.allowance === 'ALLOWED');
                        if(tempAllowed.length > 0) {
                            setDepartmentValue(tempAllowed[0].id);
                            locationValue = tempAllowed[0].id;
                            locationValueObj['department'] = tempAllowed[0].id;
                            tempAction = 2;
                            if(!props?.removeAllOption) {
                                setClinicArray([...tempAllowed[0].children, {id: 'ALL', name: 'All'}]);
                            } else {
                                setClinicArray(tempAllowed[0].children);
                            }
                        }
                        else {
                            setDepartmentValue(tempDepartmentArr[0].children[0].id);
                            locationValue = tempDepartmentArr[0].children[0].id;
                            locationValueObj['department'] = tempDepartmentArr[0].children[0].id;
                            tempAction = 2;
                            setClinicArray(tempDepartmentArr[0].children[0].children);
                            if(tempDepartmentArr[0].children[0]?.children && tempDepartmentArr[0].children[0].children?.length > 0) {
                                let tempClinic: any = tempDepartmentArr[0].children[0].children?.filter((it: any) => it.allowance === 'ALLOWED');
                                if (tempClinic.length > 0) {
                                    setClinicValue(tempClinic[0].id);
                                    locationValue = tempClinic[0].id;
                                    locationValueObj['clinic'] = tempClinic[0].id;
                                    tempAction = 3;
                                }
                            }
                        }
                    }
                    else {
                        if(!props?.removeAllOption) {
                            setDepartmentArray([...tempDepartmentArr[0].children, {id: 'ALL', name: 'All'}]);
                        } else {
                            setDepartmentArray(tempDepartmentArr[0].children); 
                        }
                    }
                }
                else {
                    setDepartmentArray([]);
                }
            }
            else {
                setDepartmentArray([]);
            }
        }
        if (action === 2) {
            locationValueObj['facility'] = facilityValue;
            locationValueObj['department'] = value;
            setClinicValue(null);
            let tempClinicArr: any = departmentArray?.filter((item: any) => item?.id && item.id === value);
            if (tempClinicArr && tempClinicArr?.length > 0) {
                if (tempClinicArr[0]?.children && tempClinicArr[0]?.children?.length > 0) {
                    if(tempClinicArr[0].allowance !== 'ALLOWED') {
                        setClinicArray(tempClinicArr[0].children);
                        let tempClinicAllowed = tempClinicArr[0].children.filter((it) => it.allowance === 'ALLOWED');
                        if(tempClinicAllowed.length > 0) {
                            setClinicValue(tempClinicAllowed[0].id);
                            locationValue = tempClinicAllowed[0].id;
                            locationValueObj['clinic'] = tempClinicAllowed[0].id;
                            tempAction = 3;
                        }
                    }
                    else {
                        if(!props?.removeAllOption) {
                            setClinicArray([...tempClinicArr[0].children, {id: 'ALL', name: 'All'}]);
                        } else {
                            setClinicArray(tempClinicArr[0].children);
                        }
                    }
                }
                else {
                    setClinicArray([]);
                }
            }
            else {
                setClinicArray([]);
            }
        }
        if(action === 3) {
            locationValueObj['facility'] = facilityValue;
            locationValueObj['department'] = departmentValue;
            locationValueObj['clinic'] = value;
        }
        if(locationValue) {
            props.onChange(locationValue, isDepartment, tempAction, locationValueObj);
        }
        else {
            props.onChange(value, isDepartment, action, locationValueObj);
        }
    }

    return (
        <div id={props?.id}>
            <Space direction={props?.direction ?? 'vertical'} style={{ width: '100%' }}>
                <div>
                    {!props?.hideLabel && <><label>Facility</label><br /></>}
                    <Select
                        id='LocationSelectDropDown'
                        showSearch={showSearchValue ? true : false}
                        style={{ width: props?.width ?? '100%' }}
                        placeholder='Select Location'
                        optionFilterProp='children'
                        onChange={(value) => {
                            setFacilityValue(value);
                            onChange(value, false, 1);
                        }}
                        value={facilityValue}
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onBlur={props?.onBlur}
                        onFocus={props?.onFocus}
                        onSearch={props?.onSearch}
                    >
                        {facilityArray && facilityArray?.length > 0 && facilityArray.map((item: any) => {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                        }
                        )}
                    </Select>
                </div>
                {departmentArray && departmentArray.length > 0 && <div>
                    {!props?.hideLabel && <><label>Department</label><br /></>}
                    <Select
                        id='LocationSelectDepartment'
                        showSearch={showSearchValue ? true : false}
                        style={{ width: props?.width ?? '100%' }}
                        placeholder='Select Department'
                        optionFilterProp='children'
                        onChange={(value) => {
                            setDepartmentValue(value);
                            onChange(value, true, 2);
                        }}
                        value={departmentValue}
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onBlur={props?.onBlur}
                        onFocus={props?.onFocus}
                        onSearch={props?.onSearch}
                    >
                        {departmentArray && departmentArray?.length > 0 && departmentArray.map((item: any) => {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                        }
                        )}
                    </Select>
                </div>}
                {clinicArray && clinicArray.length > 0 && props?.isClinicVisible && <div>
                    {!props?.hideLabel && <><label>{props?.clinicLabel ?? 'Clinic'}</label><br /></>}
                    <Select
                        id='LocationSelectClinicDropDown'
                        showSearch={showSearchValue ? true : false}
                        style={{ width: props?.width ?? '100%' }}
                        placeholder='Select Clinic'
                        optionFilterProp='children'
                        onChange={(value) => {
                            setClinicValue(value);
                            onChange(value, false, 3);
                        }}
                        value={clinicValue}
                        filterOption={(input: any, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onBlur={props?.onBlur}
                        onFocus={props?.onFocus}
                        onSearch={props?.onSearch}
                    >
                        {clinicArray && clinicArray?.length > 0 && clinicArray.map((item: any) => {
                            return <Option key={item.id} value={item.id}>{item.name}</Option>
                        }
                        )}
                    </Select>
                </div>}
            </Space>
        </div>
    );
}

export default LocationSelect;