import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import configureStore from "./redux/store/configureStore";
import App from './App';
import './index.scss';
import './style.scss';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ErrorNotifier from "./components/ErrorNotifier";
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTIC_ID} from './api/config';

const TRACKING_ID = GOOGLE_ANALYTIC_ID; 
ReactGA.initialize(TRACKING_ID);

export const store = configureStore();


const theme = createTheme({
    typography: {
        fontFamily: [
            'Raleway'
        ],
        fontSize: 22,
    },
})

const container = document.getElementById('wrapper');

const renderPage = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ErrorNotifier />
      <App />
    </ThemeProvider>
  </Provider>
);

const root = createRoot(container);
root.render(renderPage);


// // After
// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);