import {BASE_API_URL} from "./config";
import {createAxios} from "./axios";

const queueApi = createAxios({
  baseURL: BASE_API_URL
})

export const getQueues = (params = null) => {
    return queueApi.get('/queues', {
        params: {
            length:1000,    
            ...params
        }
    });
}

export const getQueueDataTable = (params) => {
  return queueApi.get('/queues', {
      params: {          
              page: params.page,
              draw: 1,
              start: params.start,
              length: params.length,
              search: params.search,
              sortOrder: params.sortOrder,
              sortDirection: params.sortDirection,
              facilityId: params?.facility,
              departmentId: params?.department,
              clinicId: params?.clinic,
              sortColumn: params?.sortColumn
      }
  });
}

export const createQueue = (payload) => {
  return queueApi.post('/queues', payload)
}

export const updateQueue = (id, payload) => {
    return queueApi.put(`/queues/${id}`, payload)
}

export const deleteQueue = (id) => {
  return queueApi.delete(`/queues/${id}`);
}

export const getQueueRoomById = (id) => {
    return queueApi.get(`/queues/rooms/${id}`);
}

export const loginToRoomById = (id, override = false) => {
    return queueApi.post(`/queues/rooms/${id}/loggedInUser${override ? '?override=true' : ''}`);
}

export const logoutOfRoomById = (id) => {
    return queueApi.delete(`/queues/rooms/${id}/loggedInUser`);
}