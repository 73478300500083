import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import Aux from 'components/hoc/AuxFunction';
import { deleteTemplates, getAll } from '../../../api/actionTemplates';
import CreateActionTemplateForm from './CreateActionTemplateForm';
import DeleteModal from '../../../components/Modals/DeleteModal';
import CRUDDataTable from '../../../components/DataTable/CRUDDataTable';
import { Link } from 'react-router-dom';
import { isCurrentPermissionKeyPermitted } from 'utils';
import { useSelector } from 'react-redux';
import { getAllOptions } from '../../../api/actionTemplatesOptions';

const ActionTemplates = ({ formVisible, setFormVisibility }) => {
  const modeStates = {
    ADD: 'add',
    EDIT: 'edit',
  };

  const [actionTemplates, setActionTemplates] = useState([]);
  const [actionOptionTemplates, setActionOptionTemplates] = useState([]);
  const [id, setId] = useState();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [mode, setMode] = useState(modeStates.ADD);
  const permissionState = useSelector((state) => state.permissions);

  const cols = [
    {
      title: <strong>Name</strong>,
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text, record, value) => {
        return (
          <Link
            onClick={() => {
              setTemplateId(record?.id);
              setFormVisibility(true);
              setMode(modeStates.EDIT);
            }}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: isCurrentPermissionKeyPermitted(
        'settings:action_templates:delete',
        permissionState
      ) && <strong>Options</strong>,
      dataIndex: 'name',
      key: 'name',
      render: (text, record, value) => {
        return (
          <Space>
            {/*<Button>Edit</Button>*/}
            {isCurrentPermissionKeyPermitted(
              'settings:action_templates:delete',
              permissionState
            ) && (
              <Button
                id={'ActionTemplateDeleteButton'}
                onClick={() => {
                  setId(record.id);
                  setDeleteVisible(true);
                }}
                danger
              >
                Delete
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  function fetchData() {
    let params = {
      length: 10,
      page: 0,
      sortColumn: 'name',
      sortDirection: 'ASC',
    };
    getAll(params).then((res) => {
      setActionTemplates(res.data);
    });

    let optionsParam = {
      generate: true,
    };
    getAllOptions(optionsParam).then((res) => {
      setActionOptionTemplates(res.data);
    });
  }

  const [draw, setDraw] = useState(0);
  useEffect(() => {
    fetchData();
  }, [draw]);
  if (!actionTemplates.data) {
    return null;
  }
  return (
    <Aux>
      {formVisible && (
        <CreateActionTemplateForm
          visible={formVisible}
          setVisible={setFormVisibility}
          onSuccess={fetchData}
          templateId={templateId}
          isDisabled={
            !isCurrentPermissionKeyPermitted(
              'settings:action_templates:edit',
              permissionState
            ) && mode !== modeStates.ADD
          }
          mode={mode}
          onCancel={() => {
            setMode(modeStates.ADD);
          }}
          actionOptionTemplates={actionOptionTemplates}
        />
      )}
      <DeleteModal
        visible={deleteVisible}
        setVisibility={setDeleteVisible}
        deleteFunction={deleteTemplates}
        onSuccess={() => setDraw(draw + 1)}
        entityId={id}
        entity='Action Template'
      />
      <Card style={{ marginBottom: '10px' }}>
        {/*<Table columns={cols} dataSource={actionTemplates}/>*/}
        <CRUDDataTable
          draw={draw}
          entity='Action Template'
          fetchDataFunction={async (params) => {
          return await getAll(params);
          }}
          data={actionTemplates}
          createModal={<></>}
          button={<></>}
          columns={cols}
        />
      </Card>
    </Aux>
  );
};

export default ActionTemplates;
