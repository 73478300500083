import React, { useEffect, useRef, useState } from 'react';
import Aux from 'components/hoc/AuxFunction';
import StatusModal from 'components/Appointments/Modals/StatusModal';
import { Alert } from 'components/Layout/Alert';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Badge, DatePicker, message, Menu, Select, Tooltip, Tag, Typography, Space, Button, Input } from 'antd';
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';
import {
  EditOutlined,
  FileOutlined,
  FileTextOutlined,
  MessageOutlined,
  PaperClipOutlined
} from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';
import CreateMessageModal from '../Appointment/components/Messages/CreateMessageModal';
import CreateNoteModal from '../Appointment/components/Notes/CreateNoteModal';
import CreateCollateralModal from '../Appointment/components/Collateral/CreateCollateralModal';
import moment from 'moment';
import { getAllLocations, getLocationTypeList } from '../../api/locations';
import CRUDDataTable from '../../components/DataTable/CRUDDataTable';
import CreateVisitorForm from '../Visitors/components/CreateVisitorForm';
import { getAppointmentsDatatable, getAppointmentTypes } from '../../api/appointments';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import DocumentIcon from 'assets/icons/document-icon';
import MessageIcon from 'assets/icons/message-icon';
import ResourceIcon from 'assets/icons/resource-icon';
import AttachmentIcon from 'assets/icons/attachment-icon';
import PatientIcon from 'assets/icons/patient-icon';
import CreateFormModal from 'pages/Appointment/components/Forms/CreateFormModal';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import PermissifiedComponent from 'components/PermissifiedComponent';
import { isCurrentPermissionKeyPermitted, permissifyTableColumns } from 'utils';
import { FaFileCsv } from 'react-icons/all';
import UploadPatientCSVModal from 'components/Appointments/Modals/UploadPatientCSV';
import { StatusTypes } from 'utils/enums';
import {
  getAppointmentFlagMessageStatus,
  getQueueEntryFlagMessageStatus,
  onAppointmentFlagRemove,
  onQueueEntryFlagRemove
} from 'utils/helpers';
import { setFilterIsLoading } from 'redux/actions/filter';
import CloseableTag from "../../components/ClosableTag";

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select

const DEFAULT_FILTERS = {
  locationId: undefined,
  status: '',
  appointmentType: '',
  span:'ALL',
  startDate: null,
  endDate: null,
  patientName: '',
  contactNumber: '',
  mrn: '',
  attendingClinicianName: '',
  secondaryClinicianName: ''
};

const AppointmentsList = (props) => {
  const {
    setMessageModalVisibility,
    setCollateralModalVisibility,
    setNoteModalVisibility,
    setActiveAppointmentId,
  } = props;
    const permissionState = useSelector(state => state.permissions);
    const sysPropertiesState = useSelector((state) => state.sysProperties);
    const { RangePicker } = DatePicker;
    const [
        isCreateMessageModalVisible,
        setCreateMessageModalVisibility,
    ] = useState(false);
    const [isCreateNoteModalVisible, setCreateNoteModalVisibility] = useState(
        false
    );
    const [
        isCreateCollateralModalVisible,
        setCreateCollateralModalVisibility,
    ] = useState(false);
    const [isStatusModalVisible, setStatusModalVisibility] = useState(false);
    const [draw, setDraw] = useState(0);
    const [uploadPatientCSVModalVisibility, setUploadPatientCSVModalVisibility] = useState(false);

    //const newFilter = [];
    //newFilter.push({ paramName: 'startDate', value: moment().format('yyyy-MM-DD') });
    //newFilter.push({ paramName: 'endDate', value: moment().format('yyyy-MM-DD') });
    //const [customFilters, setCustomFilters] = useState([]);
    const [customFilters, setCustomFilters] = useState(Object.keys(DEFAULT_FILTERS).map((paramName) => ({
      paramName,
      value: DEFAULT_FILTERS[paramName]
    })));
    const [search, setSearch] = useState(1);
    const [dataTableParams, setDataTableParams] = useState(DEFAULT_FILTERS);
    const [facilityValue, setFacilityValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [clinicValue, setClinicValue] = useState(null);
    const [locationCalled, setLocationCalled] = useState(0);
    const initial = useRef(true);
    // const [spanDefaultValue, setSpanDefaultValue] = useState('UPCOMING');
    
    const [timeFilters, setTimeFilters] = useState([]);
    const dispatch = useDispatch();

    const [id, setId] = useState(0);
    const [hospitalOptions, setHospitalOptions] = useState([]);
    const [nameSearch, setNameSearch] = useState('');
    const locationsType = useRef([]);
    const appointmentsType = useRef([]);

    const setLocationParams = (data) => {
      setHospitalOptions(data);
      const locationObj = getLocationValues(data);

      let locationId = '';
      if(locationObj.facility) {
        setFacilityValue(locationObj.facility);
        locationId = locationObj.facility;
      }
      if(locationObj.department) {
        setDepartmentValue(locationObj.department);
        locationId = locationObj.department;
      }
      if(locationObj.clinic) {
        setClinicValue(locationObj.clinic);
        locationId = locationObj.clinic;
      }
      let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) =>  {
        if(paramName === 'locationId') {
          return ({
            paramName,
            value: locationId,
          })
        }
        else {
          return ({
            paramName,
            value: dataTableParams[paramName],
          })
        }
      });
      setCustomFilters(updatedCustomFilters);
      setDataTableParams((params) => ({
        ...params,
        locationId: locationId,
      }));
      setLocationCalled(1);
    }
    const satisfied = async () => {



        const response = await getAllLocations();
        let responseLocType = await getLocationTypeList();
        let responseAppType = await getAppointmentTypes();
        locationsType.current = responseLocType?.data?.data ?? [];
        appointmentsType.current = responseAppType?.data?.data ?? [];
        setHospitalOptions(response.data.data);
        setLocationParams(response.data.data);
        // two nested data calls because it's returning a datatable
        // console.log(response.data.data)
        dispatch(setFilterIsLoading(false));
    };
    useEffect(() => {
        dispatch(setFilterIsLoading(true));
        satisfied();
    }, []);

    //useEffect(() => {
    //    initFilters();
    //}, []);

    //const initFilters = () => {
    //    const newFilter = [...customFilters];

    //    newFilter.push({ paramName: 'startDate', value: moment().format('yyyy-MM-DD') });
    //    newFilter.push({ paramName: 'endDate', value: moment().format('yyyy-MM-DD') });

    //    setCustomFilters(newFilter);
    //}

  const [isCreateFormModalVisible, setCreateFormModalVisibility] = useState(false);

  const renderMenu = (id, appointmentTitle) => (
    <Menu>
      <Menu.Item>
        <Link
          to={`/appointments/${id}`}
          onClick={() => setActiveAppointmentId(id)}
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setStatusModalVisibility({
              id: id,
              isVisible: true,
            });
          }}
        >
          Change Status
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateMessageModalVisibility(true);
            setId(id);
          }}
        >
          Send Message
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          onClick={() => {
            setCreateCollateralModalVisibility(true);
            setId(id);
          }}
        >
          Attach Collateral
        </Link>
      </Menu.Item>
      {/*<Menu.Item>
        <Link
          onClick={() => {
            setCreateNoteModalVisibility(true);
            setId(id);
          }}
        >
          Add Note
        </Link>
      </Menu.Item>*/}
    </Menu>
  );

  const notificationTypes = [
    {
      name: 'newForms',
      icon: <DocumentIcon />,
    },
    {
      name: 'newMessages',
      icon: <MessageIcon />,
    },
    {
      name: 'newResources',
      icon: <ResourceIcon />,
    },
    {
      name: 'newAttachments',
      icon: <AttachmentIcon />,
    },
    {
      name: 'incompletePatientTasks',
      icon: <PatientIcon />,
    },
    {
      name: 'incompleteStaffTasks',
      icon: <ListAltTwoToneIcon />, 
    },
  ];

  useEffect(() => {
    let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => ({
      paramName,
      value: dataTableParams[paramName],
    }))
    setCustomFilters(updatedCustomFilters)
  }, [search])

  const criteriaNotSearched = () => {
    if(!initial.current) {
      for (let filter of customFilters) {
        if (dataTableParams[filter.paramName] !== filter.value) {
          return true;
        }
      }
    }
    return false;
  }

  const columns = [
    !sysPropertiesState.rowColor  &&  { // show bars instead of the background color
      permissionKey: 'appointments:list:column:first_name',
      title: <strong></strong>,
      dataIndex: '-',
      key: '-',
      render: (text, record, index) => {
        return (
          <div
            className={record?.status}
            style={{
              position: 'absolute',
              height: '96%',
              width: '12px',
              left: '1px',
              top: '2%',
              marginLeft: '5px'
            }}
          ></div>
        );
      },
    },
    {
      permissionKey: 'appointments:list:column:first_name',
      title: <strong>MRN</strong>,
      dataIndex: 'mrn',
      key: 'mrn',
      sorter: true,
    },
    {
      permissionKey: 'appointments:list:column:first_name',
      title: <strong>Patient ID</strong>,
      dataIndex: 'patient.uniqueId',
      key: 'patient.uniqueId',
      sorter: true,
      render: (text, record, value) => {
        return record?.qrCodeValue
      }
    },
    {
      permissionKey: 'appointments:list:column:first_name',
      title: <strong>Patient Name</strong>,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            <Link
              to={`/appointments/${record?.id}`}
              onClick={() => {
                // setActiveAppointmentId(record?.id)
              }}
            >
              {record?.firstName} {record?.lastName}
            </Link>
          </>
        );
      },
      // filter: {
      //   paramName: 'search',
      //   label: 'Patient',
      //   // hidden: true,
      //   component: {
      //     name: 'text',
      //     placeholder: 'Enter patient name',
      //   },
      // },
    },
    {
      permissionKey: 'appointments:list:column:date_time',
      title: <strong>Adm Time & Date</strong>,
      dataIndex: 'appointmentDateTime',
      key: 'appointmentDateTime',
      sorter: true,
      defaultSortOrder: "ascend",
      render: (text, record, value) =>
        moment(text).format('DD MMM YYYY h:mm A'),
      // filter: {
      //   paramName: 'date',
      //   label: 'Date',
      //   component: {
      //     name: 'daterange',
      //     defaultValue: [moment(), moment()],
      //     format: 'DD/MM/YYYY',
      //   },
      //   defaultValue: [moment(), moment()],
      //   displayFormat: (value) => {
      //     const dates = value && value.map((date) => date.format('DD-MM-yyyy'));
      //     return `${dates[0]} to ${dates[1]}`;
      //   },
      //   beforeRequest: (value) =>
      //     value &&
      //     value
      //       .map((date) => date.format('yyyy-MM-DD'))
      //       .reduce((result, date, i) => {
      //         if (i === 0) result.startDate = date;
      //         else result.endDate = date;

      //         return result;
      //       }, {}),
      // },
    },
    {
      permissionKey: 'appointments:list:column:appointment_type',
      title: <strong>Specialty</strong>,
      dataIndex: 'appointmentType',
      key: 'appointmentType',
      sorter: true,
      render: (text, record, value) => {
        return (
          <>
            <div>{record?.appointmentType}</div>
            {/*<Tag color={record?.attendanceType === 'CLINIC' ? 'blue' : 'green'}>*/}
            {/*  {record?.attendanceType}*/}
            {/*</Tag>*/}
          </>
        );
      },
      // filter: {
      //   paramName: 'appointmentType',
      //   label: 'Appointment Type',
      //   component: {
      //     name: 'select',
      //     placeholder: 'Select an Appointment Type',
      //     fetchData: () => {
      //       return appointmentsType.current.map((item) => {
      //         return {
      //           value: item.key,
      //           displayText: item.display,
      //         };
      //       });
      //     },
      //   },
      // },
    },
    {
      permissionKey: 'appointments:list:column:clinician',
      title: <strong>Clinician Name</strong>,
      dataIndex: 'attendingClinicianName',
      key: 'attendingClinicianName',
      sorter: true,
    },
    {
      permissionKey: 'appointments:list:column:clinician',
      title: <strong>Resource</strong>,
      dataIndex: 'secondaryClinicianName',
      key: 'secondaryClinicianName',
      sorter: true,
    },
    {
      permissionKey: 'appointments:list:column:status',
      title: <strong>Status</strong>,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (text, record, value) => {
        let flags = record?.flags;
        let renderTags = [];
        if (flags && flags.length) {
          flags.map((flag) => {
            renderTags.push(<><br/><CloseableTag
                closable={isCurrentPermissionKeyPermitted('appointments:list:column:status:edit_tag', permissionState)}
              tag={getAppointmentFlagMessageStatus(flag)}
              index={flag}
              id={`AppointmentFlag${getAppointmentFlagMessageStatus(flag)}`}
              onClose={()=>{
                onAppointmentFlagRemove(record, flag, ()=> {
                  setDraw((draw) => draw + 1)
                })
              }} /></>);
          });
        }
        let queueEntryFlags = record?.queueEntryFlags;
        if (queueEntryFlags && queueEntryFlags.length) {
          queueEntryFlags.map((flag) => {
            renderTags.push(<><br/><CloseableTag
              tag={getQueueEntryFlagMessageStatus(flag)}
              index={flag}
              closable={isCurrentPermissionKeyPermitted('appointments:list:column:status:edit_tag', permissionState)}
              id={`AppointmentFlag${getQueueEntryFlagMessageStatus(flag)}`}
              onClose={() => {
                onQueueEntryFlagRemove(record?.queueEntryId, flag, () => {
                  setDraw((draw) => draw + 1)
                })
              }}/></>);
          });
        }
        return (
          <>
          {StatusTypes.find(item => (item.value === text))?.displayText.toUpperCase()}
            {renderTags}{' '}
          </>
        );
      },
      // filter: {
      //   paramName: 'status',
      //   label: 'Status',
      //   component: {
      //     name: 'select',
      //     placeholder: 'Select a status',
      //     fetchData: () => StatusTypes,
      //   },
      // },
    },
    {
      permissionKey: 'appointments:list:column:clinician',
      title: <strong>Contact Number</strong>,
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      sorter: true,
    },
    {
      permissionKey: 'appointments:list:column:clinician', //gurcan
      title: <strong>Clinic Type</strong>,
      dataIndex: 'clinicType',
      key: 'clinicType',
      sorter: false,
      // filter: {
      //   paramName: 'clinicType',
      //   label: 'Clinic Type',
      //   component: {
      //     name: 'select',
      //     placeholder: 'Select a Clinic Type',
      //     fetchData: () => {
      //       return locationsType.current.map((item) => {
      //         return {
      //           value: item.key,
      //           displayText: item.display,
      //         };
      //       });
      //     },
      //   },
      // },
    },
    {
      permissionKey: 'appointments:list:column:clinician',
      title: <strong>Fin Class</strong>,
      dataIndex: 'finClass',
      key: 'finClass',
      sorter: false,
    },
    {
      permissionKey: 'appointments:list:column:actionables',
      title: <strong>Tasks / Alerts</strong>,
      width: '100px',
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        return (
          <div>
            {notificationTypes.map((type) =>
              record[type.name] ? (
                <Tooltip
                  title={`${record[type.name]} ${type.name
                    .split(/(?=[A-Z])/)
                    .map((s) => s.toLowerCase())
                    .join(' ')}`}
                >
                  <Badge count={record[type.name]}>
                    {React.cloneElement(type.icon, {
                      width: 30,
                      height: 25,
                      className: 'm-1 actionables',
                    })}
                  </Badge>
                </Tooltip>
              ) : null
            )}
          </div>
        );
      },
    },
    {
      permissionKey: 'appointments:list:column:options',
      title: <strong>Actions</strong>,
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        const appointmentOptions = [
          {
            permissionKey: 'appointments:list:column:options:update_status',
            id: 'AppointmentUpdateStatusMenu',
            label: 'Update Status',
            icon: <EditOutlined />,
            onClick: () => {
              setStatusModalVisibility(true);
              setId(record.id);
            },
          },
          {
            permissionKey: 'appointments:list:column:options:add_collateral',
            id: 'AppointmentAddCollateralMenu',
            label: 'Add Collateral',
            icon: <PaperClipOutlined />,
            onClick: () => {
              setCreateCollateralModalVisibility(true);
              setId(record.id);
            },
          },
          {
            permissionKey: 'appointments:list:column:options:send_message',
            id: 'AppointmentSendMessageMenu',
            label: 'Send Message',
            icon: <MessageOutlined />,
            onClick: () => {
              setCreateMessageModalVisibility(true);
              setId(record.id);
            },
          },
          /*{
            permissionKey: 'appointments:list:column:options:add_note',
            id: 'AppointmentAddNoteMenu',
            label: 'Add Note',
            icon: <FileTextOutlined />,
            onClick: () => {
              setCreateNoteModalVisibility(true);
              setId(record.id);
            },
          },*/
          {
            permissionKey: 'appointments:list:column:options:add_form',
            id: 'AppointmentAddFormMenu',
            label: 'Add Form',
            icon: <FileOutlined />,
            onClick: () => {
              setCreateFormModalVisibility(true);
              setId(record.id);
            },
          },
        ];

        const permittedOptions = appointmentOptions.filter(
          (appointmentOption) =>
            isCurrentPermissionKeyPermitted(
              appointmentOption.permissionKey,
              permissionState
            )
        );

        return (
          <>
            <ActionButtonsDropdown menus={permittedOptions} tabName='Appointment'/>
          </>
        );
      },
    },

    // {
    //   permissionKey: 'appointments:list:column:clinician',
    //   filter: {
    //     paramName: 'doctorName',
    //     label: 'Clinician',
    //     component: {
    //       name: 'text',
    //       placeholder: 'Enter clinician name',
    //     },
    //   },
    // },
    // {
    //   permissionKey: 'appointments:list:column:filter:span',
    //   filter: {
    //     paramName: 'span',
    //     label: 'Span',
    //     defaultValue: 'UPCOMING',
    //     component: {
    //       name: 'select',
    //       placeholder: 'Select span',
    //       fetchData: () => {
    //         return [
    //           { value: 'ALL', displayText: 'ALL' },
    //           { value: 'PAST', displayText: 'PAST' },
    //           { value: 'UPCOMING', displayText: 'UPCOMING' },
    //         ];
    //       },
    //     },
    //   },
    // },
  ];

  const fetchAppointments = async(params) => {
    params = {
      ...params,
      sortColumn: params?.sortColumn
    }

    if (params.date && Array.isArray(params.date)) {
      if (params.date[0]){
        params.startDate = params.date[0].format('yyyy-MM-DD');
      }
      if (params.date[1]){
        params.endDate = params.date[1].format('yyyy-MM-DD');
      }
      delete params.date;
    }
    return await getAppointmentsDatatable(params);
  }

  return (
    <Aux>
      <StatusModal
        isStatusModalVisible={isStatusModalVisible}
        setStatusModalVisibility={setStatusModalVisibility}
        id={id}
        onSuccess={() => setDraw(draw + 1)}
      />
      <Alert />
      <CreateNoteModal
        visible={isCreateNoteModalVisible}
        setVisibility={setCreateNoteModalVisibility}
        icon={<FileTextOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateMessageModal
        visible={isCreateMessageModalVisible}
        setVisibility={setCreateMessageModalVisibility}
        icon={<MessageOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateCollateralModal
        showSearchValue={true}
        visible={isCreateCollateralModalVisible}
        setVisibility={setCreateCollateralModalVisibility}
        icon={<PaperClipOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <CreateFormModal
        showSearchValue={true}
        visible={isCreateFormModalVisible}
        setVisibility={setCreateFormModalVisibility}
        icon={<FileOutlined className='mr-2' />}
        appointmentId={id}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <UploadPatientCSVModal
        visible={uploadPatientCSVModalVisibility}
        setVisibility={setUploadPatientCSVModalVisibility}
        setDraw={setDraw}
        draw={draw}
      />
      <PermissifiedComponent permissionKey='appointments:location_filter'>
        <Row>
          <Col>
            <LocationSelect
              locationData={hospitalOptions}
              facilityValue={facilityValue}
              departmentValue={departmentValue}
              clinicValue={clinicValue}
              showSearchValue={true}
              id="appointmentsHospitalSelect"
              width='275px'
              direction='horizontal'
              onChange={(value) => {
                // const paramName = 'locationId';
                // const filters = [...customFilters];
                // const hospitalFilter = filters.filter(
                //     (filter) => filter.paramName === paramName
                // )[0];

                // if (hospitalFilter) {
                //     hospitalFilter.value = value;
                // } else {
                //     filters.push({ paramName: paramName, value: value });
                // }

                // setCustomFilters(filters);
                initial.current = false;
                setDataTableParams((params) => ({
                  ...params,
                  locationId: value,
                }))
              }}
              isClinicVisible={true}
              showAll={true}
            />
          </Col>
          <Col
            className='text-right'
          >
            <Button
              id='appointmentsUploadPatientCSVButton'
              className='mt-5'
              type='primary'
              disabled={!isCurrentPermissionKeyPermitted('appointments:list:upload_csv', permissionState)}
              onClick={() => {
                setUploadPatientCSVModalVisibility(true);
              }}
            >
              <FaFileCsv />&nbsp;&nbsp;Upload Patient CSV
            </Button>
          </Col>
        </Row>
        <br />
      </PermissifiedComponent>
      <Space direction='vertical' size="middle">
        <Row>
            <Col xs={12} lg={3}>
              <Space direction='vertical' size="small" className='w-100'>
                <Text strong>Date Range</Text>
                <RangePicker
                  id={"appointmentsDate"}
                  format={"DD/MM/YYYY"}
                  className='w-100'
                  popupStyle={{
                    minWidth: '0px !important',
                  }}
                  defaultValue={[null, null]}
                  onCalendarChange={(dates, _dateStrings, _info) => {
                    initial.current = false;
                    if (dates) {
                      setDataTableParams((params) => ({
                        ...params,
                        startDate: dates[0]?.format("YYYY-MM-DD"),
                        endDate: dates[1]?.format("YYYY-MM-DD"),
                      }))
                    }
                    else{
                      setDataTableParams((params) => ({
                        ...params,
                        startDate: null,
                        endDate: null
                      }))
                    }
                    // if (dates && moment(dates[0]).isSameOrAfter(moment()) && moment(dates[1]).isSameOrAfter(moment())) {
                    //   setSpanDefaultValue('UPCOMING');
                    // }
                    // else {
                    //   setSpanDefaultValue('');
                    // }
                  }}
                />
              </Space>
            </Col>
            <Col xs={12} lg={2}>
              <Space direction='vertical' size="small" className='w-100'>
                <Text strong>Status</Text>
                <Select
                  id="appointmentsStatus"
                  className='w-100'
                  showSearch
                  defaultValue={"ALL"}
                  onChange={(value) => {
                    initial.current = false;
                    setDataTableParams((params) => ({
                      ...params,
                      status: value,
                    }))}
                  }
                >
                  <Option value={'ALL'}>All</Option>
                  {StatusTypes.map((status, i) => {
                    return <Option key={i} value={status.value}>{status.displayText}</Option>
                  })}
                </Select>
              </Space>
            </Col>
            <Col xs={12} lg={3}>
              <Space direction='vertical' size="small" className='w-100'>
                <Text strong>Specialty</Text>
                <Select
                  id="appointmentsType"
                  className='w-100'
                  showSearch
                  defaultValue={"ALL"}
                  onChange={(value) => {
                    initial.current = false;
                    setDataTableParams((params) => ({
                      ...params,
                      appointmentType: value,
                    }))}
                  }
                >
                  <Option value={'ALL'}>All</Option>
                  {appointmentsType.current.map((type, i) => {
                    return <Option key={i} value={type.key}>{type.display}</Option>
                  })}
                </Select>
              </Space>
            </Col>
            <Col xs={12} lg={2}>
              <Space direction='vertical' size="small" className='w-100'>
                <Text strong>Span</Text>
                <Select
                  showSearch={true}
                  id="appointmentsSpan"
                  className='w-100'
                  // defaultValue={spanDefaultValue}
                  defaultValue={'ALL'}
                  onChange={(value) => {
                    initial.current = false;
                    setDataTableParams((params) => ({
                      ...params,
                      span: value,
                    }))}
                  }
                >
                  <Option key={1} value={'ALL'}>All</Option>
                  <Option key={2} value={'PAST'}>Past</Option>
                  <Option key={3} value={'UPCOMING'}>Upcoming</Option>
                </Select>
              </Space>
            </Col>
        </Row>
        <Row>
          <Col xs={12} lg={2}>
            <Space direction='vertical' size="small" className='w-100'>
              <Text strong>MRN</Text>
              <Input
                id="appointmentsMrn"
                className='w-100'
                allowClear
                value={dataTableParams.mrn}
                placeholder={"Search by MRN"}
                type='text'
                name='mrn'
                onChange={(e) => {
                  const value = e.target.value;
                  initial.current = false;
                  setDataTableParams((params) => ({
                    ...params,
                    mrn: value,
                  }))
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={2}>
            <Space direction='vertical' size="small" className='w-100'>
              <Text strong>Patient Name</Text>
              <Input
                id="appointmentsPatientName"
                className='w-100'
                allowClear
                value={dataTableParams.patientName}
                placeholder={"Search by name"}
                type='text'
                name='patientName'
                onChange={(e) => {
                  initial.current = false;
                  const value = e.target.value;
                  setDataTableParams((params) => ({
                    ...params,
                    patientName: value,
                  }))
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={2}>
            <Space direction='vertical' size="small" className='w-100'>
              <Text strong>Phone Number</Text>
              <Input
                id="appointmentsPhoneNumber"
                className='w-100'
                allowClear
                value={dataTableParams.contactNumber}
                placeholder={"Search by phone number"}
                maxLength={10}
                type='text'
                name='contactNumber'
                onChange={(e) => {
                  initial.current = false;
                  const value = e.target.value;
                  setDataTableParams((params) => ({
                    ...params,
                    contactNumber: value,
                  }))
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={2}>
            <Space direction='vertical' size="small" className='w-100'>
              <Text strong>Clinician</Text>
              <Input
                id="appointmentsClinician"
                className='w-100'
                allowClear
                value={dataTableParams.attendingClinicianName}
                placeholder={"Search by clinician"}
                maxLength={40}
                type='text'
                name='attendingClinicianName'
                onChange={(e) => {
                  initial.current = false;
                  const value = e.target.value;
                  setDataTableParams((params) => ({
                    ...params,
                    attendingClinicianName: value,
                  }))
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={2}>
            <Space direction='vertical' size="small" className='w-100'>
              <Text strong>Resource</Text>
              <Input
                id="appointmentsResource"
                className='w-100'
                allowClear
                value={dataTableParams.secondaryClinicianName}
                placeholder={"Search by resource"}
                maxLength={40}
                type='text'
                name='secondaryClinicianName'
                onChange={(e) => {
                  initial.current = false;
                  const value = e.target.value;
                  setDataTableParams((params) => ({
                    ...params,
                    secondaryClinicianName: value,
                  }))
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={2}>
            <Space direction='vertical' size="small" className='w-100'>
              <Text strong>&nbsp;</Text>
              <Button
                id="appointmentsSearch"
                type={`${criteriaNotSearched() ? 'primary' : '' }`}
                className={`w-100 ${!criteriaNotSearched() ? 'not-searched': ''}`}
                onClick={() => setSearch(current => current + 1)}>
                Search
              </Button>
            </Space>
          </Col>
        </Row>
        <br />
      </Space>
      {locationCalled > 0 ? <CRUDDataTable
        draw={draw}
        entity=''
        fetchDataFunction={fetchAppointments}
        continuousFetchInterval={30000}
        customFilters={customFilters}
        createModal={<CreateVisitorForm />}
        columns={permissifyTableColumns(columns, permissionState)}
        sortOrder='appointmentDateTime'
        button={<></>}
        highlightRow={(rec, i) => {
          return (
            rec.highlightDeclinedForStaff ||
            rec.newForms > 0 ||
            rec.newMessages > 0 ||
            rec.newAttachments > 0 ||
            rec.incompleteStaffTasks > 0
          );
        }}
        searchText="Scan patient's QR code or type to search"
        searchAutoFocus={true}
        showSearchBar={false}
        isRowColor={sysPropertiesState.rowColor}
      /> : <></>}
    </Aux>
  );
};

export default AppointmentsList;
