import { SET_TENANT, SET_TENANT_BRANDING } from 'redux/actions/types/tenant';

export const INITIAL_STATE = {
    id: undefined,
    uniqueId: 'Default',
    name: undefined,
    branding: {
        logo: undefined,
        centraliseBranding: true,
        page: {
            appTitle: 'Digital Patient Journey',
            appSubTitle: 'Digital Patient Journey',
            navbarHeaderTitle: 'Five Faces Pty. Ltd.',
        },
        theme: {
            palette: {
                primary: {
                    light: '#0170FE',
                    main: '#0170FE',
                    dark: '#193B68'
                },
                navbar: {
                    background: '#FFFFFF',
                    text: '#193B68'
                },
                sidebar: {
                    mainText: '#193B68',
                    activeBackground: '#0170FE',
                    activeText: '#FFFFFF'
                },
                header: {
                    primary: '#193B68',
                    secondary: '#193B68'
                },
                text: {
                    primary: '#404040'
                },
                button: {
                    primaryBackground: '#0170FE',
                    text: '#FFFFFF',
                    secondaryBackground: '#0170FE'
                },
                background: {
                    default: '#F3F8FF'
                },
                status: {
                    checkedIn: '#77BCF4',
                    called: '#B9F889',
                    startConsult: '#1C9FE3',
                    checkedOut: '#EC5954',
                    requestReschedule: '#77BCF4',
                    unConfirmed: '#1C9FE3',
                    confirmed: '#EC5954',
                    noShow: '#77BCF4',
                    cancelled: '#77BCF4',
                    scheduled: '#77BCF4',
                },
            },
            typography: {
                htmlFontSize: '',
                fontFamily: 'Raleway',
                fontUrl: '',
                font: {}
            }
        }
    }
}

export default (state = INITIAL_STATE, action) => {
    const { type, tenant, branding, logo, error } = action;

    switch(type) {
        case SET_TENANT: {
            return {
                ...state,
                tenant
            }
        }

        case SET_TENANT_BRANDING: {
            let newTenantState = {...state.tenant};

            if (branding) {
                newTenantState.branding = {...branding};
            } else {
                newTenantState.branding = {...state.branding}
            }

            return {
                ...state,
                ...newTenantState
            }
        }

        default:
            return state
    }
}