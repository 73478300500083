export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL + '/api';
export const BASE_AUTH_URL = process.env.REACT_APP_API_BASE_URL + '/api/auth';
export const BASE_WS_URL = process.env.REACT_APP_API_BASE_URL + '/ws';
export const FORBIDDEN_URL = BASE_URL + 'forbidden';
export const KEYCLOAK_AUTH_URL = process.env.REACT_APP_KEYCLOAK_AUTH_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const REQUEST_TIMEOUT = process.env.REACT_APP_REQUEST_TIMEOUT;
export const DEFAULT_LOGO = "https://files.fivefaces.com.au/images/FiveFaces_Logo_Navy.svg"
export const POWERED_BY_LOGO = "https://files.fivefaces.com.au/images/FiveFaces_PoweredBy_Navy.svg"
export const GOOGLE_ANALYTIC_ID = process.env.REACT_APP_GA_ID;


export const msalConfig = {
  appId: process.env.REACT_APP_MSAL_APP_ID ,
  tenantId: process.env.REACT_APP_MSAL_TENANT_ID ,
  scopes: ['User.Read', 'profile'],
};
