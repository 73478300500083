import { useState, useMemo, useEffect } from 'react';
import { Button, Divider, Skeleton, Popconfirm, Tree, message } from 'antd';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { DownOutlined, ExclamationCircleFilled, QuestionCircleFilled } from '@ant-design/icons';

import { fetchAllPermissions, deletePermission } from 'api/permissions';
import { getAllRolePermissions, getPermissionByRole, updateRolePermission } from 'api/rolePermissions';
import CreateSelectRole from './CreateSelectRole';
import { FaPlus } from 'react-icons/fa';
import CreatePermissionModal from './CreatePermissionModal';
import {useSelector} from "react-redux";
import {isCurrentPermissionKeyPermitted} from "../../../utils";

const RolePermissionContainer = styled.div`
    padding: 24px;
    background: #fff;
`;

const permissionModalModes = {
    ADD: 'New',
    EDIT: 'Update'
};

const Permissions = () => {
    const [permissionModalVisible, setPermissionModalVisibility] = useState(false);
    const [plainPermissionData, setPlainPermissionData] = useState(null);
    const [permissionData, setPermissionData] = useState([]);
    const [permissionModalMode, setPermissionModalMode] = useState(permissionModalModes.ADD);
    const [currentPermissionData, setCurrentPermissionData] = useState(null);
    const [currentRoleUniqueId, setCurrentRoleUniqueId] = useState(null);
    const [currentRole, setCurrentRole] = useState({});
    const [currentPermissions, setCurrentPermissions] = useState([]);
    const [treeViewValue, setTreeViewValue] = useState([]);
    const [permissionPayload, setPermissionPayload] = useState([]);
    const [drawRolePermission, setDrawRolePermission] = useState(0);
    const [roles, setRoles] = useState([]);
    const [draw, setDraw] = useState(0);
    const permissionState = useSelector(state => state.permissions);
    
    const buildTree = (permissionArr, includeDiffMark = true) => {
        return permissionArr.reduce((tree, csv) => {
            csv.permissionValue.split(':').reduce((obj, title) => {
                const id = csv.permissionValue.endsWith(title) ? csv.permissionValue : (includeDiffMark ? `${title}${csv.permissionValue}!notincluded` : csv.permissionValue);
                return obj[title] = obj[title] || { 
                    id: id
                }
            }, tree);

            return tree;
        }, {});
    }

    const convertTree = (tree) => {
        return Object.keys(tree).map(title => {
            if(title === 'id') return null;

            const isParentNode = tree[title]['id'].endsWith('!notincluded')

            const obj = {
                title:  <Popconfirm
                            title={isParentNode ? 'Please select from the child-level permissions' : `What do you want to do with ${tree[title]['id']}?`}
                            okText="Delete"
                            cancelText="Edit"
                            onCancel={() => onPermissionEditClick(tree[title]['id'])}
                            onConfirm={() => onPermissionDeleteClick(tree[title]['id'])}
                            okButtonProps={{
                                hidden: isParentNode ? true : false
                            }}
                            cancelButtonProps={{
                                hidden: isParentNode ? true : false
                            }}
                            icon={isParentNode ? <ExclamationCircleFilled /> : <QuestionCircleFilled />}
                        >
                             {title?.replace(/_/g, ' ')?.replace(/(\w)(\w*)/g,function(g0,g1,g2){return g1?.toUpperCase() + g2?.toLowerCase();})}
                        </Popconfirm>,
                key: tree[title]['id']
            };

            const children = convertTree(tree[title]);
            if (children.length) obj.children = children;
            return obj;
        }).filter(obj => obj != null);
    }

    useEffect(() => {
        if (plainPermissionData && plainPermissionData.length > 0) {
            const permissionTree = buildTree(plainPermissionData);
            const convertedPermissionTree = convertTree(permissionTree);
            setPermissionData(convertedPermissionTree);
        }
    }, [plainPermissionData]);

    const onPermissionEditClick = (permissionValue) => {
        if (plainPermissionData) {
            const currentPermission = plainPermissionData.find(permission => permission.permissionValue === permissionValue);
            if (currentPermission) {
                setPermissionModalMode(permissionModalModes.EDIT);
                setCurrentPermissionData(currentPermission);
                setPermissionModalVisibility(true);
            }
        }
    }

    const onPermissionDeleteClick = async (permissionValue) => {
        if (plainPermissionData) {
            let newPlainPermissionData = [];
            const currentPermission = plainPermissionData.find(permission => permission.permissionValue === permissionValue);
            if (currentPermission) {
                try {
                    await deletePermission(currentPermission.uniqueId);
                    newPlainPermissionData = plainPermissionData.filter(permission => permission.permissionValue !== currentPermission.permissionValue);
                    setPlainPermissionData(newPlainPermissionData);
                    message.success('Permission Item successfully deleted', 2);
                } catch (err) {
                    message.error('Error occurred while trying to delete permission', 2);
                }
            }
        }
    }

    const getAllPermissions = async () => {
        try {
            const response = await fetchAllPermissions();

            if (response?.data) {
                const permissions = response.data;
                setPlainPermissionData(permissions);
            }
        } catch (err) {
            message.error('Error occurred while trying to get the permissions', 2);
        }
    };

    const getAllRolePermissionData = async () => {
        try {
            const response = await getAllRolePermissions();
            
            if (response.data) {
                setRoles(response.data);
            }
        } catch (err) {
            message.error('Error occurred while trying to get the role permissions', 2);
        }
    };

    const fetchPermissionsByRole = async (roleUniqueId) => {
        try {
            const response = await getPermissionByRole(roleUniqueId);

            if (response.data) {
                const responsePermission = response.data;
                
                const convertedRolePermission = responsePermission?.permissions.map(item => (
                    item['permissionValue']
                ));
                
                // setPermissionData(convertedRolePermission);
                setCurrentPermissions(convertedRolePermission);
            }
        } catch (err) {
            console.log(err);
            message.error('Error occurred while trying to get the permissions associated to the current role', 2);
        }
    };

    const saveRolePermission = async (uniqueId, payload) => {
        try {
            await updateRolePermission(uniqueId, payload);

            setCurrentRoleUniqueId(null);
            setCurrentPermissions([]);
            message.success('Role Permission successfully saved');
        } catch (err) {
            message.error('Error occurred while trying to save role permissions.');
        }
    };

    const getAllBranchWithChildren = (array) => {
        let resultArray = [];
        
        if (array && array.length > 0) {
            array.map(item => {
                if (item?.children && item?.children?.length > 0) {
                    resultArray.push({
                        key: item.key, 
                        childCount: item.children.length
                    });
                    let newResultArray = getAllBranchWithChildren(item.children);
                    resultArray.push(...newResultArray);
                }
            });
        }
        
        return resultArray;
    };

    useEffect(() => {
        getAllPermissions();
        getAllRolePermissionData();
    }, []);

    useEffect(() => {
        getAllRolePermissionData();
    }, [drawRolePermission])

    useEffect(() => {
        if (currentRoleUniqueId) {
            fetchPermissionsByRole(currentRoleUniqueId);
        }
    }, [currentRoleUniqueId]);

    useEffect(() => {
        getAllPermissions();
    }, [draw]);

    useEffect(() => {
        if (permissionData?.length > 0) {
            let treeValue = [];

            const filteredRolePermission = plainPermissionData.filter(permission => (
                currentPermissions.includes(permission.permissionValue)
            ));
            
            const rolePermissionTree = buildTree(filteredRolePermission, false);
            const convertedRolePermissionTree = convertTree(rolePermissionTree);

            const permissionTreeBranches = getAllBranchWithChildren(permissionData);
            const convertedRolePermissionTreeBranches = getAllBranchWithChildren(convertedRolePermissionTree);
            
            let halfChecked = permissionTreeBranches.map(mpBranch => {
                return convertedRolePermissionTreeBranches.some( rpBranch => rpBranch.key?.startsWith(mpBranch.key) && rpBranch?.childCount !== mpBranch?.childCount) && mpBranch.key
            }).filter(e => e);

            const halfCheckedParent = currentPermissions.filter(i => halfChecked.filter(x => x.includes(i)).length > 0)
            halfChecked = halfChecked.concat(halfCheckedParent);

            setPermissionPayload(currentPermissions);
            const checkedNodeKeys = currentPermissions.filter(cpi => !halfChecked.includes(cpi));
            
            if (halfChecked.length > 0) {
                treeValue = {
                    checked: checkedNodeKeys, 
                    halfChecked
                }
            } else {
                treeValue = [...currentPermissions]
            }

            setTreeViewValue(treeValue);
        }
    }, [plainPermissionData, permissionData, currentPermissions]);

    const onCheck = (checkedKeysValue, event) => {
        setPermissionPayload([...checkedKeysValue, ...event.halfCheckedKeys]);
        setTreeViewValue([...checkedKeysValue]);
    };

    const afterPermissionSubmit = () => {
        setDraw(draw + 1);
        setPermissionModalVisibility(false);
    };

    const onAddPermissionClick = () => {
        setCurrentPermissionData(null);
        setPermissionModalMode(permissionModalModes.ADD);
        setPermissionModalVisibility(true);
    };

    return (
        <>
            <CreatePermissionModal
                visible={permissionModalVisible}
                closable={true}
                afterSubmit={afterPermissionSubmit}
                onCancel={() => setPermissionModalVisibility(false)}
                maskClosable={false}
                initialValues={currentPermissionData}
                mode={permissionModalMode}
                destroyOnClose
            />
            <RolePermissionContainer>
                <Container fluid>
                    <Row>
                        {roles && roles.length > 0 &&
                        <>
                            <Col xs={8} md={8} lg={8}>
                                <CreateSelectRole
                                    data={roles}
                                    setCurrentRoleUniqueId={setCurrentRoleUniqueId}
                                    setDrawRolePermission={setDrawRolePermission}
                                    setCurrentRole={setCurrentRole}
                                    drawRolePermission={drawRolePermission}
                                />
                            </Col>
                            <Col xs={4} md={4} lg={4} className='text-right'>
                                <Button 
                                    type='primary'
                                    disabled={!isCurrentPermissionKeyPermitted('settings:permissions:save', permissionState)}
                                    onClick={() => {
                                        const payload = {
                                            ...currentRole,
                                            permissions: [...permissionPayload]
                                        };
                                        if (currentRoleUniqueId && currentRole) {
                                            saveRolePermission(currentRoleUniqueId, payload);
                                        } else {
                                            message.warning('You need to select role to save role permissions.');
                                        }
                                    }}
                                >
                                    Save Permission
                                </Button>
                            </Col>
                            <Divider/>
                        </>}
                    </Row>
                    <Row>
                        <Col xs={6} md={6} lg={6}>
                            <h3 className='font-weight-bolder mb-1'>Permissions</h3>
                            <small className='text-muted' style={{ fontSize: '1.3rem' }}>Check all the permission you want to affiliate with the current selected permission type.</small><br/>
                        </Col>
                        <Col className='text-right pt-3 pb-5' xs={12} sm={6} md={6} lg={6}>
                            <Button
                                disabled={!isCurrentPermissionKeyPermitted('settings:permissions:new_permission', permissionState)}
                                type='dashed'
                                onClick={onAddPermissionClick}
                            >
                                <FaPlus />&nbsp;Add New Permission
                            </Button>
                            <CreatePermissionModal />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            {permissionData && permissionData.length > 0 ? 
                                <Tree
                                    checkable
                                    disabled={!isCurrentPermissionKeyPermitted('settings:permissions:edit', permissionState)}
                                    onCheck={onCheck}
                                    treeData={permissionData}
                                    showLine={{showLeafIcon: false}}
                                    checkedKeys={treeViewValue || []}
                                    defaultExpandAll
                                    autoExpandParent
                                    switcherIcon={<DownOutlined />}
                                /> 
                                : 
                                <Skeleton />}
                        </Col>
                    </Row>
                </Container>
            </RolePermissionContainer>
        </>
    );
};

export default Permissions;
