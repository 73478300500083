import React, { useRef, useState, useEffect } from 'react';
import { Typography, Modal, Upload, Row, Col, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, ResetButton, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import { CloseOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { createCollateral } from 'api/collaterals';
import titleize from 'titleize';
import { createDocument } from '../../../../api/documents';
import { createAppointmentAttachment } from 'api/attachments';

const { Title } = Typography;

const entity = `attachment`;
const initialValues = {
    description: '',
};

const schema = yup.object().shape(
    {
        // description: yup
        //   .string()
        //   .min(2, "Too short.")
        //   .max(255, "Too long.")
        //   .required("Please enter a description."),
    }
);

const CreateAttachmentModal = ({ visible, setVisibility, icon, appointmentId, onSuccess }) => {
    const [files, setFiles] = useState([]);
    const [descriptionValue, setDescriptionValue] = useState('');
    const formikRef = useRef(null);
   
    function onRemoveFile(file) {
        const index = files.indexOf(file);
        const newFiles = files.slice();
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    useEffect(
        () => {
            if (!visible) {
                reset();
                setDescriptionValue('');
            }
        },
        [visible]
    );

    async function create(values) {
        try {
            if(files.length === 0) {
                message.error('Please Select File For Upload');
            }
            else {    
                message.loading(`Uploading ${entity}...`, 0);
                const form = new FormData();
                form.append('description', descriptionValue);
                form.append('appointmentId', appointmentId);
                files.forEach(file => form.append('file', file, file.name));
                const res = await createAppointmentAttachment(form);
                message.destroy();
                message.success(`${titleize(entity)} successfully uploaded.`);
                onSuccess();
                setVisibility(false);
            }       
        } catch (error) {
            message.destroy();
            message.error(error.response.data.message);
        }
    }

    function reset() {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    const onResetClick = () => {
        setFiles([]);
        setDescriptionValue('');
    }

    return (
        <Modal
            title={
                <Title level={4} className="mb-0" style={{ display: 'flex', alignItems: 'center' }}>
                    {icon}
                    {`New ${titleize(entity)}`}
                </Title>
            }
            visible={visible}
            onCancel={() => setVisibility(false)}
            closeIcon={(<CloseOutlined id='closeAppointmentsAttachments' />)}
            footer={null}
        >
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={values => create(values)}
                onReset={() => onResetClick()}
                render={() =>
                    <Form layout="vertical" colon={false}>
                        <Row className="mb-4">
                            <Col>
                                <Upload
                                    id='appointmentsAttachmentsUpload'
                                    customRequest={() => {
                                        return;
                                    }}
                                    beforeUpload={file => {
                                        setFiles([file]);
                                        return false;
                                    }}
                                    onRemove={onRemoveFile}
                                    className="mb-4"
                                    fileList={files}
                                >
                                    <span className='ant-btn'><UploadOutlined /><span>{`Upload ${titleize(entity)}`}</span></span>
                                </Upload>
                            </Col>
                        </Row>
                        <textarea
                            id='appointmentsAttachmentsDesc'
                            style={{ width: '100%' }}
                            onChange={e => {
                                console.log(e.target.value);
                                setDescriptionValue(e.target.value);
                            }}
                            value={descriptionValue}
                        />
                        <Row gutter={4} className="d-flex justify-content-end">
                            <Col>
                                <ResetButton id='appointmentsAttachmentsReset' disabled={false} >Reset</ResetButton>
                            </Col>
                            <Col>
                                <SubmitButton id='appointmentsAttachmentsCreate'>Create</SubmitButton>
                            </Col>
                        </Row>
                    </Form>}
            />
        </Modal>
    );
};

export default CreateAttachmentModal;
