import './MainLayout.scss';
import Navigation from './Navigation';
import PageWrapper from './PageWrapper';
import { Button } from 'antd';
import Aux from '../hoc/AuxFunction';
import { useEffect, useState } from 'react';
import { getAllLocations } from 'api/locations';
import { useAppSelector } from 'hooks/use-app-selector';
import { useAuthContext } from 'hooks/index';
import {removeAuth} from 'utils/helpers';

const MainLayout = props => {
    const { children } = props;
    const permissions = useAppSelector((state) => state.permissions.permissions);
    const [access, setAccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const {logout} = useAuthContext();
    const logoutApp = () => {
        logout();
        removeAuth();
      };

    const checkAccess = async () => {
        
        try {
            if(permissions?.Dashboard.length > 0) {
                const locationResponse = await getAllLocations();
                if(locationResponse && locationResponse?.data && locationResponse?.data?.data && locationResponse?.data?.data?.length > 0) {
                    setAccess(true);
                } else {
                    setErrorMessage(true);
                
                }
            } else {
                setErrorMessage(true);
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        if (permissions?.Dashboard){
            checkAccess();
        }
    }, [permissions]);

    return (
      <Aux class>
        {access ? (
          <>
            <Navigation fullName={props.fullName} />
            <PageWrapper page={children} />
          </>
        ) : (
            errorMessage && <div className='error-center'>
            <div>Insufficient user access.</div>
            <div>Please contact the Statewide Servicer Desk on 1300 2833 55</div>
            <div>
              <Button onClick={logoutApp} type='primary'>
                Logout
              </Button>
            </div>
          </div>
        )}
      </Aux>
    );
};

export default MainLayout;
