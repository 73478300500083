import { Button, Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {useMediaQuery, useTheme} from "@material-ui/core";


const StyledMenuItem = styled(Menu.Item)`
    & .anticon {
        font-size: 20px !important;
    }
`;

const ActionButtonsDropdown = ({ menus, tabName='' }) => {
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderMenus = (
        <Menu>
            { menus.map((menu,index) =>
                !menu.hide &&
                <>
                    {
                        menu.dividerBefore && <Menu.Divider />
                    }
                    <StyledMenuItem
                        id={menu?.id}
                        key={index}
                        icon={menu.icon}
                        onClick={() => !menu.link ? menu.onClick() : history.push(menu.link)}
                    >
                        {menu.label}
                    </StyledMenuItem>
                </>
            )}
        </Menu>
    );
    
    return (
        <Dropdown overlay={renderMenus} trigger={fullScreen ? ['click'] : ['hover']}>
            <Button type='primary' style={{fontSize: '12px'}} id={(tabName) ? `${tabName}SelectOptionButton` : `SelectOptionButton`}>Select Option</Button>
        </Dropdown>
    )
}

export default ActionButtonsDropdown;