import {keycloak} from 'api/keycloak';
import {validate} from 'api/msal';
import {getToken} from 'components/token';

const instance = {}
export const AuthUtils = () => {
 return instance;
}

AuthUtils.keycloak = () => {
    return keycloak.init({
        onLoad: 'check-sso',
        // checkLoginIframe: false
    });
}

AuthUtils.getKeycloakToken = () => {
    return keycloak.token;
}

AuthUtils.handleKeycloakError = error => {
    // @todo make dialogue
    if (error.response?.status === 401) {
        console.log(error.response)
        // keycloak.logout();
    }
    // return Promise.reject(error);
}

AuthUtils.msAuth = () => {
    return validate();
}

AuthUtils.getMSAuthToken = () => {
    return getToken();
}

// todo general method
//  supposedly be able to set what kind of authentication
//  should generally be used globally
AuthUtils.authenticate = () => {
}

// todo general method
//  supposedly be able to set what kind of authentication
//  should generally be used globally
AuthUtils.getToken = () => {
}