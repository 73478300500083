import { SET_ACTIVE_USER } from 'redux/actions/types/user';

const INITIAL_STATE = {
    details: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
    const { type, user } = action;

    switch(type) {
        case SET_ACTIVE_USER: {
            return {
                ...state,
                details: {
                    firstName: user.firstName,
                    lastName: user.lastName
                }
            }
        }

        default:
            return state;
    }
}

export default userReducer;
