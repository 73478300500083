import {Modal, Button, Row, Col, Empty, Card, Typography} from "antd";
import AppointmentPage from "pages/Appointment";
import styled from 'styled-components';
import {DownCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
const { Title } = Typography;


const StyledModal = styled(Modal)`
    width: 700px !important;
    .ant-modal-body {
        @media only screen and (max-width: 600px) {
            padding: 10px;
        }
    }
    .button {   
        width: 100%;
        margin-bottom: 10px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 600px) {
            height: 50px;
        }
      }
    .animate {
      border: none!important;
      -webkit-animation: pulsing 1.25s infinite;
      -moz-animation: pulsing 1.25s infinite;
      -ms-animation: pulsing 1.25s infinite;  
      animation: pulsing 1.25s infinite;
      transition: all 300ms ease-in-out !important;
    
      &:hover {
        webkit-animation: none!important;
      }

      @-webkit-keyframes pulsing {
          to {
            box-shadow: 0 0 0 8px rgba(255,255,0, 1);
          }
        }
          
        @-moz-keyframes pulsing {
          to {
            box-shadow: 0 0 0 8px rgba(255,255,0, 1);
          }
        }
          
        @-ms-keyframes pulsing {
          to {
            box-shadow: 0 0 0 8px rgba(255,255,0, 1);
          }
        }
          
        @keyframes pulsing {
          to {
            box-shadow: 0 0 0 8px rgba(255,255,0, 1);
          }
        }
    }
   .success {
        background: #32CD32;
        color: #FFFFFF
   }
   .button-label {
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
   }
   .icon {
    font-size: 25px;
   }
   .scroll {
    max-height: calc(100vh - 165px);
    overflow: scroll;
    overflow-x: clip;

    @media only screen and (max-width: 600px) {
        max-height: calc(100vh - 195px);
    }
   }
`;

const CallPatientInfoModal = ({visible, setVisibility, appointment, returnToQueue, endConsult, changeQueue}) => {
  let isReception = appointment.queue && appointment.queue.clinic.reception;
  return (
    <StyledModal
      visible={visible}
      onCancel={() => setVisibility(false)}
      closable={false}
      maskClosable={false}
      footer={null}
      style={{top: 20}}
    >
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Button className="button" type="primary" onClick={() => returnToQueue(appointment.id)}><span
            className="button-label">Return To Queue</span> <DownCircleOutlined className="icon"/></Button>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          {isReception && ((appointment.appointment && appointment.patient)) ?
            <Button className="button success animate" onClick={() => changeQueue(appointment)}> <span
              className="button-label"> Change Queue </span> <CheckCircleOutlined className="icon"/></Button> :
            <Button className="button success animate" onClick={() => endConsult(appointment.id)}> <span
              className="button-label"> {isReception ? 'Complete' : 'End Consult'} </span> <CheckCircleOutlined
              className="icon"/></Button>
          }
        </Col>
      </Row>
      {(appointment.appointment && appointment.appointment.id) ? <div className="scroll">
          <AppointmentPage id={appointment.appointment.id} fullWidth={true}/></div> :
        <Card
          className='h-110 appointment-summary-card'
          title={
            <Title
              level={3}
              className='mb-0 d-block d-flex text-primary font-weight-bold'
            >
              Patient Details
            </Title>
          }
        >
          {appointment.patient && <>
            <Row>
              <Col span={8}>Name:</Col>
              <Col span={16}>
                <strong>{`${appointment.patient?.firstName} ${appointment.patient?.lastName}`}</strong>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Contact #:</Col>
              <Col span={16}>
                <strong>{appointment.patient?.contactNumber}</strong>
              </Col>
            </Row>
            <Row>
              <Col span={8}>MRN:</Col>
              <Col span={16}>
                <strong>{appointment.patient?.mrn}</strong>
              </Col>
            </Row>
            <Row>
              <Col span={8}>Patient ID:</Col>
              <Col span={16}>
                <strong>{appointment.patient?.uniqueId}</strong>
              </Col>
            </Row>
          </>
          }
          <Row>
            <Col span={8}>Ticket Number:</Col>
            <Col span={16}>
              <strong>{appointment.ticketNumber}</strong>
            </Col>
          </Row>
        </Card>
      }
    </StyledModal>
  )
}
export default CallPatientInfoModal;