import React, {useEffect, useState} from 'react';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import MSLandingPage from 'pages/Landing/ADLanding';
import {Redirect, Switch} from 'react-router';
import routes from 'routes';
import {login, logout} from 'api/msal';
import {setToken} from 'components/token';
import {AuthContext} from 'hooks';
import {BrowserRouter, Route} from 'react-router-dom';
import {AuthUtils} from 'utils/AuthUtil';
import {getUserDetails} from "../../api/auth";
import {message} from "antd";
import {useUserAuthentication} from "hooks/useUserAuthentication";
import {setTokens} from "redux/actions/auth";
import {useDispatch} from "react-redux";
import {saveAuth} from "utils/helpers";
import MainLayout from "../Layout/MainLayout";
import { setActiveUser } from 'redux/actions/user';

const MsAuthPage = () => {
    const landingUri = '/';
    const baseURI = ''
    const [isLoading, setIsLoading] = useState(true);
    const [authProps, setAuthProps] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const { authenticated } = useUserAuthentication();
    const dispatch = useDispatch();

    const [sessionState, setSessionState] = useState({
        sessionDetails: [
            {
                firstName: '',
                lastName: ''
            }
        ],
        otherState: 'not sure what this is'
    });

    useEffect(() => {
        console.log('authenticated', authenticated);
        if (authenticated) {
            fetchUserDetails();
        }
    }, [authenticated]);

    const fetchUserDetails = async () => {
        try {
            const response = await getUserDetails();
            if (response?.data) {
                setSessionState({
                    sessionDetails: [response.data],
                    otherState: null
                });

                dispatch(setActiveUser(response.data))
            }
        } catch (error) {
            message.error('Fetching user details failed', 3);
        }
    };

    const defaultUri = routes(false)[0]?.path;
    useEffect(() => {
        AuthUtils.msAuth()
            .then(result => {
                console.log('Silent SSO success', result);
                setToken(result.idToken.rawIdToken);
                setIsAuthenticated(true)
                setAuthProps({
                    isAuthenticated: true,
                    login: login,
                    logout: logout
                })

                dispatch(
                    setTokens({
                        access_token: result.idToken.rawIdToken,
                        refresh_token: 'test',
                        isUserAuthenticated: true,
                    })
                );
                saveAuth(result.idToken.rawIdToken, 'test', 1000);

                setIsLoading(false);
            })
            .catch(e => {
                console.error('Silent SSO failed', e);
                setIsAuthenticated(false)
                setIsLoading(false);

                setAuthProps({
                    isAuthenticated: false,
                    login: login,
                    logout: logout
                })
            });
    }, [])
    return <AuthContext.Provider value={{
        isAuthenticated: isAuthenticated,
        login: login,
        logout: logout
    }}>
        <BrowserRouter basename=''>
            <Switch>
                <Route path='/console/meeting/:meetingNumber/:meetingPassword/:appointmentId'
                       component={(props) => {

                           console.log('starting a meeting with', props.match.params.meetingNumber);
                           window.location.href = 'http://localhost:3001/meeting/'
                               + props.match.params.meetingNumber + '/'
                               + props.match.params.meetingPassword + '/'
                               + props.match.params.appointmentId;
                           return null;
                       }}/>

                <AuthenticatedRoute
                    exact path={landingUri}
                    onInit={() => {
                        console.log('admin landing on init')
                        return <MSLandingPage isLoading={isLoading}/>
                    }}
                    onAuthenticated={() => {
                        console.log('admin landing on authenticated', defaultUri)
                        return <Redirect to={defaultUri}/>
                    }}
                    onUnauthenticated={() => {
                        console.log('admin landing on unauthenticated')
                        return <MSLandingPage isLoading={isLoading}/>
                    }}/>

                <AuthenticatedRoute
                    onInit={() => {
                        console.log('main on init')
                        return <MSLandingPage isLoading={isLoading}/>
                    }}
                    onAuthenticated={() => {
                        return <MainLayout fullName={sessionState.sessionDetails.length > 0 && sessionState.sessionDetails[0].firstName.concat(' ', sessionState.sessionDetails[0].lastName)}/>;
                    }}
                    onUnauthenticated={() => {
                        return <Redirect to={landingUri}/>
                    }}/>
            </Switch>
        </BrowserRouter>
    </AuthContext.Provider>

}
export default MsAuthPage;