import React, { useEffect, useState } from 'react';
import {Button, message, Modal, Select} from 'antd';
import {updateAppointmentStatus} from "../../../api/appointments";
import titleize from "titleize";
import { CloseOutlined } from "@ant-design/icons";

const {Option} = Select;

function StatusModal(props) {
    const {
        setStatusModalVisibility,
        isStatusModalVisible,
        id,
        onSuccess
    } = props;

    const [statusValue, setStatusValue] = useState(null);

    const statusList = [
        { id: "StatusModalRequestOption",value: 'REQUEST_RESCHEDULE', displayText: 'REQUEST RESCHEDULE' },
        // { value: 'SCHEDULED', displayText: 'SCHEDULED' },
        // { value: 'IN_PROGRESS', displayText: 'IN PROGRESS' },
        // { value: 'COMPLETE', displayText: 'COMPLETE' },
        { id: "StatusModalCancelOption",value: 'CANCELLED', displayText: 'CANCELLED' },
        { id: "StatusModalUnconfirmOption",value: 'UNCONFIRMED', displayText: 'UNCONFIRMED' },
        // { value: 'DECLINED', displayText: 'DECLINED' },
        { id: "StatusModalConfirmOption",value: 'CONFIRMED', displayText: 'CONFIRMED' },
        // { value: 'CHECKED_IN_WAITING', displayText: 'CHECKED IN WAITING' },
        // { value: 'CALLED', displayText: 'CALLED' },
        // { value: 'CALLED_BACK_TO_QUEUE', displayText: 'CALLED BACK TO QUEUE' },
        { id: "StatusModalCheckInOption",value: 'CHECKED_IN', displayText: 'CHECKED IN' },
        // { value: 'IN', displayText: 'IN' },
        { id: "StatusModalCheckOutOption",value: 'CHECKED_OUT', displayText: 'CHECKED OUT' },
        { id: "StatusModalNoShowOption",value: 'NO_SHOW', displayText: 'NO SHOW' },
        // { value: 'START_CONSULT', displayText: 'START CONSULT' },
        // { value: 'END_CONSULT', displayText: 'END CONSULT' }
    ];

    useEffect(() => {
        if (!isStatusModalVisible) {
            setStatusValue(null);
        }
    }, [isStatusModalVisible]);

    const onSaveChange = async () => {
        try {
            if (statusValue) {
                await updateAppointmentStatus({
                    id: id,
                    status: statusValue
                });
                message.success(`${titleize('Appointment status')} changed.`);
                if (onSuccess) {
                    onSuccess();
                }
                setStatusModalVisibility(false);
            } else {
                message.error('Please select a status to continue.');
            }
        } catch(err) {
            message.destroy();
            message.error(err?.response?.data?.message);
        }
    }

    const OnCancel = () => {
        setStatusModalVisibility(false)
    }

    return (
        <Modal
            id="Ok"
            title='Change Appointment Status'
            visible={isStatusModalVisible}
            destroyOnClose={true}
            closeIcon={<CloseOutlined id='closeStatusModal' onClick={OnCancel} />}
            footer={
                [
                <Button id="StatusModalCancelButton" onClick={OnCancel} type={'default'} >Close</Button>,<Button id="StatusModalSaveButton" type={'primary'} onClick={onSaveChange}>Save Changes</Button>]
            }
            centered
        >
            <label>Status:</label><br/>
            <Select
                id={"StuatusUpdateModalSelect"}
                showSearch
                placeholder="Select Status"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                size='large'
                style={{
                    width: '100%',
                }}
                onChange={(value) => setStatusValue(value)}
            >
                {statusList && statusList.length > 0 && statusList.map((status, i) => (
                    <Option id={status?.id ?? ''} key={status.value} value={status.value}>{status.displayText}</Option>
                ))}
            </Select>
        </Modal>
    );
}

export default StatusModal;
