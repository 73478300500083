import React, { useEffect, useState } from 'react';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import { getPatientTasks, updatePatientTaskStatus, deletePatientTask } from 'api/patientTasks';
import { getStaffTasks, deleteStaffTask } from 'api/staffTasks';
import CreatePatientTaskModal from 'pages/Appointment/components/Tasks/CreatePatientTaskModal';
import CreateStaffTaskModal from 'pages/Appointment/components/Tasks/CreateStaffTaskModal';
import RejectStaffTaskModal from 'pages/Appointment/components/Tasks/RejectStaffTaskModal';
import { Button, message, Modal, Space, Tabs, Tag,  Dropdown, Menu } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined, DeleteOutlined, UnorderedListOutlined, RollbackOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { updateStatus } from 'api/tasks';
import { useSelector, useDispatch } from 'react-redux';
import { setWillTaskListRefresh } from 'redux/actions/appointment';
import { isCurrentPermissionKeyPermitted } from 'utils';

const StyledTabs = styled(Tabs)`
    & .ant-tabs-nav-wrap {
        bottom: -15px;
        z-index: 10;
        left: 25px;
    }

    & .ant-tabs-nav {
        margin: 0 !important;
        position: absolute;
    }

    & .ant-tabs-tab {
        padding: 15px !important;
    }
`

const AppointmentTasks = (props) => {
    const { refreshTaskList, setRefreshTaskList, appointmentId } = props;
    const defaultActionColTitle = <strong>Actions</strong>;
    const [createPatientTaskVisible, setCreatePatientTaskVisibility] = useState(false);
    const [createStaffTaskVisible, setCreateStaffTaskVisibility] = useState(false);
    const [editStaffTaskVisible, setEditStaffTaskVisibility] = useState(false);
    const [editPatientTaskVisible,setEditPatientTaskVisibility] = useState(false);
    const [task, setTask] = useState(null);
    const [isRejectStaffTaskModalVisible, setRejectStaffModalVisibilty] = useState(false);
    const [selectedStaffTaskId, setSelectedStaffTaskId] = useState(null);
    const [selectedTab, setSelectedTab] = useState('PATIENT');
    const [drawPatientTasks, setDrawPatientTasks] = useState(0);
    const [drawStaffTasks, setDrawStaffTasks] = useState(0);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [deleteStaffVisible, setDeleteStaffVisible] = useState(false);
    const [entityId, setEntityId] = useState();
    const [newStatus, setNewStatus] = useState('NEW');
    const [modalMessage, setModalMessage] = useState('');
    const [taskType, setTaskType] = useState('PATIENT');
    const [permittedTabs, setPermittedTabs] = useState(null);
    const [patientTaskId, setPatientTaskId] = useState();
    const appointmentState = useSelector(state => state.appointment);
    const permissionState = useSelector(state => state.permissions);
    const dispatch = useDispatch();
    let renderMenus;
    const taskTabs = {
        PATIENT: {
            permissionKey: 'appointments:tasks:patient',
            onTabChange: (tab) => {
                setTaskType('PATIENT')
                setDrawPatientTasks(drawPatientTasks + 1);
                setSelectedTab(tab);
            }
        },
        STAFF: {
            permissionKey: 'appointments:tasks:staff',
            onTabChange: (tab) => {
                setTaskType('STAFF')
                setDrawStaffTasks(drawStaffTasks + 1);
                setSelectedTab(tab);
            }
        }
    }

    useEffect(() => {
        const newPermittedTabs = {};
        Object.keys(taskTabs).map((taskTabKey) => {
            const { permissionKey } = taskTabs[taskTabKey];
            if (isCurrentPermissionKeyPermitted(permissionKey, permissionState)) {
                newPermittedTabs[taskTabKey] = taskTabs[taskTabKey];
            }
        });
        setPermittedTabs(newPermittedTabs);
        setSelectedTab(Object.keys(newPermittedTabs)[0]);
    }, []);

    useEffect(() => {
        if (refreshTaskList) {
            setDrawPatientTasks(drawPatientTasks + 1);
            setRefreshTaskList(false);
        }
    }, [refreshTaskList]);

    useEffect(() => {
        if (appointmentState.willTaskListRefresh) {
            setDrawPatientTasks(drawPatientTasks + 1);
            dispatch(setWillTaskListRefresh(false));
        }
    }, [appointmentState.willTaskListRefresh]);

    useEffect(() => {
        if (appointmentId) {
            setDrawPatientTasks(drawPatientTasks + 1);
        }
    }, [appointmentId]);

    const renderPatientTasksAction = (text, record, value) => {
        let action = null;
        if (isCurrentPermissionKeyPermitted('appointments:tasks:patient:list:columns:actions', permissionState)) {
            if (record.status === 'NEW') {
                action = <div
                    onClick={() => {
                        setPatientTaskId(text)
                        setModalMessage('Mark task as Complete?')

                        setNewStatus('COMPLETED')
                        setConfirmationVisible(true)
                    }}
                >
                 <CheckOutlined />    Complete
                </div>
            } else {
                action = <div
                    onClick={() => {
                        setPatientTaskId(text)
                        setModalMessage('Undo Completed task?')

                        setNewStatus('NEW')
                        setConfirmationVisible(true)
                    }}
                >
            <RollbackOutlined />    UnDo
                </div>
            }
        }
        const option = [
            {
                label: action
            },
            {
                label: 'Edit Task',
                icon: <EditOutlined />,
                onClick: () => {
                    setTask(record)
                    setEditPatientTaskVisibility(true)
                }
            },
            {
                label: 'Delete Task',
                icon: <DeleteOutlined />,
                onClick: () => {
                    setPatientTaskId(text)
                    setModalMessage('Are you sure you want to delete this task?')
                    setDeleteVisible(true)
                }
            }
        ];

        renderMenus = (
            <Menu>
                {option.map((menu , index) =>
                    !menu.hide &&
                    <React.Fragment key={index}>
                        {
                            menu.dividerBefore && <Menu.Divider />
                        }
                        <Menu.Item
                            // id={"patientTask"+menu.label}
                            // id={`patientTask ${menu.label}`}
                            key={menu.key ?? index}
                            icon={menu.icon}
                            onClick={menu.onClick}
                        >
                            {menu.label}
                        </Menu.Item>
                    </React.Fragment>
                )}
            </Menu>
        );
        return (
            isCurrentPermissionKeyPermitted('appointments:tasks:patient:list:columns:actions', permissionState)?
            <div >
                <Dropdown  overlay={renderMenus} trigger={['hover', 'click']} >
                    <Button id='patientSelectOption'>{'Select Option'}</Button>
                </Dropdown>
            </div>: ''
        );
    }

    const renderStaffTasksAction = (text, record, value) => {
        let action = null;
        if (isCurrentPermissionKeyPermitted('appointments:tasks:staff:list:columns:actions', permissionState)) {
            if (record.status === 'NEW') {
                action = (
                    <Space>
                        <div
                            onClick={() => {
                                setEntityId(text);
                                setModalMessage('Mark task as Complete?');

                                setNewStatus('COMPLETED');
                                setConfirmationVisible(true);
                            }}
                        >
                            <CheckOutlined />    Complete
                        </div>
                    </Space>
                )
            } else if (record.status === 'COMPLETED') {
                action = <>
                    <div
                        onClick={() => {
                            setEntityId(text)
                            setModalMessage('Undo Completed task?')
                            setNewStatus('NEW')
                            setConfirmationVisible(true)
                        }}
                    >
                        <RollbackOutlined />    Undo
                    </div>
                </>
            } else if (record.status === 'REJECTED') {
                action = <>
                    <div
                        onClick={() => {
                            setEntityId(text)
                            setModalMessage('Undo Rejected task?')
                            setNewStatus('NEW')
                            setConfirmationVisible(true)
                        }}
                    >
                        <RollbackOutlined />    Undo
                    </div>
                </>
            }
        }
        const option = [
            {
                label: action
            },
            {
                label: 'Edit Task',
                icon: <EditOutlined />,
                onClick: () => {
                    setTask(record)
                    setEditStaffTaskVisibility(true)
                }
            },
            {
                label: 'Delete Task',
                icon: <DeleteOutlined />,
                onClick: () => {
                    setSelectedStaffTaskId(text)
                    setModalMessage('Are you sure you want to delete this task?')
                    setDeleteStaffVisible(true)
                }
            }
        ];
        record.status === 'NEW' && option.splice(1,0 , {
            label: 'Reject',
            icon: <CloseOutlined />,
            onClick: () => {
                setModalMessage('Reject task?')
                setEntityId(text);
                setNewStatus('REJECTED')
                setConfirmationVisible(true)
            }
        }
        );

        renderMenus = (
            <Menu>
                {option.map((menu , index) =>
                    !menu.hide &&
                    <React.Fragment key={index}>
                        {
                            menu.dividerBefore && <Menu.Divider />
                        }
                        <Menu.Item
                        //  id={"staffTask"+(menu.label? menu.label : "")}
                            key={menu.key ?? index}
                            icon={menu.icon}
                            onClick={menu.onClick}
                        >
                            {menu.label}
                        </Menu.Item>
                    </React.Fragment>
                )}
            </Menu>
        );
        
        return (
            isCurrentPermissionKeyPermitted('appointments:tasks:staff:list:columns:actions', permissionState)?
            <div >
                <Dropdown  overlay={renderMenus} trigger={['hover', 'click']} >
                    <Button id='staffSelectOption'>{'Select Option'}</Button>
                </Dropdown>
            </div> : ''
        );
    }
      

    const onOk = () => {
        updateStatus(entityId, {
            rejectionReason: '',
            status: newStatus
        })
            .then(res => {
                setDrawStaffTasks(drawStaffTasks + 1);
                setConfirmationVisible(false);
            });
    }

    const onPatientOk = () => {
        setDrawPatientTasks(0);
        updatePatientTaskStatus(patientTaskId, newStatus)
            .then(res => {
                message.success('Successfully updated task.');
                setDrawPatientTasks(drawPatientTasks + 1);
                setConfirmationVisible(false)
            });
    }

    const onDeleteOk = () => {
        setDrawPatientTasks(0);
        deletePatientTask(patientTaskId)
            .then(res => {
                message.success('Successfully deleted task.');
                setDrawPatientTasks(drawPatientTasks + 1);
                setDeleteVisible(false)
            });
    }
    const onDeleteStaffOk = () => {
        setDrawStaffTasks(0);
        deleteStaffTask(selectedStaffTaskId)
            .then(res => {
                message.success('Successfully deleted task.');
                setDrawStaffTasks(drawPatientTasks + 1);
                setDeleteStaffVisible(false)
            });
    }

    const fetchPatientTasks = async (params) => {
        params = {
            ...params,
            sortColumn: 'id',
            appointmentId
        }
        return await getPatientTasks(params)
    }

    const fetchStaffTasks = async (params) => {
        params = {
            ...params,
            sortColumn: 'id',
            appointmentId
        }
        return await getStaffTasks(params);
    }

    const renderActionColumn = (activeTab) => {
        let actionColumn = null;

        if ((activeTab === 'PATIENT' && isCurrentPermissionKeyPermitted('appointments:tasks:patient:list:columns:actions', permissionState)) || (activeTab === 'STAFF' && isCurrentPermissionKeyPermitted('appointments:tasks:staff:list:columns:actions', permissionState))) {
            actionColumn = <strong>Actions</strong>;
        }

        return actionColumn;
    }

    const columns = [
        {
            title: <strong>Task</strong>,
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            render: (text, record, value) => {
                return <>{text}
                    <Tag visible={record.status === 'COMPLETED'}
                        color='green'
                        icon={<CheckCircleFilled style={{ position: 'relative', top: '-2px', height: '10px' }} />}
                        style={{ marginLeft: '5px' }}
                    >
                        DONE
                    </Tag>
                    <Tag
                        visible={record.status === 'REJECTED'}
                        color='red'
                        icon={<CloseCircleOutlined style={{ position: 'relative', top: '-2px', height: '10px' }} />}
                        style={{ marginLeft: '5px' }}
                    >
                        REJECTED
                    </Tag>
                </>

            }
        },
        {
            title: () => renderActionColumn(selectedTab),
            dataIndex: 'id',
            key: 'id',
            sorter: false,
            render: selectedTab === 'PATIENT' ? renderPatientTasksAction : renderStaffTasksAction
        }
    ];


    return (
        <>
            <Modal
                visible={confirmationVisible}
                onCancel={() => setConfirmationVisible(false)}
                title={null}
                onOk={taskType === 'STAFF' ? onOk : onPatientOk}
                okButtonProps={{id: 'ConfirmTaskOkButton'}}
                cancelButtonProps={{id: 'ConfirmTaskCancelButton'}}
                closeIcon={<CloseOutlined id="ConfirmModalCloseIcon" />}
            >
                <strong>{modalMessage}</strong>
            </Modal>
            <Modal
                visible={deleteVisible}
                onCancel={() => setDeleteVisible(false)}
                title={null}
                onOk={ onDeleteOk }
                okButtonProps={{id: 'DeleteTaskOkButton'}}
                cancelButtonProps={{id: 'DeleteTaskCancelButton'}}
                closeIcon={<CloseOutlined id="DeleteModalCloseIcon" />}
            >
                 <strong>{modalMessage}</strong>   
            </Modal>
            <Modal
                visible={deleteStaffVisible}
                onCancel={() => setDeleteStaffVisible(false)}
                title={null}
                onOk={ onDeleteStaffOk }
                okButtonProps={{id: 'DeleteStaffTaskOkButton'}}
                cancelButtonProps={{id: 'DeleteStaffTaskCancelButton'}}
                closeIcon={<CloseOutlined id="DeleteStaffModalCloseIcon" />}
            >
                <strong>{modalMessage}</strong>
            </Modal>
            <RejectStaffTaskModal
                visible={isRejectStaffTaskModalVisible}
                setVisibility={setRejectStaffModalVisibilty}
                id={selectedStaffTaskId}
                onSuccess={() => setDrawStaffTasks(drawStaffTasks + 1)}
            />
                    <CreateStaffTaskModal
                                visible={editStaffTaskVisible}
                                setVisibility={setEditStaffTaskVisibility}
                                task={task}
                                appointmentId={props.appointmentId}
                                icon={<UnorderedListOutlined className='mr-2' />}
                                fetchData={() => setDrawStaffTasks(0)}
                                onSuccess={() => setDrawStaffTasks(drawStaffTasks +1)}
                                onCancel={() => {
                                    setDrawStaffTasks(0);
                                    setEditStaffTaskVisibility(false)
                                }}
                            />
                        <CreatePatientTaskModal
                                taskType='CUSTOM'
                                visible={editPatientTaskVisible}
                                task={task}
                                setVisibility={setEditPatientTaskVisibility}
                                appointmentId={props.appointmentId}
                                icon={<UnorderedListOutlined className='mr-2' />}
                                onSuccess={() => setDrawPatientTasks(drawPatientTasks +1)}
                                onCancel={() => {
                                    setDrawPatientTasks(0);
                                    setEditPatientTaskVisibility(false)
                                }}
                            />
            <StyledTabs
                activeKey={selectedTab}
                onChange={(tab) => {
                    if (permittedTabs && permittedTabs[tab]) {
                        permittedTabs[tab]?.onTabChange(tab);
                    }
                }}
            >
                {permittedTabs && permittedTabs['PATIENT'] && <StyledTabs.TabPane id="patientTasksTab" tab={<span id="patientTaskTab" className='text-primary font-weight-bold'>Patient Tasks</span>} key='PATIENT'>
                    <CRUDDataTable
                        draw={drawPatientTasks}
                        tabs={true}
                        fetchDataFunction={fetchPatientTasks}
                        entity='patient task'
                        createModal={
                            <CreatePatientTaskModal
                                taskType='CUSTOM'
                                visible={createPatientTaskVisible}
                                setVisibility={setCreatePatientTaskVisibility}
                                appointmentId={props.appointmentId}
                                icon={<UnorderedListOutlined className='mr-2' />}
                                onSuccess={() => setDrawPatientTasks(0)}
                                onCancel={() => {
                                    setDrawPatientTasks(0);
                                    setCreatePatientTaskVisibility(false)
                                }}
                            />
                        }
                        isButtonShown={isCurrentPermissionKeyPermitted('appointments:tasks:patient:new', permissionState)}
                        columns={columns}
                    />
                </StyledTabs.TabPane>}
                {permittedTabs && permittedTabs['STAFF'] && <StyledTabs.TabPane id="staffTasksTab" tab={<span className='text-primary font-weight-bold'>Staff Tasks</span>} key='STAFF'>
                    <CRUDDataTable
                        draw={drawStaffTasks}
                        tabs={true}
                        fetchDataFunction={fetchStaffTasks}
                        entity='staff task'
                        createModal={
                            <CreateStaffTaskModal
                                visible={createStaffTaskVisible}
                                setVisibility={setCreateStaffTaskVisibility}
                                appointmentId={props.appointmentId}
                                icon={<UnorderedListOutlined className='mr-2' />}
                                fetchData={() => setDrawStaffTasks(0)}
                                onCancel={() => {
                                    setDrawStaffTasks(0);
                                    setCreateStaffTaskVisibility(false)
                                }}
                            />
                        }
                        isButtonShown={isCurrentPermissionKeyPermitted('appointments:tasks:staff:new', permissionState)}
                        columns={columns}
                    />
                </StyledTabs.TabPane>}
            </StyledTabs>
        </>
    )
}
    ;

export default AppointmentTasks
