import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Button, Modal} from 'antd';
import {Dialog, DialogContent, DialogTitle, DialogActions, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from 'antd/es/date-picker';
import Typography from '@material-ui/core/Typography';

import 'styles/DateRangeModal.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

export default ({
                    title,
                    subtitle,
                    visible,
                    setVisible,
                    pickerSize,
                    fullScreen=false,
                    closable=true,
                    onOk,
                    onCancel,
                    okText,
                    cancelText = null
                }) => {

    const handleOnOk = (e) => {
        const isValid = validate(componentValue);
        if(isValid && onOk) {
            onOk(e, componentValue);
            setVisible(false);
            setComponentValue(null);
        }
    }

    const handleOnCancel = (e) => {
        if(onCancel) onCancel(e);
        setVisible(false);
        setComponentValue(null);
    }

    const {RangePicker} = DatePicker;

    const [componentValue, setComponentValue] = useState(null);
    const [errMessage, setErrMessage] = useState(null);

    useEffect(() => {validate(componentValue)}, [componentValue])
    useEffect(() => {setErrMessage(null)}, [visible]);

    function validate(componentValue) {
        const isValid = !!componentValue?.length > 0;
        if(!isValid) {
            setErrMessage('Date filter is required');
        } else {
            setErrMessage(null);
        }

        return isValid;
    }

    return (
        <Dialog
            open={visible}
            fullScreen={fullScreen}
            onClose={handleOnCancel}
            style={{zIndex: '100'}}
        >
            <DialogTitle disableTypography onClose={handleOnCancel}>
                <Typography variant='h6'>{title}</Typography>
                {
                    closable && <IconButton
                        style={{position:'absolute', right:'8px', top:'8px'}}
                        onClick={handleOnCancel}>
                        <CloseIcon />
                    </IconButton>
                }
            </DialogTitle>
            <DialogContent>
                <Row>
                    <Col xs={12}>
                        {subtitle}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <RangePicker format='DD-MM-YYYY' value={componentValue} onChange={(value) => setComponentValue(value)}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span
                            style={{fontSize: 'small', color:' red'}}>{errMessage}</span>
                    </Col>
                </Row>
            </DialogContent>
            <DialogActions>
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Button
                                block
                                className='Dialog-Action-Button'
                                onClick={handleOnCancel}>
                                { cancelText?? 'Cancel' }
                            </Button>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Button
                                block
                                className='Dialog-Action-Button'
                                onClick={handleOnOk}
                                type='primary'>
                                { okText?? 'OK'}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </DialogActions>
        </Dialog>
    );
}