import { message, Modal, Typography } from "antd";
import parse from 'html-react-parser';
import titleize from 'titleize';
import { CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const DeleteModal = ({ entity, visible, setVisibility, entityId, deleteFunction, onSuccess }) => {
    return (
        <Modal
            title={<Text id="DeleteResourceModalTitle" type="danger">Delete {titleize(entity)}</Text>}
            visible={visible}
            cancelButtonProps={{
              id: 'MessageModalCancelButton'
            }}
            onCancel={() => setVisibility(false)}
            closeIcon={(<CloseOutlined id='closeDeleteModal' />)}
            okButtonProps={{id:'MessageModalOkButton'}}
            okText={'Yes'}
            okType="danger"
            onOk={async () => {
                await deleteFunction(entityId).then((res) => {
                    onSuccess();
                    message.success(`${titleize(entity)} successfully deleted.`)
                    setVisibility(false)
                }).catch((err) => {
                    setVisibility(false)
                });

            }}
        >
            {parse(`Are you sure you want to delete this <strong>${entity}</strong>?`)}
        </Modal>
    )
}

export default DeleteModal;