import React from 'react';
import {DatePicker, Input, Select} from "antd";

const FilterComponent = ({ value, component, onChange }) => {
    const val = value? value.value : null;
    switch(component.name) {
        case 'select': return <Select 
        showSearch
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        id={`${value?.label}DatatableFilter`}
        style={{ width: '100%', minWidth: 220 }} 
        {...component} value={val} onChange={onChange}>
            {
                component && component.fetchData().map(row =>
                    <Select.Option key={row.value} value={row.value}>{row.displayText}</Select.Option>)
            }
        </Select>

        case 'daterange': return <DatePicker.RangePicker id={`${value?.label}DatatableFilter`} value={val} {...component} onChange={onChange} />

        default: return <Input id={`${value?.label}DatatableFilter`} {...component} value={val} onChange={ e => onChange(e.target.value) }/>
    }
}

export default FilterComponent;