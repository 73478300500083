import { useSelector, useDispatch } from 'react-redux';
import { setTokens } from 'redux/actions/auth';
import { refreshTheToken } from 'utils/helpers';

export const useUserAuthentication = () => {
  const authState = useSelector((state) => state.auth);
  const { isUserAuthenticated } = authState;
  const dispatch = useDispatch();
  let authenticated = isUserAuthenticated;

  const token = sessionStorage.getItem('token');

  if (token) {
    if (!authenticated) {
      dispatch(
        setTokens({
          access_token: token,
          refresh_token: 'test',
          isUserAuthenticated: true,
        })
      )
    }
  }
  else {
    if (authenticated) {
      dispatch(
        setTokens({
          access_token: '',
          refresh_token: '',
          isUserAuthenticated: false,
        })
      )
    }
  }

  return { authenticated };
};
