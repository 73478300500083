import {Card, Col, message, Modal, Radio, Row, Select, Space, Typography} from 'antd';
import {Formik} from 'formik';
import {Form, Input, ResetButton, SubmitButton} from 'formik-antd';
import React, {useEffect, useRef, useState} from 'react';
import * as yup from 'yup';
import {createVisitor} from 'api/visits'
import {getAllLocations} from 'api/locations';
import HospitalSelect from 'components/location/hospitalSelect';
import { CloseOutlined } from "@ant-design/icons";

const {Title, Text} = Typography;
const CreateCollateralSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, "Too short.")
    .max(30, "Too long.")
    .required("Please enter your first name."),
  lastName: yup
    .string()
    .min(2, "Too short.")
    .max(255, "Too long.")
    .required("Please enter your last name."),
  email: yup
    .string()
    .email(),
  contactNumber: yup
    .number()
});

const CreateVisitorForm = ({visible, setVisibility, onSuccess}) => {
  const [files, setFiles] = useState([]);
  const formikRef = useRef(null);
  const {Option} = Select;
  const [type, setType] = useState('VISITOR')
  const [contactMethod, setContactMethod] = useState('BOTH')
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState();
  useEffect(() => {
    (async () => {
      let response = await getAllLocations();
      setHospitalOptions(response.data.data)
    })();
  }, [])

  function onChange(value) {
    console.log(value);
    setSelectedHospital(value);
  }

  function onBlur() {
    console.log('blur');
  }

  function onFocus() {
    console.log('focus');
  }

  function onSearch(val) {
    console.log('search:', val);
  }

  function onRemoveFile(file) {
    const index = files.indexOf(file);
    const newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  async function create(values) {
    try {
      values.type = type;
      values.preferredContactMethod = contactMethod;

      let visit = {
        location: selectedHospital,
        visitor: {
          contactNumber: values.contactNumber,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          preferredContactMethod: values.preferredContactMethod
        },
        type: values.type
      }
      console.log(visit)


      const form = new FormData();
      message.loading('Creating visitor...', 0);
      let data = new Blob([JSON.stringify(visit)], {type: 'application/json'});
      form.append("data", data);
      form.append("file", values.file);

      const res = await createVisitor(form);
      console.log(res);


      // @TODO: Remove in production, or maybe change it later on

      // Object.keys(values).forEach(key => form.append(key, values[key]));
      // files.forEach(file => form.append("file", file, file.name));
      // const res = await createCollateral(form);
      message.destroy();
      message.success('New visitor created.');
      onSuccess();
      setVisibility(false)
    } catch (error) {
      message.destroy();
      message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  const style = {width: '90%'};

  return (
    <Modal
      title={<Title level={4} className="mb-0" style={{display: "flex", alignItems: "center"}}>New Visitor</Title>}
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
      width={'70%'}
      closeIcon={(<CloseOutlined id='closeVisitorFormModal' />)}
      destroyOnClose
    >
      <Formik
        innerRef={formikRef}
        initialValues={{firstName: "", lastName: ""}}
        validationSchema={CreateCollateralSchema}
        onSubmit={values => create(values)}
        onReset={() => setFiles([])}
        render={() => (
          <Form layout="vertical" colon={false}>
            <Space direction="vertical" style={{width: '100%'}}>
              <Card title="Visitor details" style={{width: '100%'}}>
                <Row>


                  <Col span={12}>


                    <Form.Item label="First Name" name="firstName" className="mb-4">
                      <Input id='createVisitorFormFirstNameInput' name="firstName" style={style}/>
                    </Form.Item>
                    <Form.Item label="File" name="file" className="mb-4">
                      <Input id='createVisitorFormFileInput' type='file' name="file" style={style}/>
                    </Form.Item>
                    <Form.Item label="Last Name" name="lastName" className="mb-4">
                      <Input id='createVisitorFormLastNameInput' name="lastName" style={style}/>
                    </Form.Item>
                    <Form.Item label="Visitor Type" name="type" className="mb-4">
                      <Radio.Group style={style} onChange={e => setType(e.target.value)} defaultValue="VISITOR"
                                   buttonStyle="solid">
                        <Radio.Button value="VISITOR">Visitor</Radio.Button>
                        <Radio.Button value="PATIENT">Patient</Radio.Button>
                        <Radio.Button value="STAFF">Staff</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>

                    <Form.Item label="Email" name="email" className="mb-4">
                      <Input id='createVisitorFormEmailInput' name="email" style={style}/>
                    </Form.Item>
                    <Form.Item label="Contact Number" name="contactNumber" className="mb-4">
                      <Input id='createVisitorFormContactNumberInput' name="contactNumber" style={style}/>
                    </Form.Item>
                    <Form.Item label="Contact Method" name="contactMethod" className="mb-4">
                      <Radio.Group style={style} onChange={e => setContactMethod(e.target.value)} defaultValue="a"
                                   buttonStyle="solid">
                        <Radio.Button value="EMAIL">Email</Radio.Button>
                        <Radio.Button value="SMS">Sms</Radio.Button>
                        <Radio.Button value="BOTH">Both</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                </Row>
              </Card>
              <Card title="Patient details">
                <HospitalSelect onChange={onChange}
                                removeAllOption={true}
                                useEntity={true}
                                />
              </Card>
              <Row gutter={4} className="d-flex justify-content-end">
                <Col>
                  <ResetButton id='createVisitorFormResetButton'>Reset</ResetButton>
                </Col>
                <Col>
                  <SubmitButton id='createVisitorFormCreateButton'>Create</SubmitButton>
                </Col>
              </Row>
            </Space>
          </Form>
        )}
      />


    </Modal>
  );
};

export default CreateVisitorForm;
