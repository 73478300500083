import React, { useEffect, useRef, useState } from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteCollateral, getCollateralsDatatable } from "api/collaterals";
import {
    getAllLocations,
} from "api/locations";
import DeleteModal from "components/Modals/DeleteModal";
import { getCollateralURL } from 'api/collaterals';
import { Link } from "react-router-dom";
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CollateralForm from 'pages/Collateral/components/CollateralForm';
import { isCurrentPermissionKeyPermitted } from "utils";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Row, Col, Typography, Space, Input } from 'antd';
import { setFacility } from "redux/actions/collateralList";
import moment from 'moment';
import { setFilterIsLoading } from "redux/actions/filter";
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';

const DEFAULT_FILTERS = {
    facility: undefined,
    department: undefined,
    clinic: undefined,
    label: ''
};

const CollateralPage = (props) => {
    const [draw, setDraw] = useState(0);

    const [collateralId, setCollateralId] = useState("");
    const [collateral, setCollateral] = useState(null);
    const [collateralFormVisibility, setCollateralFormVisibility] = useState(false);
    const permissionState = useSelector(state => state.permissions);
    const [customFilters, setCustomFilters] = useState(Object.keys(DEFAULT_FILTERS).map((paramName) => ({
        paramName,
        value: DEFAULT_FILTERS[paramName]
    })));
    const [search, setSearch] = useState(1);
    const [dataTableParams, setDataTableParams] = useState(DEFAULT_FILTERS);
    const [facilityValue, setFacilityValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [clinicValue, setClinicValue] = useState(null);
    const [locationCalled, setLocationCalled] = useState(0);
    const [locations, setLocations] = useState([]);
    const initial = useRef(true);

    const {Text} = Typography;

    const dispatch = useDispatch();

    useEffect(() => {
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => ({
            paramName,
            value: dataTableParams[paramName],
        }))
        setCustomFilters(updatedCustomFilters)
    }, [search])

    const setLocationParams = (data) => {
        const locationObj = getLocationValues(data);
        if (locationObj.facility) {
            setFacilityValue(locationObj.facility);
        }
        if (locationObj.department) {
            setDepartmentValue(locationObj.department);
        }
        if (locationObj.clinic) {
            setClinicValue(locationObj.clinic);
        }
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => {
            if (paramName === 'facility' || paramName === 'department' || paramName === 'clinic') {
                return ({
                    paramName,
                    value: locationObj[paramName],
                })
            }
            else {
                return ({
                    paramName,
                    value: dataTableParams[paramName],
                })
            }
        });
        setDataTableParams((params) => ({
            ...params,
            facility: locationObj['facility'],
            department: locationObj['department'],
            clinic: locationObj['clinic']
        }));
        setCustomFilters(updatedCustomFilters);
    
        setLocationCalled(1);
    }

    const criteriaNotSearched = () => {
        if(!initial.current) {
            for (let filter of customFilters) {
                if (dataTableParams[filter.paramName] !== filter.value) {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        dispatch(setFilterIsLoading(true));
        fetchLocationsData();
    }, []);
    

    const fetchLocationsData = async () => {
        let res = await getAllLocations();
        setLocations(res.data.data);
        setLocationParams(res.data.data);
        let facility = [];
        res.data.data.map((f) => {
            facility = [
                ...facility,
                f
            ]
        })
        dispatch(setFacility(facility));
    }

    const columns = [
        {
            title: <strong>Resource Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: true,
            render: (value, record, index) => {
                return <Button id={value?.replace(' ',"")} type="link" onClick={async () => {
                    let response = await getCollateralURL(record?.id);
                    let url = response?.data?.match(/^https?:\/\//i) ? response?.data : '//' + response?.data;
                    // console.log(response) how am i supposed to know if it should be file or url?
                    window.open(url);
                }}>{value}</Button>;

            },
        },
        {
            title: <strong>Description</strong>,
            dataIndex: "description",
            key: "description",
            sorter: true,
        },
        {
            title: <strong>URL</strong>,
            dataIndex: "url",
            key: "url",
            sorter: true,
        },
        {
            title: <strong>Facility</strong>,
            dataIndex: "facility",
            key: "facility",
            render: (value, record, index) => {
                return <>{value?.name}</>;

            },
            sorter: true,
        },
        {
            title: <strong>Department</strong>,
            dataIndex: "department",
            key: "department",
            render: (value, record, index) => {
                return <>{value?.name}</>;
            },            
            sorter: true,
        },
        {
            title: <strong>Clinic</strong>,
            dataIndex: "clinic",
            render: (value, record, index) => {
                return <>{value?.name}</>;

            },            
            key: "clinic",
            sorter: true,
        },
        {
            title: <strong>Label</strong>,
            dataIndex: "label",
            key: "label",
            sorter: true,
        },
        {
            title: <strong>Modified Date</strong>,
            dataIndex: "lastModifiedAt",
            key: "lastModifiedAt",
            sorter: true,
            render: (text, record, value) => {
                return moment(record?.lastModifiedAt).format('DD/MM/YYYY');
            }
        },
        {
            title: isCurrentPermissionKeyPermitted('resources:options', permissionState) ? <strong>Options</strong> : null,
            dataIndex: "id",
            key: "id",
            sorter: false,
            render: (text, record, value) => {
                const collateralOptions = [
                    {
                        id: 'UpdateResourceOption',
                        permissionKey: 'resources:options:update',
                        label: 'Update Resource',
                        icon: <EditOutlined />,
                        onClick: async () => {
                            const response = await getCollateralURL(record?.id);
                            setCollateral({ ...record, fileURL: response?.data });
                            setCollateralFormVisibility(true);
                        }
                    },
                    {
                        id: 'DeleteResourceOption',
                        permissionKey: 'resources:options:delete',
                        label: 'Delete Resource',
                        icon: <DeleteOutlined />,
                        onClick: () => setCollateralId(text)
                    }
                ];

                const permittedCollateralOptions = collateralOptions.filter(collateralOption => (isCurrentPermissionKeyPermitted(collateralOption.permissionKey, permissionState)));

                return isCurrentPermissionKeyPermitted('resources:options', permissionState)
                    && <ActionButtonsDropdown
                        menus={permittedCollateralOptions}
                        tabName='Collateral'
                    />
            },
        },
    ];

    return (
        <>
            <DeleteModal
                entity="resource"
                visible={collateralId}
                setVisibility={setCollateralId}
                entityId={collateralId}
                deleteFunction={deleteCollateral}
                onSuccess={() => setDraw(draw + 1)}
            />
            <CollateralForm
                title={'Update Resource'}
                visible={collateralFormVisibility}
                collateral={collateral}
                setVisibility={setCollateralFormVisibility}
                onSuccess={() => setDraw(draw + 1)}
            />
            <Row>
                <Col>
                    <LocationSelect
                        locationData={locations}
                        facilityValue={facilityValue}
                        departmentValue={departmentValue}
                        clinicValue={clinicValue}
                        showSearchValue={true}
                        id="queueHospitalSelect"
                        width='275px'
                        direction='horizontal'
                        onChange={(value, isDepartment, action, locationObj) => {
                            initial.current = false;
                            if (locationObj === null) {
                                if (action === 1) {
                                    setDataTableParams((params) => ({
                                        ...params,
                                        facility: value,
                                        department: undefined,
                                        clinic: undefined
                                    }));
                                }
                                if (action === 2) {
                                    setDataTableParams((params) => ({
                                        ...params,
                                        department: value,
                                        clinic: undefined
                                    }));
                                }
                                if (action === 3) {
                                    setDataTableParams((params) => ({
                                        ...params,
                                        clinic: value,
                                    }));
                                }
                            }
                            else {
                                setDataTableParams((params) => ({
                                    ...params,
                                    facility: locationObj['facility'],
                                    department: locationObj['department'],
                                    clinic: locationObj['clinic']
                                }));
                            }
                        }
                        }
                        isClinicVisible={true}
                        showAll={true}
                    />
                </Col>
            </Row>
            <br/>
            <Row gutter={16}>
                <Col xs={12} lg={6} className="gutter-row">
                    <Space direction='vertical' size='small' className='w-100'>
                        <Text strong>Label</Text>
                        <Input
                            id="label"
                            className='w-100'
                            allowClear
                            value={dataTableParams.label}
                            placeholder={'Search by Label'}
                            type='text'
                            name='label'
                            onChange={(e) => {
                                initial.current = false;
                                const value = e.target.value;
                                setDataTableParams((params) => ({
                                    ...params,
                                    label: value,
                                }));
                            }}
                        />
                    </Space>
                </Col>
                <Col xs={12} lg={4}>
                    <Space direction='vertical' size='small' className='w-100'>
                        <Text strong>&nbsp;</Text>
                        <Button
                            id='appointmentsSearch'
                            type={`${criteriaNotSearched() ? 'primary' : '' }`}
                            className={`w-100 ${!criteriaNotSearched() ? 'not-searched': ''}`}
                            onClick={() => setSearch((current) => current + 1)}
                        >
                            Search
                        </Button>
                    </Space>
                </Col>
            </Row>
            <br />
            {locationCalled > 0 ? <CRUDDataTable
                draw={draw}
                entity="resource"
                sortOrder='lastModifiedAt'
                fetchDataFunction={async (params) => {
                    params = {
                        ...params
                      }
                    return await getCollateralsDatatable(params);
                }}
                createModal={<CollateralForm title='New Resource' collateral={null} />}
                isButtonShown={isCurrentPermissionKeyPermitted('resources:new', permissionState)}
                columns={columns}
                customFilters={customFilters}
            /> : <></>}
        </>
    );
};

export default CollateralPage;
