import { message, Table } from 'antd';
import { getRoleConfig } from 'api/role';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import SelectCell from 'components/Controls/SelectCell';
import {getAllRolePermission, bulkUpdateRolePermission} from "api/rolePermissions";

const RolePermissionContainer = styled.div`
    padding: 24px;
    background: #fff;
`;

const RolePermissions = ({setOnSave, isEditable}) => {
    const [roleConfig, setRoleConfig] = useState([
        {
            "roleDetails": {
              "name": "Super User",
              "role": "ADMIN"
            },
            "permissions": [],
        },
        {
            "roleDetails": {
              "name": "Department Suupervisor",
              "role": "VACCINE_OPS"
            },
            "permissions": [],
        },
        {
            "roleDetails": {
              "name": "Department Admins",
              "role": "VACCINE_ADMIN"
            },
            "permissions": [],
        },
        {
            "roleDetails": {
              "name": "Clinicians",
              "role": "VACCINE_NURSE"
            },
            "permissions": [],
        }
    ]);
    const [moduleAccess, setModuleAccess] = useState([]);

    const requestForRoleConfigs = async () => {
        try {
            const response = await getRoleConfig();
            const moduleAccess = await getAllRolePermission();
            setRoleConfig(response.data);
            setModuleAccess(moduleAccess.data
                .reduce((result, value, i) => {
                    if(!result[value.module]) {
                        result[value.module] = [];
                    }
                    result[value.module].push({ value: value.uniqueId, display: value.accessType });
                    return result;
                }, {}));
        } catch (err) {
            message.error('Error occurred while trying to get all the role configurations.');
            setRoleConfig([]);
        }
    };

    useEffect(() => {
        requestForRoleConfigs();
    }, []);
    
    const columns = () => {
        const roleColumns = [
            {
                title: 'TAB',
                dataIndex: 'modulePermissionData',
                key: 'modulePermissionData',
                fixed: 'left',
                editable: false,
                render: text => {
                    return text[0] || ''
                }
            }
        ]; 
        
        roleConfig && roleConfig.length > 0 && roleConfig.map((rolePermission, idx) => {
            const modules = getAllModules();
            roleColumns.push({
                title: <strong>{rolePermission['roleDetails']['name']}</strong>,
                dataIndex: 'modulePermissionData',
                key: 'modulePermissionData',
                editable: isEditable,
                options: moduleAccess,
                modules: modules,
                render: text => {
                    const permissionType = text[idx + 1].accessType;
                    return permissionType || 'No Access'
                }
            });
        });

        return roleColumns;
    };
    
    const getAllModules = () => {
      let moduleList = [];
    
      moduleList = roleConfig && roleConfig.length > 0 && roleConfig[0].permissions.map(permission => {
        return permission.module;
      });
      
      return moduleList;
    };

    const getModulePrettyName = (moduleName) => {
        const mapping = {
            'Queuecontrol': 'Queue Control',
            'CheckedinPatients': 'Arrived Patients',
            'Locations': 'Clinics'
        }

        const result = mapping[moduleName];
        return result? result: moduleName;
    }
    
    const extractAllPermissionPerModule = () => {
        const modules = getAllModules();
      
      const modulePermissionData = modules && modules.length > 0 && modules.map((module, i) => {
          let moduleData = [getModulePrettyName(module)];
      
          roleConfig.forEach(rolePermission => {
            const currentModulePermission = rolePermission.permissions.find(rolePermission => rolePermission.module === module);
            
            moduleData.push({
                uniqueId: currentModulePermission.uniqueId,
                accessType: currentModulePermission.displayName
            });
        });
        
        return {
            key: i,
            modulePermissionData: moduleData
        }
      });
      
      return modulePermissionData;
    };
    
    const dataSource =  extractAllPermissionPerModule();
    const newCols = columns().map((col, colIndex) => {
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                options: col.options,
                dataIndex: col.dataIndex,
                modules: col.modules,
                handleSave: (value) => {
                    record.modulePermissionData[colIndex] = {
                        uniqueId: value.value === "NO_ACCESS" ? undefined : value.value,
                        accessType: value.value === "NO_ACCESS" ? undefined : value.display
                    };
                    if(!finalData) {
                        setFinalData(dataSource);
                    } else {
                        const _finalData = finalData;
                        _finalData[record.key].modulePermissionData[colIndex] = {
                            uniqueId: value.value === "NO_ACCESS" ? undefined : value.value,
                            accessType: value.value === "NO_ACCESS" ? undefined : value.display
                        };
                        setFinalData(_finalData);
                    }
                },
            }),
        };
    });

    const [finalData, setFinalData] = useState(null);
    const onSave = () => {
        if(!finalData) {
            message.error('No changes to save');
            return;
        }
        const modules = getAllModules();
        const request = []

        roleConfig?.map(i => i.roleDetails)
            .forEach((role, roleIndex) => {
                const obj = {roleDetails: role, permissions: []};
                modules?.forEach((module, moduleIndex) => {
                    const permission = finalData?.filter(data => data.modulePermissionData[0] === getModulePrettyName(module))[0]//.modulePermissionData[roleIndex + 1];
                    if(permission) {
                        obj.permissions.push({...permission.modulePermissionData[roleIndex + 1], module: module});
                    }
                });
                request.push(obj);
            })

        bulkUpdateRolePermission(request)
            .then(data => {
                message.success('Save success');
                setFinalData(null);
            }).catch(e => {
                message.error('Failed saving data. Please try again later.');
            });
    }

    useEffect(() => {
        setOnSave(onSave);
    }, [finalData])

    return (
        <>
            <RolePermissionContainer>
                <Table
                    columns={newCols}
                    scroll={{ x: 100 }}
                    dataSource={dataSource}
                    components={{body: {cell: SelectCell}}}
                />
            </RolePermissionContainer>
        </>
    );
};

export default RolePermissions;
