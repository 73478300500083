import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const patientApi = createAxios({
    baseURL: BASE_API_URL + '/patients',
});

export const uploadPatients = (file = null) => {
    let formData = new FormData();

    formData.append('file', file);

    return patientApi.post('/upload', formData, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });
};

export const resolvePatientDetailsChanged = (id) => {
    return patientApi.post(`/${id}/details-changed-resolved`)
}

export const updatePreferences = (payload, id) => {
    return patientApi.post(`/${id}/preference`, payload);
}