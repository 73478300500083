import React, { useEffect, useRef, useState } from "react";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
// import { isCurrentPermissionKeyPermitted } from "utils";
import { DeleteOutlined, EditOutlined } from "@material-ui/icons";
import ActionButtonsDropdown from "components/ActionButtonsDropdown";
import DeleteModal from "components/Modals/DeleteModal";
import CreateQueueModal from "./CreateQueueModal";
import { deleteQueue, getQueueDataTable } from "api/queue";
import {useDispatch, useSelector} from "react-redux";
import { getAllLocations } from "api/locations";
import PermissifiedComponent from "../../components/PermissifiedComponent";
import { setFilterIsLoading } from "redux/actions/filter";
import { Button, Row, Col, Typography } from 'antd';
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';
import {isCurrentPermissionKeyPermitted, permissifyTableColumns} from "utils";

const DEFAULT_FILTERS = {
    facility: undefined,
    department: undefined,
    clinic: undefined,
};

const Queue = () => {
    const [draw, setDraw] = useState(0);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [addOrEditVisible, setAddOrEditVisible] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [queueId, setQueueId] = useState(0);
    const [customFilters, setCustomFilters] = useState(Object.keys(DEFAULT_FILTERS).map((paramName) => ({
        paramName,
        value: DEFAULT_FILTERS[paramName]
    })));
    const [search, setSearch] = useState(1);
    const [dataTableParams, setDataTableParams] = useState(DEFAULT_FILTERS);
    const [facilityValue, setFacilityValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [clinicValue, setClinicValue] = useState(null);
    const [locationCalled, setLocationCalled] = useState(0);
    const [locations, setLocations] = useState([]);
    const initial = useRef(true);
    const permissionState = useSelector(state => state.permissions);

    const dispatch = useDispatch();

    useEffect(() => {
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => ({
            paramName,
            value: dataTableParams[paramName],
        }))
        setCustomFilters(updatedCustomFilters)
    }, [search])

    const setLocationParams = (data) => {
        const locationObj = getLocationValues(data);
        if (locationObj.facility) {
            setFacilityValue(locationObj.facility);
        }
        if (locationObj.department) {
            setDepartmentValue(locationObj.department);
        }
        if (locationObj.clinic) {
            setClinicValue(locationObj.clinic);
        }
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => {
            if (paramName === 'facility' || paramName === 'department' || paramName === 'clinic') {
                return ({
                    paramName,
                    value: locationObj[paramName],
                })
            }
            else {
                return ({
                    paramName,
                    value: dataTableParams[paramName],
                })
            }
        });
        setDataTableParams((params) => ({
            ...params,
            facility: locationObj['facility'],
            department: locationObj['department'],
            clinic: locationObj['clinic']
        }));
        setCustomFilters(updatedCustomFilters);

        setLocationCalled(1);
    }

    useEffect(() => {
        dispatch(setFilterIsLoading(true));
        fetchLocationsData();
    }, []);

    const criteriaNotSearched = () => {
        if(!initial.current) {
            for (let filter of customFilters) {
                if (dataTableParams[filter.paramName] !== filter.value) {
                    return true;
                }
            }
        }
        return false;
    }

    const fetchLocationsData = async () => {
        const res = await getAllLocations();
        let data = [];
        res.data.data.map((f) => {
            data = [
                ...data,
                f
            ]
        })

        setLocations(data);
        setLocationParams(data);
    }

    const columns = [
        {
            title: <strong>Facility</strong>,
            dataIndex: "facility.name",
            permissionKey: "queuecontrol:queue_datatable:columns:facility",
            key: "facility.name",
            render: (value, record, index) => {
                return <>{record.facility?.name}</>;

            },
            sorter: true,
        },
        {
            title: <strong>Department</strong>,
            dataIndex: "department.name",
            key: "department.name",
            permissionKey: "queuecontrol:queue_datatable:columns:department",
            render: (value, record, index) => {
                return <>{record.department?.name}</>;
            },
            sorter: true,
        },
        {
            title: <strong>Clinic</strong>,
            dataIndex: "clinic.name",
            key: "clinic.name",
            permissionKey: "queuecontrol:queue_datatable:columns:clinic",
            render: (value, record, index) => {
                return <>{record.clinic?.name}</>;

            },
            sorter: true,
        },
        {
            title: <strong>Ticket Prefix</strong>,
            dataIndex: "ticketPrefix",
            key: "ticketPrefix",
            permissionKey: "queuecontrol:queue_datatable:columns:queue_prefix",
            render: (value, record, index) => {
                return <>{value}</>;
            },
            sorter: false
        },
        {
            title: <strong>Capacity</strong>,
            dataIndex: "capacity",
            key: "capacity",
            render: (value, record, index) => {
                return <>{value}</>;
            },
            sorter: false
        },
        {
            title: <strong>Options</strong>,
            dataIndex: "id",
            key: "id",
            sorter: false,
            permissionKey: "queuecontrol:queue_datatable:columns:options",
            render: (value, record, index) => {
                const queueActionOptions = [
                    {
                        id: 'updateQueueOption',
                        label: 'Update Queue',
                        icon: <EditOutlined />,
                        permissionKey: 'queuecontrol:queue_datatable:columns:options:update',
                        onClick: () => {
                            setAddOrEditVisible(true);
                            setQueueId(value);
                            setSelectedQueue(record)
                        },
                    },
                    {
                        id: 'deleteQueueOption',
                        label: 'Delete Queue',
                        icon: <DeleteOutlined />,
                        permissionKey: 'queuecontrol:queue_datatable:columns:options:delete',
                        onClick: () => {
                            setDeleteVisible(true);
                            setQueueId(value);
                        },
                    },
                ];

                return <ActionButtonsDropdown
                    menus={queueActionOptions}
                    tabName='Queue'
                />
            },
        }
    ];

    return (
        <>
            <DeleteModal
                entity="Queue"
                visible={deleteVisible}
                setVisibility={setDeleteVisible}
                entityId={queueId}
                deleteFunction={deleteQueue}
                onSuccess={() => setDraw(draw + 1)}
            />
            {locations && locations.length > 0 && <CreateQueueModal
                visible={addOrEditVisible}
                selectedQueue={selectedQueue}
                setVisibility={setAddOrEditVisible}
                onSuccess={() => setDraw(draw + 1)}
                locationData={locations}
            />}
            {/* <PermissifiedComponent permissionKey='queuecontrol:location_filter'> */}
                <Row>
                    <Col lg={20} md={20}>
                        <LocationSelect
                            locationData={locations}
                            facilityValue={facilityValue}
                            departmentValue={departmentValue}
                            clinicValue={clinicValue}
                            showSearchValue={true}
                            id="queueHospitalSelect"
                            width='275px'
                            direction='horizontal'
                            onChange={(value, isDepartment, action, locationObj) => {
                                initial.current = false;
                                if(locationObj === null) {
                                  if (action === 1) {
                                    setDataTableParams((params) => ({
                                      ...params,
                                      facility: value,
                                      department: undefined,
                                      clinic: undefined
                                    }));
                                  }
                                  if (action === 2) {
                                    setDataTableParams((params) => ({
                                      ...params,
                                      department: value,
                                      clinic: undefined
                                    }));
                                  }
                                  if (action === 3) {
                                    setDataTableParams((params) => ({
                                      ...params,
                                      clinic: value,
                                    }));
                                  }
                                }
                                else {
                                  setDataTableParams((params) => ({
                                    ...params,
                                    facility: locationObj['facility'],
                                    department: locationObj['department'],
                                    clinic: locationObj['clinic']
                                  }));
                                }
                              }
                            }
                            isClinicVisible={true}
                            showAll={true}
                        />
                    </Col>
                    <Col
                        lg={4}
                        md={4}
                        style={{marginBlock: 'auto'}}
                    >
                        <Typography.Text>&nbsp;</Typography.Text>
                        <Button
                            id="appointmentsSearch"
                            type={`${criteriaNotSearched() ? 'primary' : '' }`}
                            className={`w-100 ${!criteriaNotSearched() ? 'not-searched': ''}`}
                            onClick={() => setSearch(current => current + 1)}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
                <br />
            {/* </PermissifiedComponent> */}
            <PermissifiedComponent permissionKey="queuecontrol:queue_datatable">
                {locationCalled > 0 ? <CRUDDataTable
                    draw={draw}
                    entity="Queue"
                    fetchDataFunction={async (params) => {
                        return await getQueueDataTable(params);
                    }}
                    createModal={<CreateQueueModal   
                        locationData={locations} 
                        />
                    }
                        isButtonDisabled={!isCurrentPermissionKeyPermitted('queuecontrol:queue_datatable:new', permissionState)}
                        columns={permissifyTableColumns(columns, permissionState)}
                        customFilters={customFilters}
                /> : <></>}
            </PermissifiedComponent>
        </>
    )
};

export default Queue;