import { useEffect, useState } from 'react'
import { Card, Col, Row, Typography } from 'antd'
import { DemoRingProgress } from './DemoRingProgress'
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons'
import { useWindowSize } from 'react-use';
import { DragHandleOutlined } from '@material-ui/icons';
import moment from 'moment';

const { Text } = Typography
export const Color = {
    Green: "#0EAC51",
    WhiteGray: "#efefef",
    White: "#fff",
    Red: "#A70C00",
    Orange: "#C86400",
    Blue: "#0077C0",
    BlueGreen: "#07ABA0",
    Gray: "#636e72",
    Purple: "#8e44ad",
    Black: "#2d3436",
    Yellow: "#FEF445",
}

export const RESPONSIVE_MOBILE_WIDTH = 1000;
const CIRCLE_MOBILE_WIDTH = 65;
const CIRCLE_REGULAR_WIDTH = 80;
const ARROW_MOBILE_WIDTH = "1.2rem";

const mn  = (sec) => {
    if (sec >= 3600) {
        return moment(sec * 1000).utcOffset(0).format('h[ hr ]m[ mins]')
    }
    return moment(sec * 1000).utcOffset(0).format('m[ mins]')
}

const getMinutes = (value) => {
    if(value) {
        let arr = value.split(" ");
        if(arr.length === 4){
            return (parseInt(arr[0])*60 + parseInt(arr[2])); 
        }
        else if(arr.length === 2) {
            return parseInt(arr[0]);
        }
        else {
            return 0;
        }
    }
    return 0;
}
const TodayStatistics = ({ title, color, showAnimation, data, rowStyles = null, averages = null }) => {

    const [totalAppointment, setTotalApppointment] = useState(0);
    const [totalClinicTime, setTotalClinicTime] = useState(0);
    const { width } = useWindowSize();

    useEffect(() => {
        if (data && data?.stats) {
            let total = (data?.stats?.CONFIRMED ?? 0) + (data?.stats?.CHECKED_IN ?? 0) + (data?.stats?.START_CONSULT ?? 0) + (data?.stats?.CHECKED_OUT ?? 0);
            let clinicTime = ( data?.stats?.timeInQueueSec ? getMinutes(mn(parseInt(data?.stats?.timeInQueueSec))) : 0)
                             + (data?.stats?.waitingForConsultSec ? getMinutes(mn(parseInt(data?.stats?.waitingForConsultSec))): 0)
                             + (data?.stats?.timeInConsultSec ? getMinutes(mn(parseInt(data?.stats?.timeInConsultSec))) : 0);
            setTotalApppointment(total);
            setTotalClinicTime(mn(clinicTime*60));
        }
        if (averages) {
            let total = (averages?.CONFIRMED ? parseInt(averages?.CONFIRMED) : 0) + (averages?.CHECKED_IN ? parseInt(averages?.CHECKED_IN) : 0) + (averages?.START_CONSULT ? parseInt(averages?.START_CONSULT) : 0) + (averages?.CHECKED_OUT ? parseInt(averages?.CHECKED_OUT) : 0);
            let clinicTime = (averages.timeInQueueSec ? getMinutes(mn(parseInt(averages.timeInQueueSec))) : 0)
                             + (averages.waitingForConsultSec ? getMinutes(mn(parseInt(averages.waitingForConsultSec))) : 0) 
                             + (averages.timeInConsultSec ? getMinutes(mn(parseInt(averages.timeInConsultSec))) : 0);
            setTotalApppointment(total);
            setTotalClinicTime(mn(clinicTime*60));
        }
    }, [data, averages]);
    return (
        <>
            <Row style={rowStyles}>
                <Col span={24}>
                    <Card
                        bodyStyle={{
                            padding: width < RESPONSIVE_MOBILE_WIDTH ? `24px 0` : 24
                        }}
                        bordered={false}
                        title={
                            <Text
                                level={3}
                                style={{
                                    marginLeft: '20px',
                                    color
                                }}>
                                {title}
                            </Text>
                        }>
                        <Row align='middle' justify="center">
                            <Col span={1}></Col>
                            <Col span={4} className="centered-content">
                                <DemoRingProgress
                                    fontSize={width < RESPONSIVE_MOBILE_WIDTH ? '8px' : '11px'}
                                    lineHeight={width < RESPONSIVE_MOBILE_WIDTH ? '9px' : '14px'}
                                    key={`${width}-booked`}
                                    width={width < RESPONSIVE_MOBILE_WIDTH ? CIRCLE_MOBILE_WIDTH : CIRCLE_REGULAR_WIDTH}
                                    height={110}
                                    animation={showAnimation}
                                    data={{
                                        title: 'Confirmed',
                                        description: data?.stats?.CONFIRMED || parseInt(averages?.CONFIRMED) || '0',
                                        value: (averages?.CONFIRMED ? parseInt(averages?.CONFIRMED) / totalAppointment : (data?.stats.CONFIRMED ? (data?.stats.CONFIRMED / totalAppointment) : 0)),
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={2} className="centered-content">
                                {' '}
                                <ArrowRightOutlined
                                    key={`${width}-arrow-b`}
                                    style={{
                                        fontSize: width < RESPONSIVE_MOBILE_WIDTH ? ARROW_MOBILE_WIDTH : '2.5rem',
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={4} className="centered-content">
                                <DemoRingProgress
                                    fontSize={width < RESPONSIVE_MOBILE_WIDTH ? '8px' : '10px'}
                                    lineHeight={width < RESPONSIVE_MOBILE_WIDTH ? '9px' : '14px'}
                                    key={`${width}-checkedIn`}
                                    width={width < RESPONSIVE_MOBILE_WIDTH ? CIRCLE_MOBILE_WIDTH : CIRCLE_REGULAR_WIDTH}
                                    height={110}
                                    animation={showAnimation}
                                    data={{
                                        title: 'Checked In &  Waiting',
                                        description: data?.stats?.CHECKED_IN || parseInt(averages?.CHECKED_IN) || '0',
                                        value: (averages?.CHECKED_IN ? parseInt(averages?.CHECKED_IN) / totalAppointment : (data?.stats.CHECKED_IN ? (data?.stats.CHECKED_IN / totalAppointment) : 0)),
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={2} className="centered-content">
                                {' '}
                                <ArrowRightOutlined
                                    key={`${width}-arrow-ci`}
                                    style={{
                                        fontSize: width < RESPONSIVE_MOBILE_WIDTH ? ARROW_MOBILE_WIDTH : '3rem',
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={4} className="centered-content">
                                <DemoRingProgress
                                    fontSize={width < RESPONSIVE_MOBILE_WIDTH ? '8px' : '11px'}
                                    lineHeight={width < RESPONSIVE_MOBILE_WIDTH ? '9px' : '14px'}
                                    key={`${width}-vaccinated`}
                                    width={width < RESPONSIVE_MOBILE_WIDTH ? CIRCLE_MOBILE_WIDTH : CIRCLE_REGULAR_WIDTH}
                                    height={110}
                                    animation={showAnimation}
                                    data={{
                                        title: 'In Consult',
                                        description: data?.stats?.START_CONSULT || parseInt(averages?.START_CONSULT) || '0',
                                        value: (averages?.START_CONSULT ? parseInt(averages?.START_CONSULT) / totalAppointment : (data?.stats.START_CONSULT ? (data?.stats.START_CONSULT / totalAppointment) : 0)),
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={2} className="centered-content">
                                {' '}
                                <ArrowRightOutlined
                                    key={`${width}-arrow-co`}
                                    style={{
                                        fontSize: width < RESPONSIVE_MOBILE_WIDTH ? 'ARROW_MOBILE_WIDTH' : '3rem',
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={4} className="centered-content">
                                <DemoRingProgress
                                    fontSize={width < RESPONSIVE_MOBILE_WIDTH ? '9px' : '12px'}
                                    key={`${width}-checkedOut`}
                                    width={width < RESPONSIVE_MOBILE_WIDTH ? CIRCLE_MOBILE_WIDTH : CIRCLE_REGULAR_WIDTH}
                                    height={110}
                                    animation={showAnimation}
                                    data={{
                                        title: 'Checked Out',
                                        description: data?.stats?.CHECKED_OUT || parseInt(averages?.CHECKED_OUT) || '0',
                                        value: (averages?.CHECKED_OUT ? parseInt(averages?.CHECKED_OUT) / totalAppointment : (data?.stats.CHECKED_OUT ? (data?.stats.CHECKED_OUT / totalAppointment) : 0)),
                                        color,
                                    }}
                                />
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                        <Row align='middle' justify="center" style={{
                            marginTop: "25px",
                            color: Color.Gray,
                            borderColor: Color.Gray,
                            borderWidth: "2px !important",
                            fontSize: width < RESPONSIVE_MOBILE_WIDTH ? '9px' : '13px'
                        }}>
                            <Col span={1}></Col>
                            <Col span={4} className="centered-content">
                                <div className="centered-content">
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>Unconfirmed</p>
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>{data?.stats?.UNCONFIRMED || parseInt(averages?.UNCONFIRMED) || '0'}</p>
                                </div>
                            </Col>

                            <Col span={2}></Col>
                            <Col span={4} className="centered-content">
                                <div className="centered-content">
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>To Reschedule</p>
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>{data?.stats?.REQUEST_RESCHEDULE || parseInt(averages?.REQUEST_RESCHEDULE) || '0'}</p>
                                </div>
                            </Col>

                            <Col span={2}></Col>
                            <Col span={4} className="centered-content">
                                <div className="centered-content">
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>No Show</p>
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>{data?.stats?.NO_SHOW || parseInt(averages?.NO_SHOW) || '0'}</p>
                                </div>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={4} className="centered-content">
                                <div className="centered-content">
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>Cancelled</p>
                                    <p style={{ marginBottom: 0, textAlign: "center" }}>{data?.stats?.CANCELLED || parseInt(averages?.CANCELLED) || '0'}</p>
                                </div>
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                         <br/>
                        {(data || averages) && (
                            <>
                                <Row
                                    align='left' justify="left"
                                ><Text
                                    level={3}
                                    style={{
                                        marginLeft: '20px',
                                        color,
                                        textAlign: "center"
                                    }}>
                                        Total Time In clinic (average)
                                    </Text>
                                </Row>
                                <Row gutter={8} align='middle' justify="center" style={{ marginTop: "24px", fontSize: width < RESPONSIVE_MOBILE_WIDTH ? '9px' : '13px' }}>
                                    <Col span={4}>
                                        <div style={{ marginLeft: "8px", fontWeight: "bold", padding: "8px", textAlign: "center" }}>
                                            {data?.stats?.timeInQueueSec ? mn(data?.stats?.timeInQueueSec ) : parseInt(averages?.timeInQueueSec) ?  mn(parseInt(averages?.timeInQueueSec)) :  '0 mins'}
                                        </div>
                                        <div style={{ flexGrow: 1, textAlign: "center" }}>Time in Queue</div>
                                    </Col>
                                    <Col span={2}>
                                        <PlusOutlined
                                            style={{
                                                fontSize: width < RESPONSIVE_MOBILE_WIDTH ? ARROW_MOBILE_WIDTH : '3rem',
                                            }} />
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ marginLeft: "8px", fontWeight: "bold", padding: "8px", textAlign: "center" }}>
                                            {data?.stats?.waitingForConsultSec ? mn(data?.stats?.waitingForConsultSec) :  parseInt(averages?.waitingForConsultSec) ? mn(parseInt(averages?.waitingForConsultSec)) : '0 mins'}
                                        </div>
                                        <div style={{ flexGrow: 1, textAlign: "center" }}>Waiting for consult</div>
                                    </Col>
                                    <Col span={2}>
                                        <PlusOutlined
                                            style={{
                                                fontSize: width < RESPONSIVE_MOBILE_WIDTH ? ARROW_MOBILE_WIDTH : '3rem',

                                            }} />
                                    </Col>
                                    <Col span={4}>
                                        <div style={{ marginLeft: "8px", fontWeight: "bold", padding: "8px", textAlign: "center" }}>
                                            {data?.stats?.timeInConsultSec ? mn(data?.stats?.timeInConsultSec) :  parseInt(averages?.timeInConsultSec) ? mn(parseInt(averages?.timeInConsultSec)) : '0 mins'}
                                        </div>
                                        <div style={{ flexGrow: 1, textAlign: "center" }}>Time in consult</div>
                                    </Col>
                                    <Col span={2}>
                                        <DragHandleOutlined
                                            style={{
                                                fontSize: width < RESPONSIVE_MOBILE_WIDTH ? ARROW_MOBILE_WIDTH : '3rem',

                                            }} />

                                    </Col>
                                    <Col span={4}>
                                        <div style={{ marginLeft: "8px", fontWeight: "bold", padding: "8px" }}>{totalClinicTime}</div>
                                        <div style={{ flexGrow: 1 }}>Total time in clinic</div>
                                    </Col>

                                </Row>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default TodayStatistics
