import {BASE_API_URL} from 'api/config';
import {createAxios} from "./axios";

const patientTasksApi = createAxios({
  baseURL: BASE_API_URL + '/patient-tasks'
});

export const getPatientTasks = params => {
  return patientTasksApi.get(``,{
    params: params
  });
}

export const createPatientTask = payload => {
  return patientTasksApi.post(``, payload);
}

export const updatePatientTaskStatus = (id, status) => {
  return patientTasksApi.patch(`/${id}/status?status=${status}`)
}

export const updatePatientTaskDetails = (id, payload) => {
    return patientTasksApi.put(
        `/${id}`,
        {
            description: payload.description,
            notifyStaffWhenCompleted: payload.notifyStaffWhenCompleted
        }
    );
}

export const updatePatientTask = (id, description) => {
  return patientTasksApi.patch(`/${id}/description?description=${description}`);
}

export const deletePatientTask = id => {
  return patientTasksApi.delete(`/${id}`);
}