import React, { useEffect, useState, useRef } from "react";
import { Typography, Modal, Row, Col, Spin, Checkbox, Button, message } from "antd";
import { Formik } from 'formik';
import { Form, Select, ResetButton, SubmitButton, Field } from 'formik-antd';

// import {createPatientTask} from 'api/patientTasks'
import { getUserDetailBaseOnId } from 'api/appointments';
import { CloseOutlined } from '@ant-design/icons';
import { getAllSettings } from "api/systemSettings";
import { updatePreferences } from "api/patients";
import {isCurrentPermissionKeyPermitted} from "../../../../utils";
import {useSelector} from "react-redux";

const { Title } = Typography;


const ViewUserDetailsModal = ({ visible, setVisibility, appointmentId, permissionState }) => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reasonsOption, setReasonsOption] = useState([]);
    const [preferredLanguageOption, setPreferredLanguageOption] = useState([]);
    const dataFetched = useRef(false);
    useEffect(() => {
        if (visible && !dataFetched.current) {
            getUserDetailBaseOnId(appointmentId).then((response) => {
                setUserData(response?.data);
                dataFetched.current = true;
                setLoading(false);
            }).catch((err) => {
                setVisibility(false);
                setLoading(false);
            });
            getTenantSettings()
        }
    }, [visible])

    const getTenantSettings = async () => {
        const response = await getAllSettings('TENANT');
        const lan = response.data.TENANT.find(tenant => tenant.name === 'PATIENT_PREFERENCES_INTERPRETER_LANGUAGES')?.value ?? "";
        const res = response.data.TENANT.find(tenant => tenant.name === 'PATIENT_PREFERENCES_OPT_OUT_REASONS')?.value ?? "";
        setPreferredLanguageOption(lan.split(','));
        setReasonsOption(res.split(','));
    }

    return (
        <Modal
            title={<Title level={4} className="mb-0"
                style={{ display: "flex", alignItems: "center" }} id={'PatientDetailsModalTitle'}>Patient Details</Title>}
            visible={visible}
            onCancel={() => { setVisibility(false); }}
            cancelButtonProps={{ id: 'cancelButton' }}
            closeIcon={(<CloseOutlined id='closePatientDetails' />)}
            footer={null}
            centered
            width={'50%'}
            bodyStyle={{ height: '80vh', overflowY: 'auto' }}
        >
            {userData && <>
                <Spin spinning={loading}>
                    <Row>
                        <Col span={12}>
                            <label>First Name : </label> {userData?.firstName ?? '-'}
                        </Col>
                        <Col span={12}>
                            <label>Last Name :</label> {userData?.lastName ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <label>Gender : </label> {userData?.sex ?? '-'}
                        </Col>
                        <Col span={12}>
                            <label>Date Of Birth : </label> {userData?.dateOfBirth ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <label>Mobile Number : </label> {userData?.contactNumber ?? '-'}
                        </Col>
                        <Col span={12}>
                            <label>Email : </label> {userData?.email ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>MRN : </label>{userData?.mrn ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Medicare Number : </label>{userData?.medicareCardNumber ?? '-'}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <label>Medicare Expiry  : </label>{userData?.medicareExpDate ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Emergency Contact Name : </label>{userData?.emergencyContactName ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Emergency Contact Number : </label>{userData?.emergencyContactNumber ?? '-'}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <label>GP Name : </label> {userData?.gpName ?? '-'}
                        </Col>
                        <Col span={12}>
                            <label>GP Address :</label> {userData?.gpAddress ?? '-'}
                        </Col>
                    </Row>
                    <hr />
                    {userData && <Formik
                        enableReinitialize
                        initialValues={{
                            mobilityAssistance: userData?.patientPreference?.mobilityAssistance ?? false,
                            carerGuardian: userData?.patientPreference?.carerGuardian ?? false,
                            requireInterpreter: userData?.patientPreference?.requireInterpreter ?? false,
                            preferredLanguage: userData?.patientPreference?.preferredLanguage ?? '',
                            nswHealthStaff: userData?.patientPreference?.nswHealthStaff ?? false,
                            doNotWantToParticipate: userData?.patientPreference?.doNotWantToParticipate ?? false,
                            reason: userData?.patientPreference?.reason ?? '',
                        }}
                        onSubmit={async (values, { }) => {
                            setLoading(true);
                            const preferences = {
                                mobilityAssistance: values.mobilityAssistance,
                                carerGuardian: values.carerGuardian,
                                requireInterpreter: values.requireInterpreter,
                                preferredLanguage: values.preferredLanguage,
                                nswHealthStaff: values.nswHealthStaff,
                                doNotWantToParticipate: values.doNotWantToParticipate,
                                reason: values.reason
                            }

                            await updatePreferences(preferences, userData?.id).then((resp) => {
                                setLoading(false);
                                setUserData(resp?.data);
                                message.success('Preferences updated successfully');
                                setVisibility(false);
                            }).catch((err) => {
                                message.error('Failed to save preferences');
                            })
                        }

                        }
                    >{({
                        values,
                        setFieldValue
                    }) => (
                        <Form id="preferences" className="form-horizontal">
                            <Row>
                                <Col span={24}>
                                    <h2 className="mb-0"
                                        style={{ display: "flex", alignItems: "center" }} >Preferences
                                    </h2>
                                </Col>
                                <Col span={24} className="mb-3">
                                    <span>Please let us know how we can assist you better </span>
                                </Col>
                            </Row>
                            <div className="ml-5">
                                <Row>
                                    <Col span={24} className="form-group">
                                        <Field name="mobilityAssistance" type="checkbox" disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} />
                                        <label className="ml-3" htmlFor="mobilityAssistance">I require mobility assistance</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="form-group">
                                        <Field name="carerGuardian" type="checkbox" disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} />
                                        <label className="ml-3" htmlFor="carerGuardian">I am a carer / guardian for this patient</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="form-group" >
                                        <Field name="requireInterpreter" type="checkbox"  disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} onClick={(e) => {
                                            if (e.target.value) {
                                                setFieldValue('preferredLanguage', '')
                                            }
                                        }} />
                                        <label className="ml-3" htmlFor="requireInterpreter">I require an interpreter </label>
                                    </Col>
                                </Row>
                                {
                                    values?.requireInterpreter && <Row>
                                        <Col span={24} className="form-group ml-3" >
                                            <Form.Item
                                                label='Preferred language'
                                                name='preferredLanguage'
                                                className="mb-0 "
                                            >
                                                <Select name='preferredLanguage' disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} className="mb-1" style={{ width: 150, fontSize: 'inherit' }} getPopupContainer={trigger => trigger.parentNode}>
                                                    {preferredLanguageOption && preferredLanguageOption.length > 0 && preferredLanguageOption.map(
                                                        (option, idx) => {
                                                            return (
                                                                <Select.Option
                                                                    key={idx}
                                                                    value={option}
                                                                >
                                                                    {option}
                                                                </Select.Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col span={24} className="form-group">
                                        <Field name="nswHealthStaff" type="checkbox" disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} />
                                        <label className="ml-3" htmlFor="nswHealthStaff">I am NSW Health staff </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24} className="form-group">
                                        <Field name="doNotWantToParticipate" type="checkbox" disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} onClick={(e) => {
                                            if (e.target.value) {
                                                setFieldValue('reason', '')
                                            }
                                        }} />
                                        <label className="ml-3" htmlFor="doNotWantToParticipate">I do not want to participate in this online service</label>
                                    </Col>
                                </Row>
                                {
                                    values?.doNotWantToParticipate && <Row>
                                        <Col span={24} className="form-group ml-3">
                                            <Form.Item
                                                label='Reason'
                                                name='reason'
                                                className="mb-0"
                                            >
                                                <Select name='reason' style={{ width: 200 }} disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)} getPopupContainer={trigger => trigger.parentNode}>
                                                    {reasonsOption && reasonsOption.length > 0 && reasonsOption.map(
                                                        (option, idx) => {
                                                            return (
                                                                <Select.Option
                                                                    key={idx}
                                                                    value={option}
                                                                >
                                                                    {option.trim()}
                                                                </Select.Option>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                                <Row gutter={4} style={{ display: "block" }}  >
                                    <Col>
                                        <Button htmlType="submit" type="primary" block disabled={!isCurrentPermissionKeyPermitted('appointments:details:preferences:edit', permissionState)}>
                                            Update Preferences
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    )}
                    </Formik>
                    }
                </Spin>
            </>}
        </Modal>
    );
};

export default ViewUserDetailsModal;
