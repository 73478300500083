import { message, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const DeleteRoomsModal = ({ setVisibility, visible, roomName, data, setData, roomId }) => {

    const deleteRoom = () => {
        try{
            let roomData = data?.data?.filter((val, _index) => {
                if (val.id !== roomId) {
                    return val
                }
            });
            let deleteRoom = {
                "data": roomData
            }
            setData(deleteRoom);
            setVisibility(false);
            message.success('Room deleted successfully');
        }
        catch (error) {
            message.error('Unable to delete the room');
        }
    }

    return <Modal
        visible={visible}
        onOk={deleteRoom}
        okButtonProps={{id:'DeleteRoomModalOkButton'}}
        onCancel={() => setVisibility(false)}
        cancelButtonProps={{id:'DeleteRoomModalCancelButton'}}
        okText={"Ok"}
        cancelText={"Cancel"}
        closeIcon={(<CloseOutlined id='closeDeleteRoomModal' />)}
    >
        Are you want to delete {roomName}?
    </Modal>
}

export default DeleteRoomsModal;