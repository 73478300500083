import { useEffect, useRef, useState } from 'react';
import { Button, Col, message, Modal, Row, Typography, Tabs, Switch } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import { HomeOutlined, DatabaseOutlined, DeleteOutlined } from '@ant-design/icons';
import { createLocation, updateLocation } from 'api/locations';
import CreateMetadataModal from './Metadata/CreateMetadataModal';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import DeleteMetadataModal from './Metadata/DeleteMetadataModal';
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { TabPane } = Tabs;

// TODO ready but not yet implemented. please see TODO note below
const CreateLocationSchema = yup.object().shape({
  name: yup.string().required('Please enter a name.'),
  uniqueId: yup.string().required('Please enter public form ID.'),
  externalLocationId: yup.string().required('Please enter CCL Location ID.')
});

const LocationForm = ({
  visible,
  setVisibility,
  onSuccess,
  locationId,
  selectedLocation,
  setSelectedLocation,
  moduleName,
  parentId,
}) => {

  const formikRef = useRef(null);
  const title = selectedLocation !== undefined ? 'Update' : 'Create';
  const submitText = selectedLocation !== undefined ? 'Save' : 'Create';
  const [tabkey, setTabKey] = useState(0);
  const [draw, setDraw] = useState(0);
  const [metadata, setMetadata] = useState([]);
  const [isCreateModalVisible, setCreateModalVisibility] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
  const [selectedMetadata, setSelectedMetadata] = useState(undefined);
  const changeTab = (key) => {
    setTabKey(key);
  };


  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.key.localeCompare(b.key),
      render: (text, record, value) => {
        return <>
          {record?.key}
        </>;
      }
    },
    {
      title: <strong>Value</strong>,
      dataIndex: 'value',
      key: 'value',
      sorter: (a, b) => a.value.localeCompare(b.value),
      render: (text, record, value) => {
        return <>
          {record?.value}
        </>;
      }
    },
    {
      title: <strong>Options</strong>,
      dataIndex: 'id',
      key: 'id',
      sorter: false,
      render: (text, record, value) => {
        const attachmentActions = [
          {
            permissionKey: true,
            label: 'Delete',
            icon: <DeleteOutlined />,
            onClick: () => {
              setSelectedMetadata(record);
              setDeleteModalVisibility(true);
            }
          }
        ];


        return  <ActionButtonsDropdown
          menus={attachmentActions}
          tabName={`${moduleName}MetaData`}
        />
      },
    },
  ];

  const [tabs, setTabs] = useState([
    {
      key: 0,
      name: `${moduleName}`,
    },
    {
      key: 1,
      name: 'Metadata',
    },
  ]);
  const [location, setLocation] = useState({});
  useEffect(() => {
    if (selectedLocation !== undefined) {
      // this allows pass by value and not reference
      // sorry had to resort to temp solution, quite new to js
      setLocation(JSON.parse(JSON.stringify(selectedLocation ?? null)));
      selectedLocation?.metadata ? setMetadata(selectedLocation.metadata) : setMetadata([]);
    } else if (selectedLocation === undefined || Object.keys(selectedLocation).length < 1) {
      setLocation({});
      setMetadata([])
      if (formikRef?.current?.resetForm) {
        formikRef.current.resetForm();
      }
    }
    setTabs([{
      key: 0,
      name: `${moduleName}`,
    },
    {
      key: 1,
      name: 'Metadata',
    }])
  }, [selectedLocation ,visible ]);

  async function create(values) {
    try {
      message.loading(`Uploading ${moduleName} ...`, 0);
      location.name = values.name ?? location.name;
      location.contactNumber = values.contactNumber ?? location.contactNumber;
      location.cancelContactNumber = values.cancelContactNumber ?? location.cancelContactNumber;
      location.uniqueId = values.uniqueId ?? location.uniqueId;
      location.externalLocationId = values.externalLocationId ?? location.externalLocationId;
      location.metadata = metadata;
      values.metadata = metadata;
      values.callPatientSmsEnabled = location.callPatientSmsEnabled;

      /**
       * TODO take note that this post creation validation was used
       * instead of formik validation. This is because formik was
       * causing a bug where in preloading values will not run through
       * the validation schema and will be read as undefined
       */
      if (
        !location.name ||
        !location.uniqueId ||
        !location.externalLocationId
      ) {
        message.destroy();
        message.error('Please fill up all details');
        return;
      }
      values.parentId = parentId ?? '';

      if (selectedLocation !== undefined) {
        await updateLocation(locationId, location);
        message.destroy();
        onSuccess();
        clearValues();
        message.success(`${moduleName} successfully updated`);
      }
      else {
        await createLocation(values, moduleName);
        message.destroy();
        onSuccess();
        clearValues();
        message.success(`${moduleName} successfully added`);
      }
      setVisibility(false);
    } catch (error) {
      message.destroy();
      message.error(error.response.data.message);
    }
  }

  const clearValues = () => {
    if (location !== undefined) {
      location.name = '';
      location.contactNumber = '';
      location.cancelContactNumber = '';
      location.uniqueId = '';
      location.externalLocationId = '';
    }
  };
  
  return (
    <Modal
      title={
        <Title
          level={4}
          className='mb-0'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeOutlined className='mr-2' />{selectedLocation !== undefined ? "Edit" : "Create"} {moduleName}
        </Title>
      }
      visible={visible}
      onCancel={() => {
        setVisibility(false);
        clearValues();
      }}
      closeIcon={(<CloseOutlined id='closeLocationFormModal' />)}
      footer={null}
      destroyOnClose
    >
      <DeleteMetadataModal
        selectedMetadata={selectedMetadata}
        data={metadata}
        setData={setMetadata}
        visible={isDeleteModalVisible}
        setVisibility={setDeleteModalVisibility}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: location?.name,
          uniqueId: location?.uniqueId,
          externalLocationId: location?.externalLocationId
        }}
        validationSchema={CreateLocationSchema}
        onSubmit={(values) => create(values)}
      >
        {({ setFieldValue }) => (
          <Tabs onChange={changeTab} id={moduleName ?? 'Location'}>
            {tabs &&
              tabs.map((tab) => (
                <TabPane tab={tab.name} key={tab.key}>
                  <Form layout='vertical' colon={false}>
                    {tab.key === 0 && <>
                      <Form.Item label='Name' name='name' className='mb-4' required>
                        <Input
                        id='locationFormNameInput'
                          name='name'
                          value={location?.name}
                          onChange={(value) => {
                            location.name = value.target.value;
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='Contact Number' name='contactNumber' className='mb-4'>
                        <Input
                         id='locationFormContactNumberInput'
                          name='contactNumber'
                          value={location?.contactNumber}
                          onChange={(value) => {
                            location.contactNumber = value.target.value;
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='Cancellation Contact Number' name='cancelContactNumber' className='mb-4'>
                        <Input
                         id='locationFormCancelNumberInput'
                          name='cancelContactNumber'
                          value={location?.cancelContactNumber}
                          onChange={(value) => {
                            location.cancelContactNumber = value.target.value;
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='Active Directory ID' name='uniqueId' className='mb-4' required>
                        <Input
                         id='locationFormUniqueIdInput'
                          name='uniqueId'
                          value={location?.uniqueId}
                          onChange={(value) => {
                            location.uniqueId = value.target.value;
                          }}
                        />
                      </Form.Item>
                      <Form.Item label='CCL Location Id' name='externalLocationId' className='mb-4' required>
                        <Input
                         id='locationFormLocationIdInput'
                          name='externalLocationId'
                          value={location?.externalLocationId}
                          onChange={(value) => {
                            location.externalLocationId = value.target.value;
                          }}
                        />
                      </Form.Item>
                    {moduleName === 'Clinic' &&
                    <Form.Item 
                        label='Call Patient via SMS' 
                        name='callPatientSmsEnabled' 
                        className='mb-4'
                    >
                        <Switch
                            name='callPatientSmsEnabled'
                            defaultChecked={location?.callPatientSmsEnabled}
                            onChange={(value) => {
                                location.callPatientSmsEnabled = value;
                            }}
                        />
                    </Form.Item>}
                    </>}
                    {tab.key === 1 && <>

                      <CreateMetadataModal
                        visible={isCreateModalVisible}
                        setVisibility={setCreateModalVisibility}
                        icon={<DatabaseOutlined className='mr-2' />}
                        onSuccess={() => { }}
                        onCancel={() => { }}
                      />
                      <CRUDDataTable
                        data={{ data: metadata }}
                        draw={draw}
                        search={false}
                        fetchDataFunction={async (params) => {
                          let newData;
                          if (params?.search) {
                              newData = { "data": metadata.filter(p => p.key.toLowerCase().includes(params.search.toLowerCase())) }
                          }
                          else {
                              newData = { data: metadata };
                          }
                          return { "data": newData }
                      }}
                        title=""
                        entity=''
                        highlightRow={(row, i) => row.highlight}
                        createModal={
                          <CreateMetadataModal
                            visible={isCreateModalVisible}
                            setVisibility={setCreateModalVisibility}
                            metadata={metadata}
                            icon={<DatabaseOutlined className='mr-2' />}
                            onSuccessAdd={(entity) => {setMetadata([...metadata, entity])}}
                            onCancel={() => { }}
                          />
                        }
                        columns={columns}
                      /></>
                    }

                    <Row gutter={4} className='d-flex justify-content-end'>
                      <Col>
                        <Button
                         id='locationFormCancelButton'
                          type='dashed'
                          shape='round'
                          onClick={() => { clearValues(); setVisibility(false) }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <SubmitButton  id='locationFormSubmitButton'>{submitText}</SubmitButton>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              ))}
          </Tabs>
        )}

      </Formik>
    </Modal>
  );
};

export default LocationForm;
