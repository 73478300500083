import axios from "axios";
import {REQUEST_TIMEOUT} from "api/config";

export default class BaseService {

    url = null;
    constructor(url) {
        this.url = url;
    }
    addItem = (payload) => {
        return axios.post(this.url, payload, {
            timeout: REQUEST_TIMEOUT
        })

    }

    deleteItem = (id) => {
        return axios.delete(this.url, {
            timeout: REQUEST_TIMEOUT,
            params: {
                id: id
            }
        })

    }

}