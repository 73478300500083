import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Row, Col } from 'react-bootstrap';
import CardView from 'components/Layout/CardView';
import { FcElectricalSensor } from 'react-icons/fc';
import { Button } from 'antd';
import GitInfo from "react-git-info/macro";

import Result from './APITesting/Result';

// API Endpoints
import { getAll } from 'api/actionTemplates';
import { getAppointmentsDatatable } from 'api/appointments';
import { getBroadcastDataTable } from 'api/broadcasts';
import { fetchCollaterals } from 'api/collaterals';
import { getDashboardSummary } from 'api/dashboard';
import { getForms } from 'api/forms';
import { fetchAllFormSelection } from 'api/formSelection';
import { fetchAllAvailableCodes } from 'api/formSelectionAptCode';
import { getAllLocations } from 'api/locations';
import { getNotesDatatable } from 'api/notes';
import { getPatientTasks } from 'api/patientTasks';
import { getAllProperties } from 'api/settings';
import { getStaffTasks } from 'api/staffTasks';
import { getAllSettings } from 'api/systemSettings';
import { getTaskDatatable } from 'api/tasks';
import { getVisitors } from 'api/visits';
import {useSelector} from "react-redux";
import {isCurrentPermissionKeyPermitted} from "../../../utils";

const EndpointTest = () => {
    const [endpoints, setEndpoints] = useState([]);
    const [buildVersion, setBuildVersion] = useState(null);
    const permissionState = useSelector(state => state.permissions);

    useEffect(() => {
        const gitInfo = GitInfo();
        setBuildVersion(`${gitInfo.commit.shortHash}`);
    }, []);

    const applicationEndpoints = [
        {
            key: 1,
            api: () => getAll(),
            apiString: 'getAll()',
            endpoint: 'Get all Action Templates',
            status: 0,
        },
        {
            key: 2,
            api: () => getAppointmentsDatatable(),
            apiString: 'getAppointmentsDatatable()',
            endpoint: 'Get all Appointments',
            status: 0,
        },
        {
            key: 3,
            api: () => getBroadcastDataTable(),
            apiString: 'getBroadcastDataTable()',
            endpoint: 'Get all Broadcasts',
            status: 0,
        },
        {
            key: 4,
            api: () => fetchCollaterals(),
            apiString: 'fetchCollaterals()',
            endpoint: 'Get all Resources',
            status: 0
        },
        {
            key: 5,
            api: () => getDashboardSummary(),
            apiString: 'getDashboardSummary()',
            endpoint: 'Get all Dashboard Summary',
            status: 0
        },
        {
            key: 6,
            api: () => getForms(),
            apiString: 'getForms()',
            endpoint: 'Get all Forms',
            status: 0
        },
        {
            key: 7,
            api: () => fetchAllFormSelection(),
            apiString: 'fetchAllFormSelection()',
            endpoint: 'Get all Form Selection',
            status: 0
        },
        {
            key: 8,
            api: () => fetchAllAvailableCodes(),
            apiString: 'fetchAllAvailableCodes()',
            endpoint: 'Get all Available Appointment Codes',
            status: 0
        },
        {
            key: 9,
            api: () => getAllLocations(),
            apiString: 'getAllLocations()',
            endpoint: 'Get all Locations',
            status: 0
        },
        {
            key: 10,
            api: () => getNotesDatatable(),
            apiString: 'getNotesDatatable()',
            endpoint: 'Get all Available Appointment Codes',
            status: 0
        },
        {
            key: 11,
            api: () => getPatientTasks(),
            apiString: 'getPatientTasks()',
            endpoint: 'Get all Patients Tasks',
            status: 0
        },
        {
            key: 12,
            api: () => getAllProperties(),
            apiString: 'getAllProperties()',
            endpoint: 'Get all System Properties',
            status: 0
        },
        {
            key: 13,
            api: () => getStaffTasks(),
            apiString: 'getStaffTasks()',
            endpoint: 'Get all Staff Tasks',
            status: 0
        },
        {
            key: 14,
            api: () => getAllSettings(),
            apiString: 'getAllSettings()',
            endpoint: 'Get all Catgorised Settings',
            status: 0
        },
        {
            key: 15,
            api: () => getTaskDatatable(),
            apiString: 'getTaskDatatable()',
            endpoint: 'Get Task Datatable',
            status: 0
        },
        {
            key: 16,
            api: () => getVisitors(),
            apiString: 'getVisitors()',
            endpoint: 'Get all Visits',
            status: 0
        },
    ];

    return (
        <CardView className="h-100 pb-5">
            <Row>
                <Col>
                    <div className='text-right'>
                        <small>Build version: <strong>{buildVersion}</strong></small>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Space
                        direction={'vertical'}
                        size={'large'}
                        style={{
                            width: '100%',
                            textAlign: 'center',
                        }}
                    >
                        <FcElectricalSensor
                            style={{
                                fontSize: '10rem',
                                opacity: '.8',
                            }}
                        />
                        <h4 className="text-gray" style={{ opacity: '.8' }}>
                            Click the button below to start the API Endpoints' Testing.
                        </h4>
                        <Button
                            type="primary"
                            disabled={!isCurrentPermissionKeyPermitted('settings:infotests:test_api', permissionState)}
                            onClick={() => {
                                let endpointsData = [...applicationEndpoints];

                                applicationEndpoints.forEach(async applicationEndpoint => {
                                    const foundEndpointIndex = endpointsData.findIndex(endpointData => endpointData.key === applicationEndpoint.key);

                                    try {
                                        const response = await applicationEndpoint.api();

                                        endpointsData[foundEndpointIndex].status = response.status;
                                    } catch (err) {
                                        if (err.response?.data) {
                                            endpointsData[foundEndpointIndex].status = err.response.data.status;
                                        } else {
                                            endpointsData[foundEndpointIndex].status = 500;
                                        }
                                    }

                                    setEndpoints([...endpointsData]);
                                });
                            }}
                        >
                            Test API Endpoints
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Result endpoints={endpoints} />
                </Col>
            </Row>
        </CardView>
    );
};

export default EndpointTest;
