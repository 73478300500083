import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Modal,
  Typography,
  Row,
  Col,
  message,
  Upload,
  Tabs,
  Input,
  Button
} from 'antd';
import { GrDocumentCsv } from 'react-icons/all';
import { AudioOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Formik } from 'formik';
import { Form, Select, ResetButton, SubmitButton } from 'formik-antd';
import { getMessageTemplates } from 'api/messageTemplates';
import * as Yup from 'yup';
import { createBroadcast } from 'api/broadcasts';

const { Title } = Typography;
const { Dragger } = Upload;

interface CreateBroadcastModal {
  visible?: boolean;
  setVisibility?: (visibility: boolean) => void;
  setDraw?: (draw: any) => void;
  draw?: any;
}

const CreateTemplateBroadcastModal: FunctionComponent<CreateBroadcastModal> = ({
  visible,
  setVisibility,
  setDraw,
  draw,
}) => {
  const [messageTemplates, setMessageTemplates] = useState([]);

  const [tabs] = useState([
    {
      key: 0,
      id:"BroadCastModalEmailTab",
      name: 'Email',
    },
    {
      key: 1,
      id:"BroadCastModalSMSTab",
      name: 'SMS',
    },
    {
      key: 2,
      id:"BroadCastModalCSVTab",
      name: 'CSV',
    },
  ]);
  const [contacts, setContacts] = useState([{ name: '', contact: '' }]);

  const [tabkey, setTabKey] = useState(0);

  const { TabPane } = Tabs;
  const changeTab = (key) => {
    setTabKey(key);
    setContacts([{ name: '', contact: '' }]);
  };
  const yupSchemas = [
    {
      messageTemplateId: Yup.string()
        .required('Please select message template'),
    },
    {
      messageTemplateId: Yup.string()
        .required('Please select message template'),
    },
    {
      messageTemplateId: Yup.string()
        .required('Please select message template'),
      file: Yup.mixed()
        .required('Please choose CSV file'),
    }
  ]
  const BroadcastSchema = Yup.object().shape(yupSchemas[tabkey]);
  const formikRef = useRef(null);
  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])

  function reset() {
    setContacts([{ name: '', contact: '' }]);
  }


  const fetchMessageTemplates = async () => {
    try {
      const response = await getMessageTemplates();
      setMessageTemplates(response.data);


    } catch (err) {
      message.error("Couldn't fetch message templates, please try again later", 3);
    }
  }

  useEffect(() => {
    fetchMessageTemplates();
  }, []);


  return (
    <Modal
      title={
        <Title
          level={4}
          className='mb-0'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <AudioOutlined className='mr-2' /> New Broadcast
        </Title>
      }
      visible={visible}
      destroyOnClose
      onCancel={() => {
        if (setVisibility) {
          setVisibility(false);
        }
      }}
      closeIcon={(<CloseOutlined id='closeMessageTemplateBroadcastModal' />)}
      footer={null}
    >
      <Formik
        initialValues={{ messageTemplateId: '', file: null }}
        validationSchema={BroadcastSchema}
        onSubmit={async (broadcast) => {
          if(tabkey < 2) {
            let filteredContacts = contacts.filter((obj: any) => (obj.contact !== '' && obj.name !== ''));
            if(filteredContacts.length === 0) {
              message.error('Please add contact details.');
              return;
            }
          }
          const queryParams = ['/email', '/sms', '/sms/bulk'];
          try {
            await createBroadcast(broadcast, queryParams[tabkey], tabkey < 2 ? contacts : []);
            if (setVisibility) {
              setVisibility(false);
            }

            if (setDraw) {
              setDraw(draw + 1);
            }

            changeTab(0);

            message.success('Message Template is now sending...');
          } catch (err) {
            if (setVisibility) {
              setVisibility(false);
            }

            if (setDraw) {
              setDraw(draw + 1);
            }
            changeTab(0);
            message.info('Message Template sending failed.');
          }
        }}
      >
        {({ setFieldValue }): any => (
          <Tabs onChange={changeTab}>
            {tabs &&
              tabs.map((tab) => (
                <TabPane id={tab?.id} tab={tab.name} key={tab.key}>
                  <Form layout='vertical' colon={false}>
                    <Form.Item
                      label='Message Template'
                      name='messageTemplateId'
                    >
                      <Select 
                      showSearch
                      filterOption={(input: any, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      id='messageTemplateIdOptions' name='messageTemplateId'>
                        {messageTemplates &&
                          messageTemplates.length > 0 &&
                          messageTemplates.map(
                            (messageTemplate: any, idx: any) => {
                              return (
                                <Select.Option
                                  key={idx}
                                  value={messageTemplate.id}
                                >
                                  {messageTemplate.templateName}
                                </Select.Option>
                              );
                            }
                          )}
                      </Select>
                    </Form.Item>
                    {tab.key === 0 && <label>Emails to invite</label>}
                    {tab.key === 1 && <label>Mobile Numbers to invite</label>}
                    {tab.key !== 2 &&

                      contacts.map((contact, idx) => (
                        <>
                          <Row style={{ width: '100%', padding: '10px' }}>
                            <Col span={10}>
                              <Input
                                id="CreateBroadInput"
                                value={contact.name}
                                name='name'
                                placeholder='John Smith'
                                style={{ width: '90%' }}
                                onChange={(el) => {
                                  const val = el.target.value;
                                  const vals = [...contacts];
                                  vals[idx].name = val;
                                  setContacts(vals);
                                }}
                              />
                            </Col>
                            <Col span={10}>
                              <Input
                                id="CreateBroadInputValue"
                                value={contact.contact}
                                name='cont`act'
                                placeholder={tab.key === 0 ? 'e.g john.smith@example...' : 'e.g 04 XXXX XXXX'}
                                style={{ width: '90%' }}
                                onChange={(el) => {
                                  const val = el.target.value;
                                  const vals = [...contacts];
                                  vals[idx].contact = val;
                                  setContacts(vals);
                                }}
                              />
                            </Col>
                            <Col span={4}>
                              <Button
                                id="CreateBroadCastPlusButton"
                                type='primary'
                                shape="circle"
                                onClick={() => {
                                  const vals = [...contacts];
                                  if (vals.find(item => !item.name || !item.contact)) {
                                    message.error("Please fill all the fields!", 3);
                                  } else {
                                    vals.push({ name: '', contact: '' })
                                    setContacts(vals);
                                  }

                                }}
                                icon={<PlusOutlined />}
                              />
                            </Col>
                          </Row>
                        </>
                      ))}
                    {tab.key === 2 && (
                      <>
                        <span style={{ fontSize: '13px' }}>Please select a CSV file - <b>Format: phoneNumber, email</b></span>
                        <Form.Item name='file' label='Upload CSV'>
                          <Dragger
                            className='Message-Broadcast-Upload'
                            name='file'
                            maxCount={1}
                            listType='picture'
                            accept={'.xlsx, .xls, .csv'}
                            onChange={(info: any) => {
                              if (
                                info.file.status === 'error' ||
                                info.file.status === 'success' ||
                                info.file.status === 'done'
                              ) {
                                setFieldValue(
                                  'file',
                                  info.file.originFileObj,
                                  true
                                );
                              }

                              if (info.fileList.length < 1) {
                                setFieldValue('file', null, true);
                              }
                            }}
                          >
                            <p className='ant-upload-drag-icon'>
                              <GrDocumentCsv
                                id="CreateBroadcastCSVInput"
                                fontSize='3.5rem'
                                style={{
                                  color: 'rgba(0, 0, 0, 0.45)!important',
                                }}
                              />
                            </p>
                            <p className='ant-upload-hint'>
                              Click or drag CSV to this area to upload
                            </p>
                          </Dragger>
                        </Form.Item>
                      </>
                    )}
                    <Row gutter={4} className='d-flex justify-content-end'>
                      <Col>
                        <ResetButton id="CreateBroadCastModalResetButton">Reset</ResetButton>
                      </Col>
                      <Col>
                        <SubmitButton id="CreateBroadCastModalSendButton">Send</SubmitButton>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              ))}
          </Tabs>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateTemplateBroadcastModal;
