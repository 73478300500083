import { useState, useEffect } from 'react';
import { Button, Select } from 'antd';
import ManageRoleModal from './ManageRoleModal';
import {isCurrentPermissionKeyPermitted} from "../../../utils";
import {useSelector} from "react-redux";

const CreateSelectRole = ({
    data,
    setCurrentRoleUniqueId,
    setDrawRolePermission,
    setCurrentRole,
    drawRolePermission
}) => {
    const [rolesData, setRolesData] = useState(data);
    const [manageRoleModalVisible, setManageRoleModalVisibility] = useState(false);
    const permissionState = useSelector(state => state.permissions);

    useEffect(() => {
        if (data) {
            setRolesData(data);
        }
    }, [data]);

    return  <>
                <ManageRoleModal
                    visible={manageRoleModalVisible}
                    rolesData={rolesData}
                    setRolesData={setRolesData}
                    setManageRoleModalVisibility={setManageRoleModalVisibility}
                    setDrawRolePermission={setDrawRolePermission}
                    drawRolePermission={drawRolePermission}
                    closable={true}
                    onCancel={() => setManageRoleModalVisibility(false)}
                    maskClosable={false}
                    destroyOnClose
                />
                <label>Permission Type:</label>&nbsp;&nbsp;
                <Select
                    placeholder='Select Permission Type'
                    style={{
                        width: '350px'
                    }}
                    onChange={(value, option) => {
                        setCurrentRoleUniqueId(value);
                        setCurrentRole(option.role);
                    }}
                >
                    {rolesData 
                        && rolesData.length > 0 
                        && rolesData.map(dataRole => (
                        <Select.Option 
                            key={dataRole.uniqueId} 
                            value={dataRole.uniqueId}
                            role={dataRole}
                        >
                            {dataRole.module} ({dataRole.accessType})
                        </Select.Option>
                    ))}
                </Select>&nbsp;&nbsp;
                <Button
                    disabled={!isCurrentPermissionKeyPermitted('settings:permissions:new_type', permissionState)}
                    onClick={() => setManageRoleModalVisibility(true)}
                >
                    New Type
                </Button>
            </>;
};

export default CreateSelectRole;
