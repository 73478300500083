import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/use-app-selector';

import { getCurrentUserPermission } from 'api/rolePermissions';
import { setPermissions } from 'redux/actions/permissions';

import Dashboard from '../pages/Dashboard/Dashboard';
import CheckedInPatient from '../pages/CheckedInPatient';
import AppointmentsList from 'pages/Appointments/AppointmentsList';
import Queue from '../pages/Queue/Queue';
import CheckIn from '../pages/CheckIn/CheckIn';
import Locations from '../pages/Location/Locations';
import Settings from '../pages/Settings/Settings';
import LocationModalForm from '../pages/Location/LocationModalForm';
// import Collaterals from "../pages/Collateral/Collaterals";
import Visitors from 'pages/Visitors/Visitors';
import Messages from '../pages/Messages/Messages';
import Questions from '../pages/Questions/Questions';
import Documents from '../pages/Documents/Documents';
import Users from '../pages/Users/Users';
// import CollateralDetails from "../pages/Collateral/CollateralDetails";
import Appointment from 'pages/Appointment';
import CollateralPage from 'pages/Collateral';
import FormBuilder from 'pages/FormBuilder';
import Forms from '../pages/forms/forms';
import Tasks from '../pages/tasks/Tasks';
import Broadcasts from './../pages/Broadcasts/index';
import LoginPage from 'pages/Login';
import { keycloak } from 'api/keycloak';

const Routes = (enableQuery = false) => {
    const dispatch = useDispatch();
    const permissions = useAppSelector(state => state.permissions.permissions);
    const auth = useAppSelector(state => state.auth);

    useEffect(() => {
        if (auth.access_token) {
            (async () => {
                try {
                    const response = await getCurrentUserPermission();
                    const permissions = response.data.value;
                    if (!permissions.Dashboard){
                        permissions.Dashboard = [];
                    }
                    dispatch(setPermissions(permissions));
                } catch (err) {
                    console.log(err);
                }
            })();
        }
    }, [auth.access_token]);

    return permissions && Object.keys(permissions).length > 0
        ? [
            permissions.Dashboard
                ? {
                    path: '/dashboard',
                    title: 'Dashboard',
                    component: <Dashboard />,
                    order: 1,
                }
                : null,
            permissions.CheckedinPatients
                ? {
                    path: '/checked-in-patient',
                    title: 'Checked In Patient',
                    component: <CheckedInPatient />,
                    components: [
                        {
                            path: '/:id',
                            // title: 'View Appointment',
                            component: <Appointment />,
                        },
                    ],
                }
                : null,
            permissions.Appointments
                ? {
                    path: '/appointments',
                    title: 'Appointments',
                    component: <AppointmentsList />,
                    components: [
                        {
                            path: '/:id',
                            // title: 'View Appointment',
                            component: <Appointment />,
                        },
                    ],
                    order: 2,
                }
                : null,
            permissions.Queuecontrol
                ? {
                    path: '/queue',
                    title: 'Queue Control',
                    component: <Queue />,
                }
            : null,
            permissions.Check_In_Out
                ? {
                    path: '/checkin',
                    title: 'Check In/Out',
                    component: <CheckIn />,
                    order: 10,
                }
                : null,
            permissions.Locations
                ? {
                    path: '/locations',
                    title: 'Locations',
                    component: <Locations />,
                    components: [
                        {
                            path: '/:id',
                            component: <Locations />,
                        },
                    ],
                    order: 5,
                }
                : null,
            // {
            //     path: '/locationmodalform',
            //     title: 'LocationModalForm',
            //     component: <LocationModalForm />,
            // },
            permissions.Users
                ? {
                    path: '/users',
                    title: 'Users',
                    component: <Users />,
                    order: 6,
                }
                : null,
            permissions.Forms
                ? {
                    path: '/forms',
                    title: 'Forms',
                    component: <Forms />,
                    order: 7,
                }
                : null,
            permissions.Forms && {
                path: '/form-builder',
                title: 'Form Builder',
                component: <FormBuilder />,
                components: [
                    {
                        path: '/:id',
                        component: <FormBuilder />,
                    },
                ],
                order: 20,
            },
            permissions.Settings
                ? {
                    path: '/settings',
                    title: 'Settings',
                    component: <Settings />,
                    order: 9,
                }
                : null,
            // permissions.Visitors
            //       ? {
            //             path: '/visitors',
            //             title: 'Accepted Visitors',
            //             component: <Visitors />,
            //         }
            //       : null,
            // {
            //     path: '/questions',
            //     title: 'Questions',
            //     component: (props: any) => <Questions {...props} mode={'Edit'} />,
            // },
            // {
            //     path: '/documents',
            //     title: 'Documents',
            //     component: (props: any) => <Documents {...props} mode={'Edit'} />,
            // },
            // {
            //     path: '/messages',
            //     title: 'Messages',
            //     component: (props: any) => <Messages {...props} mode={'Edit'} />,
            // },
            permissions.Resources
                ? {
                    path: '/collateral',
                    title: 'Resources',
                    component: <CollateralPage />,
                    order: 4,
                    // components: [
                    //     {
                    //         path: '/:id',
                    //         title: 'View Collateral',
                    //         component: <CollateralDetails />
                    //     }
                    // ]
                }
                : null,
            permissions.Tasks
                ? {
                    path: '/tasks',
                    title: 'Tasks',
                    component: <Tasks />,
                    order: 3,
                }
                : null,
            permissions.Broadcasts
                ? {
                    path: '/broadcasts',
                    title: 'Broadcasts',
                    component: <Broadcasts />,
                    order: 8,
                }
                : null,
        ].filter(route => route)?.sort((r1: any, r2: any) => {
            return r1.order - r2.order;
        })
        : [];
};
export default Routes;
