import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Tooltip,
  Typography,
  Tag,
  Space,
  message,
} from 'antd';
import { FcMindMap, FcOvertime } from 'react-icons/fc';
import { AiFillPhone } from 'react-icons/ai';
import moment from 'moment';
import Notes from './components/Notes';
import PermissifiedComponent from 'components/PermissifiedComponent';
import Messages from './components/Messages';
import Heartrate from './components/Heartrate';
import Attachments from './components/Attachments';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAppointment,
  appointmentDetailsFetchDataRequest,
} from '../../redux/actions/appointments';
import { startTelehealthMeeting } from '../../redux/actions/zoom';
import { BsFillCameraVideoFill } from 'react-icons/all';

import AppointmentCollateral from './components/Collateral';
import AppointmentForm from 'pages/Appointment/components/Forms';
import AppointmentTasks from 'pages/Appointment/components/Tasks';
import { useAppSelector } from './../../hooks/use-app-selector';
import ViewUserDetailsModal from './components/Userdetails/viewUserDetailsModal';
import { InfoCircleFilled } from '@ant-design/icons';
import { resolvePatientDetailsChanged } from 'api/patients';
import ResolvePatientDetailsChangedModal from './components/Userdetails/ResolvePatientDetailsChangedModal';
const { Title } = Typography;

const AppointmentPage = (props) => {
  const { fullWidth } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [refreshTaskList, setRefreshTaskList] = useState(false);
  const { data, error, isLoading } = useSelector((state) => state.appointment);
  const [viewUserDetailVisible, setViewUserDetailVisible] = useState(false);
  const [resolvePatientDetailsChangedModal, setResolvePatientDetailsChangedModal] = useState(false);

  const appointmentId = props.id ? props.id : id;
  const permissionState = useSelector(state => state.permissions);

  useEffect(() => {
    dispatch(fetchAppointment(appointmentId));
    return () => {
      dispatch(appointmentDetailsFetchDataRequest(true));
    };
  }, []);

  const resolveChange = () => {
    resolvePatientDetailsChanged(data.appointmentId).then(() => {
      setResolvePatientDetailsChangedModal(false);
      message.success('Action Performed Successfully.')
      dispatch(fetchAppointment(appointmentId));
    }).catch((error) => {
      setResolvePatientDetailsChangedModal(false);
      message.error(error);
    });
  }

  return (
    <>
      {data && !isLoading ? (
        <>
          <ViewUserDetailsModal visible={viewUserDetailVisible} setVisibility={setViewUserDetailVisible} appointmentId={data?.id} permissionState={permissionState} />
          <ResolvePatientDetailsChangedModal visible={resolvePatientDetailsChangedModal} setVisibility={setResolvePatientDetailsChangedModal} data={data} resolveChange={resolveChange} />
          <Row className='mb-4' gutter={[16, 16]}>
            <Col xs={24} sm={24} xl={fullWidth ? 24 : 8} xxl={fullWidth ? 24 : 8}>
              <Card
                className='h-110 appointment-summary-card'
                title={
                  <Title
                    level={3}
                    className='mb-0 d-block d-flex align-items-center text-primary font-weight-bold'
                  >
                    {data?.appointmentType}
                  </Title>
                }
                extra={
                  data?.attendanceType === 'TELEHEALTH' &&
                  (
                    <Tooltip
                      visible={data?.zoomMeetingPatientJoinDate !== undefined}
                      title='Patient has entered the meeting'
                    >
                      <Button
                        id='appointmentStartTelehealthCallButton'
                        className='text-white bg-green'
                        onClick={() => {
                          dispatch(startTelehealthMeeting(appointmentId));
                        }}
                        icon={
                          <BsFillCameraVideoFill
                            style={{ float: 'left', marginTop: '3px' }}
                            className='hover:border-none'
                          />
                        }
                      >
                        <span style={{ float: 'right', marginLeft: '3px' }}>
                          Start Telehealth Call
                        </span>
                      </Button>
                    </Tooltip>
                  )
                }
              >
                <Row>
                  <Col span={8}>Name:</Col>
                  <Col span={16}>
                    <strong>{`${data?.firstName} ${data?.lastName}`}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Contact #:</Col>
                  <Col span={16}>
                    <strong>{`${data?.contactNumber}`}</strong>
                  </Col>
                </Row>
                {data?.email && (
                  <Row>
                    <Col span={8}>Email Address:</Col>
                    <Col span={16}>
                      <strong>{`${data?.email}`}</strong>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={8}>Attendance Type:</Col>
                  <Col span={16}>
                    <Tag
                      color={
                        data?.attendanceType === 'TELEHEALTH' ? 'green' : 'blue'
                      }
                    >
                      {data?.attendanceType}
                    </Tag>
                  </Col>
                </Row>
                <Row style={{ 'justifyContent': 'right' }}>
                  <Col>
                    <Space>
                      {data.patientDetailsChanged && <Tooltip title="Patient details have changed">
                        <InfoCircleFilled style={{ fontSize: '20px', color: '#ffcc00' }} onClick={() => { setResolvePatientDetailsChangedModal(true) }} />
                      </Tooltip>}
                      <Button id='patientViewDetailsButton' type='primary' onClick={() => { setViewUserDetailVisible(true) }}> View Details </Button>
                    </Space>
                  </Col>
                </Row>

              </Card>
            </Col>
            <Col xs={24} sm={24} xl={fullWidth ? 24 : 16} xxl={fullWidth ? 24 : 16}>
              <Card bodyStyle={{ height: '100%' }}>
                <Row>
                  <Col xs={24} sm={24} xl={fullWidth ? 24 : 8} className='p-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <div className='mb-2 text-primary font-weight-bold'>
                          Date & Time
                        </div>
                        <h1>
                          {moment(data?.appointmentDateTime).format('DD MMM YYYY h:mm A')}
                        </h1>
                      </div>
                      <FcOvertime style={{ width: 48, height: 48 }} />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} xl={fullWidth ? 24 : 8} className='p-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <div className='mb-2 text-primary font-weight-bold'>
                          Clinic
                        </div>
                        <h1>{data?.locationName}</h1>
                        {data?.attendingClinicianName && (
                          <div>
                            <div className='mb-2 text-primary font-weight-bold'>
                              Clinician
                            </div>
                            <h4 className='font-weight-bold'>
                              {data?.attendingClinicianName}
                            </h4>
                          </div>
                        )}
                      </div>
                      <FcMindMap style={{ width: 48, height: 48 }} />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} xl={fullWidth ? 24 : 8} className='p-4'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex flex-column'>
                        <div className='mb-2 text-primary font-weight-bold'>
                          Clinic Phone Number
                        </div>
                        <h1>
                          {data?.locationContactNumber
                            ? data.locationContactNumber
                            : '07 3232 7594'}
                        </h1>
                      </div>
                      <AiFillPhone style={{ width: 48, height: 48 }} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className='mb-4' gutter={[16, 16]}>
            <Col xs={24} sm={24} xl={fullWidth ? 24 : 12} xxl={fullWidth ? 24 : 8}>
              <PermissifiedComponent permissionKey='appointments:tasks'>
                <AppointmentTasks
                  appointmentId={data?.id}
                  refreshTaskList={refreshTaskList}
                  setRefreshTaskList={setRefreshTaskList}
                />
              </PermissifiedComponent>
              <PermissifiedComponent permissionKey='appointments:messages'>
                <Messages appointmentId={data?.id} />
              </PermissifiedComponent>
              <PermissifiedComponent permissionKey='appointments:notes'>
                <Notes appointmentId={data?.id} />
              </PermissifiedComponent>
            </Col>
            <Col xs={24} sm={24} xl={fullWidth ? 24 : 12} xxl={fullWidth ? 24 : 16}>
              <PermissifiedComponent permissionKey='appointments:forms'>
                <AppointmentForm appointmentId={data?.id} />
              </PermissifiedComponent>
              <PermissifiedComponent permissionKey='appointments:attachments'>
                <Attachments
                  appointmentId={data?.id}
                  setRefreshTaskList={setRefreshTaskList}
                />
              </PermissifiedComponent>
              <PermissifiedComponent permissionKey='appointments:collateral'>
                <AppointmentCollateral appointmentId={data?.id} />
              </PermissifiedComponent>
            </Col>
            {process.env.REACT_APP_SHOW_HEART_RATE &&
              process.env.REACT_APP_SHOW_HEART_RATE === true && (
                <Col>
                  <Heartrate />
                </Col>
              )}
          </Row>
        </>
      ) : null}
    </>
  );
};

export default AppointmentPage;
