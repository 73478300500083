import { Col, message, Modal, Row, Typography } from "antd";
import { Formik } from "formik";
import { Form, Input, ResetButton, SubmitButton } from "formik-antd";
import { useEffect, useRef } from "react";
import * as yup from 'yup';
import uuid from 'react-uuid';
import { CloseOutlined } from "@ant-design/icons";

const CreateRoomSchema = yup.object().shape({
    roomName: yup
        .string()
        .required('Please enter a Room name.')
});

const CreateRoomsModal = (props) => {
    let { setVisibility, visible, title, roomName, data, setData, roomId } = props;
    const formikRef = useRef(null);

    const { Title } = Typography;

    useEffect(() => {
        if (!visible) {
            reset();
        }
    }, [visible]);

    function reset() {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    async function create(values) {
        try {
            if (!roomName) {
                let newRoom = {
                    "id": uuid(),
                    "name": values["roomName"],
                    "newRecord": true
                }
                let newData = {
                    "data": [
                        ...data?.data,
                        newRoom
                    ]
                }
                setData(newData);
                message.success('Room added successfully');
            } else {
                let editRoom = {
                    "id": roomId,
                    "name": values["roomName"],
                    "newRecord": data?.data.filter(p => p.id === roomId)[0].newRecord ?? false
                }
                let roomData = data?.data?.map((value, _index) => {
                    if (value.id === roomId) {
                        return editRoom
                    } else {
                        return value;
                    }
                });
                let editData = {
                    "data": roomData
                }
                setData(editData);
                message.success('Room updated successfully')
            }
            setVisibility(false);
        } catch (error) {
            message.destroy();
            message.error('Unable to update the room data');
        }
    }

    return <Modal
        title={
            <Title
                level={4}
                className='mb-0'
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {title} Room
            </Title>
        }
        visible={visible}
        onCancel={() => setVisibility(false)}
        closeIcon={(<CloseOutlined id='closeCreateRoomsModal' />)}
        destroyOnClose
        footer={null}
    >
        <Formik
            innerRef={formikRef}
            initialValues={{ roomName: roomName ? props.roomName : '' }}
            validationSchema={CreateRoomSchema}
            onSubmit={(values) => create(values)}
            render={() => (
                <Form layout='vertical' colon={false}>
                    <Form.Item label='Room Name' name='roomName' className='mb-4'>
                        <Input id="roomNameCreateRoomInput" name='roomName' />
                    </Form.Item>

                    <Row gutter={4} className='d-flex justify-content-end'>
                        <Col>
                            <ResetButton id="resetButtonCreateRoomButton">Reset</ResetButton>
                        </Col>
                        <Col>
                            <SubmitButton id="submitButtonCreateRoomButton">{!roomName ? 'Create' : 'Update'}</SubmitButton>
                        </Col>
                    </Row>
                </Form>
            )}
        />
    </Modal>
}

export default CreateRoomsModal;