import React, {useRef, useEffect, useState} from "react";
import {Typography, Modal, Row, Col, message} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import * as yup from "yup";
import titleize from 'titleize';
import {createMessage} from "../../../../api/messages";
import { CloseOutlined } from "@ant-design/icons";


const {Title} = Typography;

const entity = `message`;
const schema = yup.object().shape({
  subject: yup.string().min(2, "Too short.").max(255, "Too long.").required("Please enter a subject."),
  // message: yup
  //   .string()
  //   .min(2, "Too short.")
  //   .max(255, "Too long.")
  //   .required("Please enter a message."),
});

const CreateMessageModal = ({visible, setVisibility, icon, appointmentId, messageId, onSuccess, onCancel, subject, ...props}) => {
  const formikRef = useRef(null);
  const initialValues = {
    subject: subject ? subject : '',
    message: ""
  };

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])
  const [messageValue, setMessageValue] = useState('');

  async function create(values) {
    try {
      let entity = {
        subject: values.subject,
        message: messageValue,
        replyingToMessageId: messageId
      }
      message.loading(`Creating message...`, 0);
      const res = await createMessage(appointmentId, entity);
      message.destroy();
      onSuccess();
      message.success(`${titleize('Message')} successfully sent.`);
      setVisibility(false)
    } catch (error) {
      message.destroy();
      // message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0"
                    style={{display: "flex", alignItems: "center"}}>{icon}{!subject ? `Send ${titleize(entity)}` : `Re: ${subject}` }</Title>}
      visible={visible}
      onCancel={() => { setVisibility(false); onCancel(); }}
      footer={null}
      closeIcon={(<CloseOutlined id='closeAppointmentsMessage' />)}
      destroyOnClose={true}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values => create(values)}
        render={() => (
          <Form layout="vertical" colon={false}>
            { !subject &&
              <Form.Item label="Subject" name="subject" className="mb-4">
                <Input
                  id="appointmentsMessageSubject"
                  name="subject"
                />
              </Form.Item>
            }
            <Form.Item label="Message" name="message" className="mb-4">
              {/*<Input.TextArea*/}
              {/*  name="message"*/}
              {/*  autoSize={{ minRows: 3, maxRows: 10 }}*/}
              {/*/>*/}
              <Input.TextArea
                // autoSize={{
                //   minRows: 5,
                //   maxRows: 7,
                // }}
                id="appointmentsMessageDescription"
                name="message"
                style={{width: '100%'}}
                onChange={e => setMessageValue(e.target.value)}
              />
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton id="appointmentsMessageReset">Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton id="appointmentsMessageSend">Send</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateMessageModal;
