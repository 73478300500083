import {Tag} from "antd";

const CloseableTag = (props) => {
  return <Tag key={props.index} id={props.id}>{props.tag}
      {props.closable == false ? '' : // because by default tags are closable
          <span
              style={{
                  cursor: 'pointer', paddingLeft: '7px', color: '#212529', fontSize: 'larger'
              }}
              onClick={props.onClose}>x</span>
      }
  </Tag>
}

export default CloseableTag;

