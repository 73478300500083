import React, {useRef} from 'react';
import {Typography, Modal, Row, Col, Button, message} from 'antd';
import {Formik} from 'formik';
import {Form, Input, SubmitButton} from 'formik-antd';
import * as yup from 'yup';
import titleize from 'titleize';
import { updateNote, deleteNote } from 'api/notes';
import { CloseOutlined } from "@ant-design/icons";

const {Title} = Typography;
const entity = `note`;
const schema = yup.object().shape({
  note: yup
    .string()
    .min(2, "Too short.")
    .max(255, "Too long.")
    .required("Please enter a note."),
});

const NoteFormModal = ({ visible, setVisibility, icon, appointmentId, note, onSuccess }) => {
  const formikRef = useRef(null);
  const initialValues = {
    note: note?.note
  };

  const deleteAppointmentNote = async() => {
    try {
      message.loading(`Deleting ${entity}...`, 0);
      const res = await deleteNote(note?.id);
      message.destroy();
      message.success(`${titleize(entity)} successfully deleted.`);
      onSuccess();
      setVisibility(false);
    } catch(error) {
      message.destroy();
      message.error(`Error deleting ${entity}.`);
    }
  }

  const updateAppointmentNote = async(values) => {
    try {
      message.loading(`Updating ${entity}...`, 0);

      const payload = {
        ...values,
        appointmentId
      }
      const res = await updateNote(note?.id, payload);
      message.destroy();
      message.success(`${titleize(entity)} successfully updated.`);
      onSuccess();
    } catch(error) {
      message.destroy();
      message.error(`Error updating ${entity}.`);
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0"
                    style={{display: "flex", alignItems: "center"}}>{icon}{titleize(entity)}</Title>}
      visible={visible}
      closeIcon={(<CloseOutlined id='closeNoteForm' />)}
      onCancel={() => setVisibility(false)}
      footer={null}
      destroyOnClose
    >
      <Formik
        onSubmit={values => updateAppointmentNote(values) }
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        render={() => (
          <Form layout="vertical" colon={false}>
            <Form.Item label="Note" name="note" className="mb-4">
              <Input.TextArea
                id='noteDescription'
                name="note"
                autoSize={{ minRows: 3, maxRows: 10}}
              />
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end"> 
              <>
                <Col>
                  <SubmitButton id="notFormModalSubmit" type="default">Update</SubmitButton>
                </Col>
                <Col>
                  <Button danger id="notFormModalDelete" onClick={deleteAppointmentNote}>Delete</Button>
                </Col>
              </>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default NoteFormModal;
