import {
    FunctionComponent,
} from 'react';
import { 
    Modal, 
    Typography, 
    Row, 
    Col, 
    message,
    Upload
} from 'antd';
import { GrDocumentCsv } from 'react-icons/all';
import { Formik } from 'formik';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';
import { uploadPatients } from 'api/patients';
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Dragger } = Upload;

interface UploadPatientCSVProps {
    visible?: boolean;
    setVisibility?: (visibility: boolean) => void;
    setDraw?: (draw: any) => void;
    draw?: any;
}
  
const UploadPatientCSVModal: FunctionComponent<UploadPatientCSVProps> = ({
    visible,
    setVisibility,
    setDraw,
    draw,
}) => {
    const UploadCSVSchema = Yup.object().shape({
        file: Yup.mixed()
            .required('Please choose CSV file')
    });
  
    return (
      <Modal
        title={
          <Title
            level={4}
            className='mb-0'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <GrDocumentCsv className='mr-2' /> Upload Patients CSV
          </Title>
        }
        visible={visible}
        closeIcon={(<CloseOutlined id='closeUploadPatientCSVModal' />)}
        destroyOnClose
        onCancel={() => {
          if (setVisibility) {
            setVisibility(false);
          }
        }}
        footer={null}
      >
        <Formik
          initialValues={{ file: null }}
          validationSchema={UploadCSVSchema}
          onSubmit={async (patient) => {
            message.loading('Uploading CSV, please wait...', 0);
            try {
              await uploadPatients(patient.file);
              if (setVisibility) {
                setVisibility(false);
              }

              if (setDraw) {
                setDraw(draw + 1);
              }
              message.destroy();
              message.success(
                'CSV successfully uploaded, We will be processing the CSV and might take a while to extract the records.',
                6
              );
            } catch (err: any) {
              const msg = err?.response?.data?.message;
              if (setVisibility) {
                setVisibility(false);
              }

              if (setDraw) {
                setDraw(draw + 1);
              }
              message.destroy();
              if (msg) {
                message.error(msg);
              } else {
                message.error('CSV upload failed.');
              }
            }
          }}
        >
          {({ setFieldValue }): any => (
            <Form layout='vertical' colon={false}>
              <Form.Item name='file' label='Upload CSV'>
                <Dragger
                  name='file'
                  id='uploadCSVDragger'
                  maxCount={1}
                  listType='picture'
                  accept={'.xlsx, .xls, .csv'}
                  onChange={(info: any) => {
                    if (
                      info.file.status === 'error' ||
                      info.file.status === 'success' ||
                      info.file.status === 'done'
                    ) {
                      setFieldValue('file', info.file.originFileObj, true);
                    }
                  }}
                >
                  <p className='ant-upload-drag-icon'>
                    <GrDocumentCsv
                      fontSize='3.5rem'
                      style={{ color: 'rgba(0, 0, 0, 0.45)!important' }}
                    />
                  </p>
                  <p className='ant-upload-hint'>
                    Click or drag CSV to this area to choose a CSV
                  </p>
                </Dragger>
              </Form.Item>
              <Row gutter={4} className='d-flex justify-content-end'>
                <Col>
                  <SubmitButton id='uploadCSVUploadButton'>Upload</SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };
  
  export default UploadPatientCSVModal;
  