import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import FontFaceApplier from 'components/BrandingSettings/FontFaceApplier';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandingList } from 'redux/actions/branding';
import { createGoogleFontLinkTag } from 'utils';

const { Option } = Select;

export default ({ branding, isReadOnly }) => {
    const [fonts, setFonts] = useState([]);
    const [currentFont, setCurrentFont] = useState(null);
    const brandingState = useSelector(state => state.branding);
    const tenantState = useSelector(state => state.tenant);
    const dispatch = useDispatch();

    let currentState = branding;

    const [currentSelectedFont, setCurrentSelectedFont] = useState(currentState.theme.typography.fontFamily);

    useEffect(() => {
        setCurrentSelectedFont(currentState.theme.typography.fontFamily);
    }, [currentState.theme.typography.fontFamily]);

    useEffect(() => {
        axios.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCF2xWj6CMgKFc7ojZOyjQzAgwu9y_K2Gw')
            .then(response => {
                if (response?.data?.items) {
                    setFonts(response.data.items);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }, []);
    
    const webSafeFonts = [
        {
            family: 'Arial',
            category: 'sans-serif',
            isWebSafeFont: true
        },
        {
            family: 'Verdana',
            category: 'sans-serif',
            isWebSafeFont: true
        },
        {
            family: 'Helvetica',
            category: 'sans-serif',
            isWebSafeFont: true
        },
        {
            family: 'Tahoma',
            category: 'sans-serif',
            isWebSafeFont: true
        },
        {
            family: 'Trebuchet',
            category: 'sans-serif',
            isWebSafeFont: true
        },
        {
            family: 'Times New Roman',
            category: 'serif',
            isWebSafeFont: true
        },
        {
            family: 'Georgia',
            category: 'serif',
            isWebSafeFont: true
        },
        {
            family: 'Garamond',
            category: 'serif',
            isWebSafeFont: true
        },
        {
            family: 'Courier New',
            category: 'monospace',
            isWebSafeFont: true
        },
        {
            family: 'Brush Script MT',
            category: 'cursive',
            isWebSafeFont: true
        }
    ];

    const handleFontSelectorChange = (value, options) => {
        var font = options.data;
        if (font) {
            let chosenFont = null;
            let chosenFontUrl = null;
            if (!JSON.parse(font).isWebSafeFont) {
                const googleFont = JSON.parse(font);
                chosenFont = googleFont;
                const apiUrl = [];
                apiUrl.push('https://fonts.googleapis.com/css2?family=');
                
                if (brandingState.activeBrandingId === tenantState.uniqueId) {
                    setCurrentFont(googleFont);
                }
                
                apiUrl.push(googleFont.family.replace(/ /g, '+'));
                let italicTuples = [];
                let italicTuplesStr = '';
                let regularTuples = [];
                let regularTuplesStr = '';

                if (googleFont.variants.includes('italic') && googleFont.variants.length > 2) {
                    apiUrl.push(':');
                    apiUrl.push('ital');
                }

                if (googleFont.variants.length > 2) {
                    if (apiUrl.includes('ital')) {
                        apiUrl.push(',');
                    } else {
                        apiUrl.push(':');
                    }

                    apiUrl.push('wght@');

                    if (googleFont.variants.includes('italic') && googleFont.variants.includes('regular')) {
                        googleFont.variants.map(googleFontVariant => {
                            if (googleFontVariant.includes('italic')) {
                                if (googleFontVariant !== 'italic' && googleFontVariant !== 'regular') {
                                    italicTuples.push(`1,${googleFontVariant.replace('italic', '')}`);
                                }
                            } else {
                                if (googleFontVariant !== 'regular') {
                                    regularTuples.push(`0,${googleFontVariant}`);
                                }
                            }
                        });
                    } else {
                        googleFont.variants.map(googleFontVariant => {
                            if (googleFont.variants.includes('italic') && googleFontVariant !== 'italic') {
                                italicTuples.push(`1,${googleFontVariant.replace('italic', '')}`);
                            }

                            if (googleFont.variants.includes('regular') && googleFontVariant !== 'regular') {
                                regularTuples.push(googleFontVariant);
                            }

                        });
                    }
                    italicTuplesStr = italicTuples.join(';');
                    regularTuplesStr = regularTuples.join(';');
                }

                let url = apiUrl.join('');

                if (italicTuplesStr) {
                    url += `${regularTuplesStr};${italicTuplesStr}`;
                } else {
                    url += regularTuplesStr;
                }

                createGoogleFontLinkTag(url, 'googleFontLinkUrl');
                chosenFontUrl = url;
            } else {
                const webSafeFont = JSON.parse(font);
                chosenFont = webSafeFont;
                
                if (brandingState.activeBrandingId === tenantState.uniqueId) {
                    setCurrentFont(webSafeFont);
                }   
            }

            let brandingList = brandingState.list;

            const currentBrandingIdx = brandingList.findIndex(brandingItem => brandingItem.tenantUniqueId === currentState.tenantUniqueId);

            let newBrandingItemState = {...currentState};

            newBrandingItemState['theme']['typography']['fontFamily'] = chosenFont.family;
            newBrandingItemState['theme']['typography']['fontUrl'] = chosenFontUrl;
            newBrandingItemState['theme']['typography']['font'] = options.data;

            brandingList[currentBrandingIdx] = newBrandingItemState;

            dispatch(setBrandingList(brandingList));
        }
    }

    return  <>
                <FontFaceApplier font={currentFont} />
                <h3 className='font-weight-bolder Branding-Logo-Settings-Header'>Typography</h3>
                <Row>
                    <Col>
                        <label>Global Font:</label><br />
                        {!isReadOnly ? <Select
                            id="BrandingFontSelection"
                            showSearch
                            placeholder='Select Font'
                            style={{ minWidth: '30%' }}
                            onChange={(value, options) => {
                                handleFontSelectorChange(value, options);
                                setCurrentSelectedFont(value);
                            }}
                            defaultValue={currentSelectedFont}
                            value={currentSelectedFont}
                        >
                            {webSafeFonts 
                                && webSafeFonts.length > 0 
                                    && webSafeFonts.map((webSafeFont, index) => (
                                        <Option key={`${index}-websafe`} data={JSON.stringify(webSafeFont)} value={webSafeFont.family} style={{ fontSize: '18px', fontFamily: 'Arial, sans-serif' }}>
                                            {`${webSafeFont.family}, (${webSafeFont.category})`}
                                        </Option>
                            ))}
                            {fonts 
                                && fonts.length > 0 
                                    && fonts.map((font, index) => ( 
                                        <Option key={index} data={JSON.stringify(font)} value={font.family} style={{ fontSize: '18px', fontFamily: 'Arial, sans-serif' }}>
                                            {`${font.family} (${font.category})`}
                                        </Option> 
                            ))}
                        </Select> : <h4 className="font-weight-bolder">
                                        {currentSelectedFont}
                                    </h4>}
                    </Col>
                </Row>
            </>;
}