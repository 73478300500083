import {
    SET_ACTIVE_BRANDING_TENANT_ID,
    SET_BRANDING,
    SET_BRANDING_LIST,
    SET_BRANDING_LOGO,
    SET_BRANDING_TENANT_UNIQUE_ID,
    SET_CENTRALISE_BRANDING,
} from './types/branding';

export const setCentraliseBranding = (isCentralise) => {
    return {
        type: SET_CENTRALISE_BRANDING,
        payload: isCentralise
    }
}

export const setBrandingTenantUniqueId = tenantUniqueId => {
    return {
        type: SET_BRANDING_TENANT_UNIQUE_ID,
        payload: tenantUniqueId
    }
}

export const setBranding = branding => {
    return {
        type: SET_BRANDING,
        branding
    }
}

export const setActiveBrandingTenantId = id => {
    return {
        type: SET_ACTIVE_BRANDING_TENANT_ID,
        id
    }
}

export const setBrandingList = list => {
    return {
        type: SET_BRANDING_LIST,
        branding: list
    }
}

export const setBrandingLogo = logo => {
    return {
        type: SET_BRANDING_LOGO,
        logo
    }
}