import React from 'react';
import { Route } from 'react-router-dom';
import BasePage from 'pages/BasePage';

function RouteWithSubRoutes(route) {
    return (
        <>
            <Route
                exact
                path={route.path}
            >
                {route.children}
            </Route>
            {route.components && route.components.length > 0 && route.components.map((routeComponent, i) => (
                <Route
                    key={routeComponent.path}
                    path={`${route.path}${routeComponent.path}`}
                >
                    <BasePage pageName={routeComponent.title}>{routeComponent.component}</BasePage>
                </Route>
            ))}
        </>
    );
}

export default RouteWithSubRoutes;
