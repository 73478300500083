import {
    ACTIVE_APPOINTMENT_ID,
    SET_COLLATERAL_MODAL_VISIBILITY, SET_DELETE_MODAL_VISIBILITY,
    SET_MESSAGE_MODAL_VISIBILITY,
    SET_NOTE_MODAL_VISIBILITY,
    SET_STATUS_MODAL_PROPS,
    TOGGLE_SIDEBAR_VISIBILITY,
    SET_TOPBAR_VISIBILITY,
    SET_BREADCRUMBS_TEXT,
    SET_FORM_BUILDER_ACTIVE_TAB,
    SET_FORM_BUILDER_EVENT_SELECTING,
    SET_HOSPITAL_LOCATIONS
} from 'redux/actions/types/ui';
import {
    SET_ACTIVE_COLLATERAL,
    SET_COLLATERAL_APPOINTMENT_ID, SET_COLLATERAL_DELETE_MODAL_VISIBILITY,
    SET_COLLATERAL_DETAILS_MODAL_VISIBILITY, SET_HELPER_EDIT_ITEM, SET_HELPER_ITEM_VISIBILITY
} from "./types/ui";

export const setTopbarVisibility = isTopbarVisible => {
    return {
        type: SET_TOPBAR_VISIBILITY,
        isTopbarVisible
    }
};

export const toggleSideBarVisibility = () => {
    return {
        type: TOGGLE_SIDEBAR_VISIBILITY
    }
};

export const setStatusModalVisibility = statusModalProps => {
    return {
        type: SET_STATUS_MODAL_PROPS,
        statusModalProps,
    }
};

export const setMessageModalVisibility = isMessageModalVisible => {
    return {
        type: SET_MESSAGE_MODAL_VISIBILITY,
        isMessageModalVisible,
    }
};

export const setCollateralModalVisibility = isCollateralModalVisible => {
    return {
        type: SET_COLLATERAL_MODAL_VISIBILITY,
        isCollateralModalVisible,
    }
};

export const setNoteModalVisibility = isNoteModalVisible => {
    return {
        type: SET_NOTE_MODAL_VISIBILITY,
        isNoteModalVisible,
    }
};

export const setActiveAppointmentId = activeAppointmentId => {
    return {
        type: ACTIVE_APPOINTMENT_ID,
        activeAppointmentId
    }
}

export const setActiveCollateralId = activeCollateralId => {
    return {
        type: SET_COLLATERAL_APPOINTMENT_ID,
        activeCollateralId
    }
}

export const setCollateralDetailsModalVisibility = isCollateralDetailsModalVisible => {
    return {
        type: SET_COLLATERAL_DETAILS_MODAL_VISIBILITY,
        isCollateralDetailsModalVisible
    }
}

export const setDeleteModalVisibility = isDeleteModalVisible => {
    return {
        type: SET_DELETE_MODAL_VISIBILITY,
        isDeleteModalVisible
    }
}

export const setActiveCollateral = activeCollateral => {
    return {
        type: SET_ACTIVE_COLLATERAL,
        activeCollateral
    }
}

export const setCollateralDeleteModalVisibility = isCollateralDeleteModalVisible => {
    return {
        type: SET_COLLATERAL_DELETE_MODAL_VISIBILITY,
        isCollateralDeleteModalVisible
    }
}

export const setHelperEditVisibility = isHelperEditVisible => {
    return {
        type: SET_HELPER_ITEM_VISIBILITY,
        isHelperEditVisible
    }
}

export const setHelperEditItem = helperEditItem => {
    return {
        type: SET_HELPER_EDIT_ITEM,
        helperEditItem
    }
}

export const setBreadcrumbsText = currentBreadcrumbDisplay => {
    return {
        type: SET_BREADCRUMBS_TEXT,
        currentBreadcrumbDisplay
    }
};

export const setFormBuilderActiveTab = (tab) => {
    return {
        type: SET_FORM_BUILDER_ACTIVE_TAB,
        tab
    }
}

export const setFormBuilderEventSelecting = (isSelecting) => {
    return {
        type: SET_FORM_BUILDER_EVENT_SELECTING,
        isSelecting
    }
}

export const setHospitalLocations = (locations) => {
    return {
        type: SET_HOSPITAL_LOCATIONS,
        locations
    }
}