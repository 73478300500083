import { BASE_API_URL } from './config';
import { createAxios } from './axios';

const rolePermissionsApi = createAxios({
    baseURL: BASE_API_URL + '/role-permission',
});

export const getAllRolePermission = () => {
    return rolePermissionsApi.get('');
}

export const createRolePermission = (payload) => {
    return rolePermissionsApi.post('', payload)
};

export const updateRolePermission = (uniqueId, payload) => {
    return rolePermissionsApi.put(`/${uniqueId}`, payload);
};

export const getCurrentUserPermission = () => {
    return rolePermissionsApi.get('/current-user');
};

export const getAllRolePermissions = () => {
    return rolePermissionsApi.get('');
};

export const getPermissionByRole = (uniqueId) => {
    return rolePermissionsApi.get(`/${uniqueId}`);
};

export const bulkUpdateRolePermission = (payload) => {
    return rolePermissionsApi.put('', payload);
}