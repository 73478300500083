export const FormModes = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

export const MessageTemplateMode = {
    LIST: 'LIST',
    CREATE: 'CREATE',
    EDIT: 'EDIT'
};

export const QueueEntryStatusTypes = [
    { value: 'CHECKED_IN', displayText: 'Checked In' },
    { value: 'START_CONSULT', displayText: 'In Consult' },
    { value: 'CHECKED_OUT', displayText: 'Checked Out' },
]

export const StatusTypes = [
    { value: 'REQUEST_RESCHEDULE', displayText: 'Request Reschedule' },
    // { value: 'SCHEDULED', displayText: 'Scheduled' },
    // { value: 'IN_PROGRESS', displayText: 'In Progress' },
    // { value: 'COMPLETE', displayText: 'Complete' },
    { value: 'CANCELLED', displayText: 'Cancelled' },
    { value: 'UNCONFIRMED', displayText: 'Unconfirmed' },
    // { value: 'DECLINED', displayText: 'Declined' },
    { value: 'CONFIRMED', displayText: 'Confirmed' },
    // { value: 'CHECKED_IN_WAITING', displayText: 'Checked In Waiting' },
    // {
    //   value: 'CALLED_BACK_TO_QUEUE',
    //   displayText: 'Called Back To Queue',
    // },
    { value: 'CHECKED_IN', displayText: 'Checked In' },
    // { value: 'IN', displayText: 'In' },
    { value: 'CHECKED_OUT', displayText: 'Checked Out' },
    { value: 'NO_SHOW', displayText: 'No Show' },
    // need to remove this
    { value: 'START_CONSULT', displayText: 'In Consult' },
];

export const CheckedInPatientStatusTypes = [
    { value: 'CHECKED_IN', displayText: 'Checked In' },
    { value: 'CHECKED_OUT', displayText: 'Checked Out' },
    // need to remove start consult and called
    { value: 'START_CONSULT', displayText: 'In Consult' },
];

export const Format = {
    DayMonthYearFormat: "DD/MM/YYYY",
};

export const Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];