import axios from 'axios';
import BaseService from "./BaseService";
import {REQUEST_TIMEOUT} from "api/config";

const NOTIFICATION_API_BASE_URL = "/api/notificationrecords";

class MessageService extends BaseService {

    constructor() {
        super(NOTIFICATION_API_BASE_URL);
    }

    getMessages(page, start, length, search, sortColumn, sortDirection, appointmentId){
        return axios.get(NOTIFICATION_API_BASE_URL + '/notification', {
            timeout: REQUEST_TIMEOUT,
            params: {
                page: page,
                draw: 1,
                start: start,
                sortColumn: sortColumn,
                sortDirection: sortDirection,
                length: length,
                search: search,
                appointmentId: appointmentId
            }
        });
    }
}

export default new MessageService()