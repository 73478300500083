import { useRef, useState, useEffect } from 'react';
import { Typography, Modal, Row, Col, message, Tabs, Button } from 'antd';
import { Formik, useFormik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import { PaperClipOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setClinic, setDepartment, setFacility } from "redux/actions/collateralList";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import ActionButtonsDropdown from 'components/ActionButtonsDropdown';
import CreateRoomsModal from './CreateRoomsModal';
import { createQueue, updateQueue } from 'api/queue';
import DeleteRoomsModal from "./DeleteRoomsModal";
import { CloseOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CreateQueueSchema = yup.object().shape({
    facility: yup.string().required('Please select a Facility.'),
    department: yup.string().required('Please select a Department.'),
    clinic: yup.string().required('Please select a Clinic.'),
    ticketPrefix: yup.string().required('Please enter a Ticket Prefix.')
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .max(4, 'Maximum 4 alphabets are allowed'),
    capacity: yup.number().required('Please enter a Capacity.')
        .typeError('Capacity must be a number')
        .positive('Capacity must be greater than zero')
});

const CreateQueueModal = ({
    visible,
    selectedQueue,
    setVisibility,
    onSuccess,
    locationData
}) => {

    const [tabs] = useState([
        {
            key: 0,
            name: `${selectedQueue ? "Edit" : "Create"} Queue`,
        },
        {
            key: 1,
            name: 'Rooms',
        },
    ]);
    const [tabkey, setTabKey] = useState(0);
    const { TabPane } = Tabs;
    const changeTab = (key) => {
        setTabKey(key);
    };
    const [draw, setDraw] = useState(0);
    const [facilityId, setFacilityId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [clinicId, setClinicId] = useState('');
    const [prefix, setPrefix] = useState('');
    const [capacity, setCapacity] = useState(null);
    const [deleteRoomModelVisible, setDeleteModalVisible] = useState(false);
    const [roomId, setRoomId] = useState("");
    const { facilities, departments, clinics } = useSelector(state => state.collateralList);
    const formikRef = useRef(null);
    const [editRoomModelVisible, setEditModalVisible] = useState(false);
    const [room, setRoom] = useState("");
    const facilityRef = useRef([]);
    const departmentRef = useRef([]);
    const clinicRef = useRef([]);
    const title = selectedQueue ? "Edit" : "Create";

    const dispatch = useDispatch();

    useEffect(() => {
        fetchLocationsData();
    }, []);

    useEffect(() => {
        departmentRef.current = departments ? departments : [];
        clinicRef.current = clinics ? clinics : [];
    }, [departments, clinics])

    const fetchLocationsData = async () => {
        dispatch(setFacility(locationData));
        facilityRef.current = locationData;
    }

    const [data, setData] = useState([]);

    const { values, setFieldValue } = useFormik({
        initialValues: {
            facility: selectedQueue ? selectedQueue?.facility?.id : facilityId,
            department: selectedQueue ? selectedQueue?.department?.id : departmentId,
            clinic: selectedQueue ? selectedQueue?.clinic?.id : clinicId,
            ticketPrefix: selectedQueue ? selectedQueue.ticketPrefix : prefix,
            capacity: selectedQueue ? selectedQueue.capacity : capacity,
        }
    });

    useEffect(() => {
        if (selectedQueue && selectedQueue.facility) {
            setFacilityId(selectedQueue?.facility?.id);
        }
        if (selectedQueue && selectedQueue.department) {
            setDepartmentId(selectedQueue?.department?.id);
        }
        if (selectedQueue && selectedQueue.clinic) {
            setClinicId(selectedQueue?.clinic?.id);
        }
        if (selectedQueue && selectedQueue.ticketPrefix) {
            setPrefix(selectedQueue.ticketPrefix);
        }
        if (selectedQueue && selectedQueue.capacity) {
            setCapacity(selectedQueue.capacity);
        }

        const filteredFacility = facilities.filter((item) => item.id === selectedQueue?.facility?.id);
        dispatch(setDepartment(filteredFacility[0]?.children));

        if (filteredFacility[0]?.children?.length > 0) {
            const filteredDepartment = filteredFacility[0]?.children.filter((item) => item.id === selectedQueue?.department?.id);
            dispatch(setClinic(filteredDepartment[0]?.children));
        }

        if (selectedQueue?.rooms?.length > 0) {
            setData({
                data: selectedQueue.rooms
            })
        }
        else {
            setData({
                data: []
            });
        }
    }, [selectedQueue]);

    useEffect(() => {
        setFieldValue('facility', facilityId);
        setFieldValue('department', departmentId);
        setFieldValue('clinic', clinicId);
        setFieldValue('ticketPrefix', prefix);
        setFieldValue('capacity', capacity);
    }, [facilityId, departmentId, clinicId, prefix, capacity]);

    useEffect(() => {
        if (!visible) {
            reset();
        }
    }, [visible]);

    const cols = [
        {
            title: <strong>Name</strong>,
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (value, record, index) => {
                return <>
                    {value}
                </>;
            }
        },
        {
            title: <strong>Actions</strong>,
            dataIndex: 'id',
            key: 'id',
            sorter: false,
            render: (text, record, value) => {
                const roomOptions = [
                    {
                        label: 'Edit Room',
                        id: 'EditRoomMenu',
                        icon: <></>,
                        onClick: () => {
                            setRoomId(record?.id);
                            setRoom(record?.name);
                            setEditModalVisible(true);
                        },
                    },
                    {
                        label: 'Delete Room',
                        id: 'DeleteRoomMenu',
                        icon: <></>,
                        onClick: () => {
                            setRoomId(record?.id);
                            setRoom(record?.name);
                            setDeleteModalVisible(true);
                        },
                    },
                ];

                return (
                    <>
                        <ActionButtonsDropdown menus={roomOptions} tabName='QueueMetaData'/>
                    </>
                );
            },
        },
    ];

    async function create(values) {
        try {
            message.loading('Uploading queue...', 0);
            const payload = {
                facilityId: facilityId,
                departmentId: departmentId,
                clinicId: clinicId,
                ticketPrefix: prefix,
                capacity: capacity,
                rooms: data?.data.map(val => { return val.newRecord ? { name: val.name } : { id: val.id, name: val.name } }) ?? []
            }
            if (selectedQueue) {
                await updateQueue(selectedQueue?.id, payload);
                message.destroy();
                message.success('Queue successfully updated.');
            } else {
                await createQueue(payload);
                message.destroy();
                message.success('Queue successfully created.');
            }
            onSuccess();
            setVisibility(false);
        } catch (error) {
            if (error.response.status === 409) {
                message.error('The selected clinic has already a queue defined!');
            }
            else {
                message.error(error.response.data.message);
            }
        }
    }

    function reset() {
        if (formikRef.current) {
            formikRef.current.resetForm();
            setFacilityId('');
            setDepartmentId('');
            setClinicId('');
            setPrefix('');
            setCapacity(null);
            setData({
                data: []
            });
            onSuccess();
            dispatch(setDepartment([]));
            dispatch(setClinic([]));
        }
    }

    const onChangeFacility = (val) => {
        setFacilityId(val)
        const filteredFacility = facilities.filter((item) => item.id === val);
        dispatch(setDepartment(filteredFacility[0].children));
        setDepartmentId('');
        setClinicId('');
    }

    const onChangeDepartment = (val) => {
        setDepartmentId(val);
        const filteredDepartment = departments.filter((item) => item.id === val);
        dispatch(setClinic(filteredDepartment[0].children));
        setClinicId('');
    }

    const formatInput = (e) => {
        // Prevent characters that are not numbers ("e", ".", "+" & "-")
        let checkIfNum;
        if (e?.key !== undefined) {
            // Check if it's a "e", ".", "+" or "-"
            checkIfNum = e?.key === "e" || e?.key === "." || e?.key === "+" || e?.key === "-";
        }
        else if (e?.keyCode !== undefined) {
            // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
            checkIfNum = e?.keyCode === 69 || e?.keyCode === 190 || e?.keyCode === 187 || e?.keyCode === 189;
        }
    
        return checkIfNum && e.preventDefault();
    }

    return (
        <>
            <Modal
                title={
                    <Title
                        id='CreateQueueModalTitle'
                        level={4}
                        className='mb-0'
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <PaperClipOutlined className='mr-2' />
                        {title} Queue
                    </Title>
                }
                visible={visible}
                closeIcon={(<CloseOutlined id='closeCreateQueueModal' />)}
                onCancel={() => setVisibility(false)}
                destroyOnClose
                footer={null}
            >
                <Formik
                    enableReinitialize
                    innerRef={formikRef}
                    initialValues={values}
                    validationSchema={CreateQueueSchema}
                    onSubmit={(values) => create(values)}
                    render={() => (
                        <Tabs onChange={changeTab} id='Queue'>
                            {tabs &&
                                tabs.map((tab) => (
                                    <TabPane id={tab.name} tab={tab.name} key={tab.key}>
                                        <Form layout='vertical' colon={false}>
                                            {tab.key === 0 && <>
                                                <Form.Item label='Facility' name='facility' required>
                                                    <Select 
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    id='CreateQueueModalFacilitySelect' name='facility' defaultValue={facilityId}
                                                        onChange={onChangeFacility}
                                                    >
                                                        {facilities && facilities?.map((facility, idx) => {
                                                            return (
                                                                <Select.Option key={idx} value={facility.id}>
                                                                    {facility.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='Department' name='department' required>
                                                    <Select
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    id='CreateQueueModalDepartmentSelect' name='department' defaultValue={departmentId}
                                                        onChange={onChangeDepartment}
                                                    >
                                                        {departments && departments?.map((department, idx) => {
                                                            return (
                                                                <Select.Option key={idx} value={department.id}>
                                                                    {department.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='Clinic' name='clinic' required>
                                                    <Select
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                           option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        id='CreateQueueModalClinicSelect'
                                                        name='clinic'
                                                        defaultValue={clinicId}
                                                        onChange={(val) => setClinicId(val)}
                                                    >
                                                        {clinics && clinics?.map((clinic, idx) => {
                                                            return (
                                                                <Select.Option key={idx} value={clinic.id}>
                                                                    {clinic.name}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item label='Ticket Prefix' name='ticketPrefix' className='mb-4' required>
                                                    <Input id='CreateQueueModalPrefixInput' name='ticketPrefix' value={prefix} onChange={(e) => setPrefix(e.target.value.toUpperCase())} />
                                                </Form.Item>
                                                <Form.Item label='Waiting Capacity' name='capacity' className='mb-4' required>
                                                    <Input id='CreateQueueModalCapacityInput' name='capacity' type={'number'} value={capacity} onChange={(e) => setCapacity(parseInt(e.target.value))} onKeyDown={(e) => formatInput(e)} />
                                                </Form.Item>
                                            </>
                                            }
                                            {tab.key === 1 && <>
                                                <DeleteRoomsModal
                                                    roomId={roomId}
                                                    roomName={room}
                                                    data={data}
                                                    setData={setData}
                                                    visible={deleteRoomModelVisible}
                                                    setVisibility={setDeleteModalVisible}
                                                />
                                                <CreateRoomsModal
                                                    roomName={room}
                                                    title={"Edit"}
                                                    data={data}
                                                    roomId={roomId}
                                                    setData={setData}
                                                    visible={editRoomModelVisible}
                                                    setVisibility={setEditModalVisible}
                                                />
                                                <CRUDDataTable
                                                    data={data}
                                                    fetchDataFunction={async (params) => {
                                                        let newData;
                                                        if (params?.search) {
                                                            newData = { "data": data.data?.filter(p => p.name.toLowerCase().includes(params.search.toLowerCase())) }
                                                        }
                                                        else {
                                                            newData = data;
                                                        }
                                                        return { "data": newData }
                                                    }}
                                                    draw={draw}
                                                    entity={'Room'}
                                                    createModal={<CreateRoomsModal
                                                        data={data}
                                                        setData={setData}
                                                        title={'Create'}
                                                    />}
                                                    columns={cols}
                                                />
                                            </>}
                                            <Row gutter={4} className='d-flex justify-content-end'>
                                                <Col>
                                                    {tab.key === 0 && <Button id='CreateQueueModalResetButton' type='dashed' title='Reset' onClick={() => reset()}>Reset</Button>}
                                                </Col>
                                                <Col>
                                                    <SubmitButton id='CreateQueueModalSubmitButton'>{selectedQueue ? 'Update' : 'Create'}</SubmitButton>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </TabPane>
                                ))}
                        </Tabs>
                    )}
                />
            </Modal>
        </>
    );
};

export default CreateQueueModal;
