import React, {useRef, useEffect} from "react";
import {Typography, Modal, Row, Col, message} from "antd";
import {Formik} from "formik";
import {Form, Input, ResetButton, SubmitButton} from "formik-antd";
import * as yup from "yup";
import titleize from 'titleize';
import {createStaffTask, updateStaffTask} from 'api/staffTasks'
import { CloseOutlined } from "@ant-design/icons";


const {Title} = Typography;

const entity = `staff task`;

const schema = yup.object().shape({
  description: yup
    .string()
    .min(2, "Too short.")
    .max(255, "Too long.")
    .required("Please enter a description."),
});

const CreateStaffTaskModal = ({visible, setVisibility, icon, appointmentId, onSuccess, onCancel, fetchData, task}) => {
  const formikRef = useRef(null);
  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible])
  const initialValues = {
    description: task?.description ?? '',
  };
  async function create(values) {
    try {
      let payload = {
        appointmentId: appointmentId,
        assigneeType: 'FRONT_DESK',
        taskType: 'CUSTOM',
        description: values.description
      }
      message.loading(`Creating ${entity}...`, 0);
      const res = task !== undefined ? await updateStaffTask(task?.id, payload?.description) : await createStaffTask(payload);
      message.destroy();
      message.success(`${titleize(entity)} successfully ${task !== undefined ? 'updated': 'created' }.`);
      onSuccess();
      fetchData();
      setVisibility(false);
    } catch (error) {
      message.destroy();
      // message.error(error);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }

  return (
    <Modal
      title={<Title level={4} className="mb-0"
                    style={{display: "flex", alignItems: "center"}}>{icon}{`${task !== undefined ? 'Edit' : 'Add '} ${titleize(entity)}`}</Title>}
      visible={visible}
      onCancel={() => { setVisibility(false); onCancel(); }}
      footer={null}
      closeIcon={(<CloseOutlined id='closeStaffTask' />)}
      destroyOnClose
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={values => create(values)}
        render={() => (
          <Form layout="vertical" colon={false}>
            <Form.Item label="Description" name="description" className="mb-4">
              <Input
                id="staffTaskDescription"
                name="description"
              />
            </Form.Item>
            <Row gutter={4} className="d-flex justify-content-end">
              <Col>
                <ResetButton id="staffTaskReset">Reset</ResetButton>
              </Col>
              <Col>
                <SubmitButton id="staffTaskSubmit">{task !== undefined ? 'Edit': 'Add'}</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateStaffTaskModal;
