import React from 'react';
import { IconContext } from 'react-icons';
import {NavLink, useHistory} from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge } from 'antd';

import {
  Dashboard,
  PermContactCalendar,
  MenuBook,
  GroupAdd,
  Domain,
  People,
  QuestionAnswer,
  CheckBox,
  Description,
  Settings,
  Person,
  ExitToApp,
  FileCopy,
  FormatListNumbered,
  Announcement,
  List,
  Queue
} from '@material-ui/icons';
import { useAuthContext } from 'hooks/index';
import { useQuery } from 'react-query';
import { getIncompleteStaffTasks } from 'api/staffTasks';
import { useAppSelector } from './../../hooks/use-app-selector';
import {removeAuth} from 'utils/helpers';

const SideMenu = (props) => {
  const history = useHistory();
  const { fullName, isSidebarVisible } = props;
  const {logout} = useAuthContext();
  const REFETCH_INTERVAL_IN_MILLISECONDS = 60000;
  const logoutApp = () => {
    logout();
    removeAuth();
    history.push('/');
  };

  const { data: incompleteStaffTasks } = useQuery(
    'getIncompleteStaffTasks',
    async () => {
      return await getIncompleteStaffTasks();
    },
    {
      refetchInterval: REFETCH_INTERVAL_IN_MILLISECONDS,
    }
  );

  const permissions = useAppSelector((state) => state.permissions.permissions);

  return (
    <IconContext.Provider
      value={{ style: { fontSize: '26px', paddingRight: '10px' } }}
    >
      <div
        className={`navbar-default sidebar ${isSidebarVisible ? 'active' : ''}`}
        role='navigation'
      >
        <div className='sidebar-nav navbar-collapse'>
          <ul className='nav' id='side-menu'>
            <li className='Sidebar-Sign-In d-none d-block d-sm-block d-md-none'>
              <Person fontSize='large' /> {`Signed in as:`}{' '}
              <strong>{fullName}</strong>
            </li>
            {permissions.Dashboard ? (
              <li>
                <NavLink to='/dashboard' id='sideMenuDashboard' exact>
                  <Dashboard fontSize='large' /> Dashboard
                </NavLink>
              </li>
            ) : null}
            {permissions.CheckedinPatients ? <li>
              <NavLink to='/checked-in-patient' id='sideMenuCheckedInPatient'>
                <List fontSize='large' /> Arrived Patients
              </NavLink>
            </li> : null}
            {permissions.Queuecontrol ? (
                    <li>
                  <NavLink to='/queue' id='sideMenuQueueControl' exact>
                    <Queue fontSize='large' /> Queue Control
                  </NavLink>
                </li>
            ) : null}
            {permissions.Appointments ? (
              <li>
                <NavLink to='/appointments' id='sideMenuAppointments'>
                  <PermContactCalendar fontSize='large' /> Admissions
                </NavLink>
              </li>
            ) : null}
            {permissions.Tasks ? (
              <li>
                <NavLink to='/tasks' id='sideMenuTasks'>
                  <Badge count={incompleteStaffTasks?.data}>
                    <FormatListNumbered fontSize='large' />
                  </Badge>
                  Tasks{' '}
                </NavLink>
              </li>
            ) : null}
            {permissions.Resources ? (
              <li>
                <NavLink to='/collateral' id='sideMenuResources'>
                  <MenuBook fontSize='large' /> Resources
                </NavLink>
              </li>
            ) : null}
            {permissions.Locations ? (
              <li>
                <NavLink to='/locations' id='sideMenuClinics'>
                  <Domain fontSize='large' /> Locations
                </NavLink>
              </li>
            ) : null}
            {permissions.Visitors ? (
              <li>
                <NavLink to='/visitors' id='sideMenuAcceptedVisitors' >
                  <GroupAdd fontSize='large' /> Accepted Visitors
                </NavLink>
              </li>
            ) : null}
            {permissions.Check_In_Out ? (
              <li>
                <NavLink to='/checkin' id='sideMenuCheckIn'>
                  <CheckBox fontSize='large' /> Check In
                </NavLink>
              </li>
            ) : null}
            {permissions.Users ? (
              <li>
                <NavLink to='/users' id='sideMenuUsers'>
                  <People fontSize='large' /> Users
                </NavLink>
              </li>
            ) : null}
            {/* <li><NavLink to="/questions"><QuestionAnswer fontSize='large' />{" "}Bot Questions</NavLink></li> */}
            {permissions.Forms ? (
              <li>
                <NavLink to='/forms' id='sideMenuForms'>
                  <FileCopy fontSize='large' /> Forms
                </NavLink>
              </li>
            ) : null}
            {/*<li><NavLink to="/documents"><Description fontSize='large' /> Documents</NavLink></li>*/}
            {permissions.Broadcasts ? (
              <li>
                <NavLink to='/broadcasts' id='sideMenuBroadcasts'>
                  <Announcement fontSize='large' /> Broadcasts
                </NavLink>
              </li>
            ) : null}
            {permissions.Settings ? (
              <li>
                <NavLink to='/settings' id='sideMenuSettings'>
                  <Settings fontSize='large' /> Settings
                </NavLink>
              </li>
            ) : null}
            <li className='d-none d-block d-sm-block d-lg-none'>
              <NavLink to=''  id='sideMenuLogout' onClick={logoutApp} exact>
                <ExitToApp fontSize='large' /> Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </IconContext.Provider>
  );
};

export default connect((state) => {
  return {
    isSidebarVisible: state.ui.isSidebarVisible,
  };
})(SideMenu);
