import React, { useRef, useState, useEffect } from 'react';
import { Typography, Modal, Upload, Row, Col, message, Select, Button } from 'antd';
import { Formik, useFormik } from 'formik';
import { Form, Input, ResetButton, SubmitButton } from 'formik-antd';
import * as yup from 'yup';
import { PaperClipOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { createCollateral, updateCollateral } from 'api/collaterals';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setClinic, setDepartment, setFacility } from "redux/actions/collateralList";

const { Title, Text } = Typography;

const CreateCollateralSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Too short.')
    .max(30, 'Too long.')
    .required('Please enter a name.'),
  // description: yup
  //   .string()
  //   .min(2, "Too short.")
  //   .max(255, "Too long.")
  //   .required("Please enter a description."),
  url: yup.string()
    .matches(
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/gi,
      'Please enter a valid url.'
    ),
  facility: yup
    .string()
    .required('Please enter a Facility.'),
  department: yup
    .string()
    .required('Please enter a Department.'),
  clinic: yup.string().required('Please select a Clinic.'),
});

const CreateCollateralForm = ({
  title,
  visible,
  collateral,
  setVisibility,
  onSuccess,
}) => {
  const [files, setFiles] = useState([]);
  const [nameValue, setNameValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [urlValue, setUrlValue] = useState('');
  const [facilityId, setFacilityId] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [labelValue, setLabelValue] = useState('');
  const { facilities, departments, clinics } = useSelector(state => state.collateralList);
  const formikRef = useRef(null);

  const dispatch = useDispatch();

  const { values, errors, handleChange, setFieldValue } = useFormik({
    initialValues: {
      name: collateral ? collateral.name : nameValue,
      url: collateral ? collateral.url : urlValue,
      label: collateral ? collateral.label : labelValue,
      facility: collateral ? collateral?.facility?.id : facilityId,
      department: collateral ? collateral?.department?.id : departmentId,
      clinic: collateral ? collateral?.clinic?.id : clinicId
    }
  });

  useEffect(() => {
    if (collateral && collateral.name) {
      setNameValue(collateral.name);
    }
    if (collateral && collateral.description) {
      setDescriptionValue(collateral.description);
    }
    if (collateral && collateral.url) {
      setUrlValue(collateral.url);
    }
    if (collateral && collateral.facility) {
      setFacilityId(collateral?.facility?.id);
    }
    if (collateral && collateral.department) {
      setDepartmentId(collateral?.department?.id);
    }
    if (collateral && collateral.clinic) {
      setClinicId(collateral?.clinic?.id);
    }
    if (collateral && collateral.label) {
      setLabelValue(collateral.label);
    }

    const filteredFacility = facilities.filter((item) => item.id === collateral?.facility?.id);
    dispatch(setDepartment(filteredFacility[0]?.children));

    if (filteredFacility[0]?.children?.length > 0) {
      const filteredDepartment = filteredFacility[0]?.children.filter((item) => item.id === collateral?.department?.id);
      dispatch(setClinic(filteredDepartment[0]?.children));
    }
  }, [collateral]);

  useEffect(() => {
    setFieldValue('name', nameValue);
    setFieldValue('description', descriptionValue);
    setFieldValue('url', urlValue);
    setFieldValue('facility', facilityId);
    setFieldValue('department', departmentId);
    setFieldValue('clinic', clinicId);
    setFieldValue('label', labelValue);
  }, [nameValue, descriptionValue, urlValue, facilityId, departmentId, clinicId, labelValue]);

  function onRemoveFile(file) {
    const index = files.indexOf(file);
    const newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }

  useEffect(() => {
    if (!visible) {
      reset();
    }
  }, [visible]);

  async function create(values) {
    try {
      message.loading('Uploading resource...', 0);
      const form = new FormData();

      // @TODO: Remove in production, or maybe change it later on
      form.append('name', nameValue);
      form.append('description', descriptionValue);
      form.append('url', urlValue);
      form.append('facility', facilityId);
      form.append('department', departmentId);
      form.append('clinic', clinicId);
      form.append('label', labelValue);
      files.forEach((file) => form.append('file', file, file.name));
      const res = !collateral
        ? await createCollateral(form)
        : await updateCollateral(collateral.id, form);
      message.destroy();
      message.success('Resource successfully uploaded.');
      onSuccess();
      setVisibility(false);
      setDescriptionValue('');
      setNameValue('');
      setUrlValue('');
      setLabelValue('');
    } catch (error) {
      message.error(error.response.data.message);
    }
  }

  function reset() {
    if (formikRef.current) {
      formikRef.current.resetForm();
      setNameValue('');
      setDescriptionValue('');
      setUrlValue('');
      setFacilityId('');
      setDepartmentId('');
      setClinicId('');
      setLabelValue('');
      setFiles([]);
      dispatch(setDepartment([]));
      dispatch(setClinic([]))
    }
  }

  const onChangeFacility = (val) => {
    setFacilityId(val)
    const filteredFacility = facilities.filter((item) => item.id === val);
    dispatch(setDepartment(filteredFacility[0].children));
    setDepartmentId('');
    setClinicId('');
  }

  const onChangeDepartment = (val) => {
    setDepartmentId(val);
    const filteredDepartment = departments.filter((item) => item.id === val);
    dispatch(setClinic(filteredDepartment[0].children));
    setClinicId('');
  }

  return (
    <Modal
      title={
        <Title id="CollateralFormModalTitle"
          level={4}
          className='mb-0'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <PaperClipOutlined className='mr-2' />
          {title}
        </Title>
      }
      closeIcon={(<CloseOutlined id='closeCollatralFormModal' />)}
      visible={visible}
      onCancel={() => setVisibility(false)}
      destroyOnClose
      footer={null}
    >
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={values}
        validationSchema={CreateCollateralSchema}
        onSubmit={(values) => create(values)}
        render={() => (
          <Form layout='vertical' colon={false}>
            <Form.Item label='Name' name='name' className='mb-4'>
              <Input id='collateralFormNameInput' name='name' value={nameValue} onChange={(e) => setNameValue(e.target.value)} />
            </Form.Item>
            <Form.Item label='Description' name='description' className='mb-4'>
              <Input.TextArea id='collateralFormDescriptionInput' name='description' value={descriptionValue} onChange={(e) => setDescriptionValue(e.target.value)} />
            </Form.Item>
            <Form.Item label='Url' name='url' className='mb-4'>
              <Input id='collateralFormUrlInput' name='url' value={urlValue} onChange={(e) => {
                setUrlValue(e.target.value)
                setFiles([]);
              }} />
            </Form.Item>
            <Form.Item
              label='Facility'
              name='facility'
              required
            >
              <Select 
              showSearch
              filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              id='collateralFormSelectFacilityOption' name='facility' value={facilityId}
                onChange={onChangeFacility}
              >
                {facilities &&
                  facilities.length > 0 &&
                  facilities.map((facility, idx) => {
                    return (
                      <Select.Option key={idx} value={facility.id}>
                        {facility.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label='Department'
              name='department'
              required
            >
              <Select 
              showSearch
              filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              id='collateralFormSelectDepartmentOption' name='department' value={departmentId}
                onChange={onChangeDepartment}
              >
                {departments &&
                  departments.length > 0 &&
                  departments.map((department, idx) => {
                    return (
                      <Select.Option key={idx} value={department.id}>
                        {department.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label='Clinic' name='clinic' required>
              <Select
              showSearch
              filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              id='collateralFormSelectClinicOption'
                name='clinic'
                value={clinicId}
                onChange={(val) => setClinicId(val)}
              >
                {clinics &&
                  clinics.length > 0 &&
                  clinics.map((clinic, idx) => {
                    return (
                      <Select.Option key={idx} value={clinic.id}>
                        {clinic.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label='Label' name='label' className='mb-4'>
              <Input name='label' value={labelValue} onChange={(e) => setLabelValue(e.target.value)} />
            </Form.Item>

            <Row className='mb-4'>
              <Col>
                <Upload
                id='collateralFormUploadButton'
                  customRequest={() => {
                    return;
                  }}
                  beforeUpload={(file) => {
                    setUrlValue('')
                    setFiles([file]);
                    return false;
                  }}
                  onRemove={onRemoveFile}
                  className='mb-4'
                  fileList={files}
                >
                  <span className='ant-btn'>
                    <UploadOutlined />
                    <span>Upload</span>
                  </span>
                </Upload>
                {collateral?.file && !files?.length && (
                  <Link onClick={() => window.open(collateral.fileURL)}>
                    {collateral.file.originalFilename}
                  </Link>
                )}
              </Col>
            </Row>
            <Row gutter={4} className='d-flex justify-content-end'>
              <Col>
              <Button id='collateralFormResetButton' type='dashed' title='Reset' onClick={() => reset()}>Reset</Button>
              </Col>
              <Col>
                <SubmitButton  id='collateralFormSaveButton'>{!collateral ? 'Create' : 'Update'}</SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      />
    </Modal>
  );
};

export default CreateCollateralForm;
