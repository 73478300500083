import React, { useState, useRef, useEffect } from "react";
import { Tooltip, Button, Space, Modal, Switch, Row, Col, Tree, Divider, Tag, Typography } from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import {
  deleteLocation,
  getLocationPreferences,
  getAllLocations,
  getLocationTreeDatatable,
  updateLocationPreferences,
} from "api/locations";
import DeleteModal from "components/Modals/DeleteModal";
import CRUDDataTable from "components/DataTable/CRUDDataTable";
import CreateCollateralForm from "../Collateral/components/CreateCollateralForm";
import { Link, useParams } from "react-router-dom";
import LocationForm from "./components/LocationForm";
import ActionButtonsDropdown from "components/ActionButtonsDropdown";
import { isCurrentPermissionKeyPermitted } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { BiBuilding, BiBuildings, RiBuildingLine } from 'react-icons/all';
import { Badge } from 'react-bootstrap';
import { setFilterIsLoading } from "redux/actions/filter";
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';

const DEFAULT_FILTERS = {
    facility: undefined,
    department: undefined,
    clinic: undefined,
};

const Locations = (props) => {
  const { id } = useParams();
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState("");
  const [locationId, setLocationId] = useState();
  const [parentId, setParentId] = useState();
  const [draw, setDraw] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(() => { });
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [preferencesModalVisible, setPreferencesModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [moduleName, setModuleName] = useState("Facility");
  const [isSMS, setIsSMS] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const permissionState = useSelector((state) => state.permissions);
  const [customFilters, setCustomFilters] = useState(Object.keys(DEFAULT_FILTERS).map((paramName) => ({
      paramName,
      value: DEFAULT_FILTERS[paramName]
  })));
  const [search, setSearch] = useState(1);
  const [dataTableParams, setDataTableParams] = useState(DEFAULT_FILTERS);
  const [facilityValue, setFacilityValue] = useState(null);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [clinicValue, setClinicValue] = useState(null);
  const [locationCalled, setLocationCalled] = useState(0);
  const [locations, setLocations] = useState([]);
  const initial = useRef(true);

  const dispatch = useDispatch();

  const setLocationParams = (data) => {
    const locationObj = getLocationValues(data);
    if (locationObj.facility) {
      setFacilityValue(locationObj.facility);
    }
    if (locationObj.department) {
      setDepartmentValue(locationObj.department);
    }
    if (locationObj.clinic) {
      setClinicValue(locationObj.clinic);
    }
    let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => {
      if (paramName === 'facility' || paramName === 'department' || paramName === 'clinic') {
        return ({
          paramName,
          value: locationObj[paramName],
        })
      }
      else {
        return ({
          paramName,
          value: dataTableParams[paramName],
        })
      }
    });
    setDataTableParams((params) => ({
      ...params,
      facility: locationObj['facility'],
      department: locationObj['department'],
      clinic: locationObj['clinic']
    }));
    setCustomFilters(updatedCustomFilters);
    setLocationCalled(1);
  }
  
  const fetchLocationsData = async () => {
    let res = await getAllLocations();
    setLocations(res.data.data);
    setLocationParams(res.data.data);
  }

  const criteriaNotSearched = () => {
    if(!initial.current) {
      for (let filter of customFilters) {
        if (dataTableParams[filter.paramName] !== filter.value) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    dispatch(setFilterIsLoading(true));
    fetchLocationsData();
  }, []);

  useEffect(() => {
    let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => ({
      paramName,
      value: dataTableParams[paramName],
    }))
    setCustomFilters(updatedCustomFilters)
  }, [search]);

  const getLocationTypeName = (level) => {
    if(level) {
      if(level === 1) {
        return 'Department';
      }
      else if(level === 2) {
        return 'CLinic';
      }
      else {
        return 'Facility';
      }
    } 
    return 'Facility';
  } 

  const cols = [
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value, record, index) => {
        let statusBadge = null;
        let locationTypeTag = null;

        locationTypeTag = generateLocationTypeTag(record?.level);

        if (record?.status) {
          if (record?.status.toLowerCase() === 'active') {
            statusBadge = <Badge variant='success'>Active</Badge>
          }

          if (record?.status.toLowerCase() === 'inactive') {
            statusBadge = <Badge variant='danger'>Inactive</Badge>
          }
        }

        return <>
          {locationTypeTag} {record?.name} {statusBadge}
        </>;
      }
      /*    render: (text, record, value) => {
              return record?.childrenAllowed
                  ? <Link to={`/locations/${record?.id}`}>{text}</Link>
                  : text
          }*/
    },
    {
      title: <strong>Email | ContactNumber</strong>,
      dataIndex: 'contact',
      key: 'contact',
      sorter: false,
      render: (text, record, value) => {
        let email = record?.email ? <a href={`mailto:${record?.email}`}>{record?.email}</a> : null;
        let contactNumber = record?.contactNumber ? `${record?.contactNumber}` : null;
        return <>
          {email} {email && contactNumber ? ' | ' : null} {contactNumber}
        </>;
      }
    },
    {
      title: isCurrentPermissionKeyPermitted(
        "locations",
        permissionState
      ) ? (
        <strong>Options</strong>
      ) : null,
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: (text, record, value) => {
        const locationActionOptions = [
          {
            id: 'LocationAddOption',
            permissionKey: record.level !== 2 ? "locations:new" : "",
            label: `Add ${record.level === 0 ? "Department" : "Clinic"}`,
            icon: <PlusCircleOutlined />,
            onClick: () => {
              setModuleName(record.level === 0 ? "Department" : record.level === 1 ? "Clinic" : "Facility");
              setLocationId(text);
              setSelectedLocation(() => { });
              setEditModalVisible(true);
            }
          },
          {
            id: 'LocationUpdateOption',
            permissionKey: "locations:options:update",
            label: `Update ${record.level === 1 ? "Department" : record.level === 2 ? "Clinic" : "Facility"}`,
            icon: <EditOutlined />,
            onClick: () => {
              setModuleName(record.level === 1 ? "Department" : record.level === 2 ? "Clinic" : "Facility");
              setLocationId(text);
              setSelectedLocation(record);
              setEditModalVisible(true);
            },
          },
          {
            id: 'LocationDeleteOption',
            permissionKey: "locations:options:delete",
            label: `Delete ${record.level === 1 ? "Department" : record.level === 2 ? "Clinic" : "Facility"}`,
            icon: <DeleteOutlined />,
            onClick: () => {
              setModuleName(record.level === 1 ? "Department" : record.level === 2 ? "Clinic" : "Facility");
              setDeleteModalVisibility(text);
              setLocationId(text);
            },
          },
        ];

        const permittedLocationActionOptions = locationActionOptions.filter(
          (locationActionOption) =>
            isCurrentPermissionKeyPermitted(
              locationActionOption.permissionKey,
              permissionState
            )
        );
        let buttons = () => {
          return (
            <>
              {" "}
              {record?.children?.length > 0 ? (
                ""
              ) : (
                <Tooltip title="Set Preferences">
                  <Button
                    id='locationselectButton'
                    type="primary"
                    onClick={() => {
                      setLocationId(text);
                      getPreferences(text);
                      setParentId(record.parentId);
                      setModalMessage("Set Preferences");
                      setIsParent(record?.parentId ? false : true);
                    }}
                  >
                    <MailOutlineIcon fontSize="small" />
                  </Button>
                </Tooltip>
              )}
            </>
          );
        };

        return <Row>
          {isCurrentPermissionKeyPermitted(
            "locations:options",
            permissionState
          ) && <ActionButtonsDropdown tabName={getLocationTypeName(record?.level)} menus={permittedLocationActionOptions} />}
          {isCurrentPermissionKeyPermitted(
            "locations:preferences",
            permissionState
          ) && <Space>{buttons()}</Space>}
        </Row>
      },
    },
  ];

  const generateLocationTypeTag = (level) => {
    switch (level) {
      case 0: return <Tag color={'#0D47A1'}><BiBuilding /> Facility</Tag>
      case 1: return <Tag color={'#039BE5'}><BiBuildings /> Department</Tag>
      case 2: return <Tag color={'#00BCD4'}><RiBuildingLine /> Clinic</Tag>
    }
  }

  const getPreferences = async (id) => {
    let res = await getLocationPreferences(id);
    setIsEmail(res.data.data.emailEnabled);
    setIsSMS(res.data.data.smsEnabled);
    setPreferencesModalVisible(true);
    return res;
  };

  const updatePreferences = async () => {
    let data = {};
    if (isParent) {
      data = {
        id: locationId,
        emailEnabled: isEmail,
        smsEnabled: isSMS,
        children: [],
      };
    } else {
      data = {
        id: parentId,
        isEmailEnabled: false,
        isSMSEnabled: false,
        children: [{
          id: locationId,
          emailEnabled: isEmail,
          smsEnabled: isSMS,
        }],
      };
    }
    let res = await updateLocationPreferences(data);
    setParentId(0);
    setPreferencesModalVisible(false);
    return res;
  };

  return (
    <>
      <Modal
        visible={preferencesModalVisible}
        onCancel={() => setPreferencesModalVisible(false)}
        title={modalMessage}
        okText="Save"
        onOk={() => {
          updatePreferences(locationId);
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className='font-weight-bolder'>SMS SENDING ENABLED</h4>
            <small>Check if you want to set the SMS Preferences.</small>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-right'>
            <Switch
              className='pull-right'
              style={{
                marginTop: '10px'
              }}
              checked={isSMS}
              onChange={(value) => {
                setIsSMS(value);
              }}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4 className='font-weight-bolder'>EMAIL SENDING ENABLED</h4>
            <small>Check if you want to set the Email Preferences.</small>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='text-right'>
            <Switch
              className='pull-right'
              style={{
                marginTop: '10px'
              }}
              checked={isEmail}
              onChange={(value) => {
                setIsEmail(value);
              }}
            />
          </Col>
        </Row>
      </Modal>
      <DeleteModal
        entity={moduleName}
        visible={isDeleteModalVisible}
        setVisibility={setDeleteModalVisibility}
        entityId={locationId}
        deleteFunction={deleteLocation}
        onSuccess={() => setDraw(draw + 1)}
      />
      <LocationForm
        onSuccess={() => setDraw(draw + 1)}
        visible={isEditModalVisible}
        setVisibility={setEditModalVisible}
        locationId={locationId}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        moduleName={moduleName}
        parentId={locationId}
      />
      <Row>
        <Col lg={20} md={20}>
          <LocationSelect
            locationData={locations}
            facilityValue={facilityValue}
            departmentValue={departmentValue}
            clinicValue={clinicValue}
            showSearchValue={true}
            id="queueHospitalSelect"
            width='275px'
            direction='horizontal'
            onChange={(value, isDepartment, action, locationObj) => {
              initial.current = false;
              if (locationObj === null) {
                if (action === 1) {
                  setDataTableParams((params) => ({
                    ...params,
                    facility: value,
                    department: undefined,
                    clinic: undefined
                  }));
                }
                if (action === 2) {
                  setDataTableParams((params) => ({
                    ...params,
                    department: value,
                    clinic: undefined
                  }));
                }
                if (action === 3) {
                  setDataTableParams((params) => ({
                    ...params,
                    clinic: value,
                  }));
                }
              }
              else {
                setDataTableParams((params) => ({
                  ...params,
                  facility: locationObj['facility'],
                  department: locationObj['department'],
                  clinic: locationObj['clinic']
                }));
              }
            }
            }
            isClinicVisible={true}
            showAll={true}
          />
        </Col>
        <Col
          lg={4}
          md={4}
          style={{ marginBlock: 'auto' }}
        >
          <Typography.Text>&nbsp;</Typography.Text>
          <Button
            id="appointmentsSearch"
            type={`${criteriaNotSearched() ? 'primary' : '' }`}
            className={`w-100 ${!criteriaNotSearched() ? 'not-searched': ''}`}
            onClick={() => setSearch(current => current + 1)}
          >
            Search
          </Button>
        </Col>
      </Row>
      <br />
      {locationCalled > 0 ? <CRUDDataTable
        draw={draw}
        entity="Facility"
        fetchDataFunction={async (params) => {
          // if (id!==undefined)
          //     return await getLocationChildren(id);
          let res = await getLocationTreeDatatable(params);
          addKeys(res.data.data, 0, params);
          return res;
        }}
        newButtonClickCallback={() => {
          setSelectedLocation({});
          setModuleName('Facility');
        }}
        createModal={<LocationForm moduleName={moduleName} />}
        columns={cols}
        isButtonShown={isCurrentPermissionKeyPermitted(
          "locations:new",
          permissionState
        )}
        customFilters={customFilters}
        rowKey="id" // this SUPPOSEDLY should be the solution, but it doesn't work.
      /> : <></>}
    </>
  );
};

const setItemLevel = (item, params) => {
  item.map(p => {
    if (!p.level) {
      if (params?.department) {
        p.level = 2;
      }
      else {
        p.level = 1
      }
    }
    if (p.children.length > 0) {
      p.children.map(x => {
        x.level = 2;
      })
    }
  });
}

/**
 * Reason for this is because upon further investigation,
 * the expandable tree in ant table is actually reliant on
 * them having "keys" though i'm not sure as to how to make
 * it reliant on the provided id instead, for now this is a temporary hack
 * @param entry
 * @param i
 */
const addKeys = (entry, i = 0, params) => {
  entry.forEach((item, _index) => {
    item.key = `${item.id}-${i++}`;
    if (!item.parentId) {
      item.level = 0;
    }
    if (item.children.length > 0) {
      if (params?.department) {
        item.level = 1;
      }
      setItemLevel(item.children, params);
      addKeys(item.children, i, params);
    }
    else if (params?.clinic) {
      item.level = 2;
      delete item.children;
    }
    else {
      delete item.children;
    }
  });
};

export default Locations;
