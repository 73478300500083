import {
    TOGGLE_SIDEBAR_VISIBILITY,
    SET_STATUS_MODAL_PROPS,
    SET_MESSAGE_MODAL_VISIBILITY,
    SET_COLLATERAL_MODAL_VISIBILITY,
    SET_NOTE_MODAL_VISIBILITY,
    ACTIVE_APPOINTMENT_ID,
    SET_VIEW_COLLATERAL_MODAL_VISIBILITY,
    SET_TOPBAR_VISIBILITY,
    SET_COLLATERAL_DETAILS_MODAL_VISIBILITY,
    SET_HELPER_ITEM_VISIBILITY, SET_HELPER_EDIT_ITEM,
    SET_BREADCRUMBS_TEXT,
    SET_FORM_BUILDER_EVENT_SELECTING,
    SET_HOSPITAL_LOCATIONS
} from 'redux/actions/types/ui';
import {
    SET_ACTIVE_COLLATERAL,
    SET_COLLATERAL_APPOINTMENT_ID,
    SET_COLLATERAL_DELETE_MODAL_VISIBILITY,
    SET_FORM_BUILDER_ACTIVE_TAB
} from "../actions/types/ui";

const INITIAL_STATE = {
    isSidebarVisible: true,
    isTopbarVisible: true,
    isStatusModalVisible: false,
    isMessageModalVisible: false,
    isCollateralModalVisible: false,
    isNoteModalVisible: false,
    activeAppointmentId: 0,
    statusModalProps: {
        id: 0,
        isVisible: false
    },
    currentBreadcrumbDisplay: '',
    formBuilderActiveTab: '1',
    isFormBuilderEventsSelecting: false,
    locations: []
};

const uiReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case TOGGLE_SIDEBAR_VISIBILITY: {
            const isSidebarVisible = (state.isSidebarVisible ? false : true);

            return {
                ...state,
                isSidebarVisible,
            }
        }

        case SET_TOPBAR_VISIBILITY: {
            return {
                ...state,
                isTopbarVisible: action.isTopbarVisible,
            }
        }

        case SET_STATUS_MODAL_PROPS: {
            return {
                ...state,
                statusModalProps: action.statusModalProps,
            }
        }

        case SET_MESSAGE_MODAL_VISIBILITY: {
            return {
                ...state,
                isMessageModalVisible: action.isMessageModalVisible,
            }
        }

        case SET_COLLATERAL_MODAL_VISIBILITY: {
            return {
                ...state,
                isCollateralModalVisible: action.isCollateralModalVisible,
            }
        }

        case SET_NOTE_MODAL_VISIBILITY: {
            return {
                ...state,
                isNoteModalVisible: action.isNoteModalVisible,
            }
        }

        case ACTIVE_APPOINTMENT_ID: {
            return {
                ...state,
                activeAppointmentId: action.activeAppointmentId,
            }
        }

        case SET_COLLATERAL_APPOINTMENT_ID: {
            return {
                ...state,
                activeCollateralId: action.activeCollateralId,
            }
        }

        case SET_COLLATERAL_DETAILS_MODAL_VISIBILITY: {
            return {
                ...state,
                isCollateralDetailsModalVisible: action.isCollateralDetailsModalVisible,
            }
        }

        case SET_ACTIVE_COLLATERAL: {
            return {
                ...state,
                activeCollateral: action.activeCollateral
            }
        }

        case SET_COLLATERAL_DELETE_MODAL_VISIBILITY: {
            return {
                ...state,
                isCollateralDeleteModalVisible: action.isCollateralDeleteModalVisible
            }
        }

        case SET_HELPER_ITEM_VISIBILITY: {
            return {
                ...state,
                isHelperEditVisible: action.isHelperEditVisible
            }
        }

        case SET_HELPER_EDIT_ITEM: {
            return {
                ...state,
                helperEditItem: action.helperEditItem
            }
        }

        case SET_BREADCRUMBS_TEXT: {
            return {
                ...state,
                currentBreadcrumbDisplay: action.currentBreadcrumbDisplay
            }
        }

        case SET_FORM_BUILDER_ACTIVE_TAB: {
            return {
                ...state,
                formBuilderActiveTab: action.tab
            }
        }

        case SET_FORM_BUILDER_EVENT_SELECTING: {
            return {
                ...state,
                isFormBuilderEventsSelecting: action.isSelecting
            }
        }

        case SET_HOSPITAL_LOCATIONS: {
            return {
                ...state,
                locations: action.locations
            }
        }

        default:
            return state;
    }
};

export default uiReducer;
