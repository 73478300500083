import React, { useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Footer from 'components/Layout/Footer';

import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumbsText } from 'redux/actions/ui';
import { useHistory } from 'react-router-dom';

const STORAGE_KEY = "current_appointment_record";

function BasePage(props) {
    const { pageName, children } = props;
    const history = useHistory();

    const uiState = useSelector(state => state.ui);
    const dispatch = useDispatch();

    useEffect(()=>{
        return () => {
            dispatch(setBreadcrumbsText(''));
        }
    }, [dispatch]);

    // useEffect(() => {
    //     const currentAppointment = localStorage.getItem(STORAGE_KEY);
    //     if (currentAppointment && history.location.pathname !== "/checked-in-patient") {
    //         history.push('/checked-in-patient');
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [localStorage.getItem(STORAGE_KEY), history.location])

    return (
        <>
            <div id='basePage'>
                {uiState.isTopbarVisible && (
                    <>
                        {/* <Breadcrumbs /> */}
                        <h1 className='fivef-pageTitle'>
                            {/* {pageName} */}
                        </h1>
                    </>
                )}
                {children}
                <Footer />
            </div>
        </>
    );
}

export default BasePage;