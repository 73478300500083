import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditorField = ({ fieldId, systemPropertyField, setData, setStaging, systemPropertiesData }) => {

    const [editorState, setEditorState] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (systemPropertyField?.value) {
            const contentBlock = htmlToDraft(systemPropertyField?.value);
            let convertedState;
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                );
                convertedState = EditorState.createWithContent(contentState);
                setEditorState(convertedState);
            }
        }
    }, [])

    return (
        <Editor
            editorState={editorState}
            editorStyle={{
                border: '1px solid #F1F1F1',
                minHeight: '30vh',
                height: '100%',
                padding: '0 10px',
            }}
            toolbar={{
              emoji: {
                emojis: [
                  "😀",
                  "😁",
                  "😂",
                  "😃",
                  "😉",
                  "😋",
                  "😎",
                  "😍",
                  "😗",
                  "🤗",
                  "🤔",
                  "😣",
                  "😫",
                  "😴",
                  "😌",
                  "🤓",
                  "😛",
                  "😜",
                  "😠",
                  "😇",
                  "😷",
                  "😈",
                  "👻",
                  "😺",
                  "😸",
                  "😹",
                  "😻",
                  "😼",
                  "😽",
                  "🙀",
                  "🙈",
                  "🙉",
                  "🙊",
                  "👼",
                  "👮",
                  "🕵",
                  "💂",
                  "👳",
                  "🎅",
                  "👸",
                  "👰",
                  "👲",
                  "🙍",
                  "🙇",
                  "🚶",
                  "🏃",
                  "💃",
                  "⛷",
                  "🏂",
                  "🏌",
                  "🏄",
                  "🚣",
                  "🏊",
                  "⛹",
                  "🏋",
                  "🚴",
                  "👫",
                  "💪",
                  "👈",
                  "👉",
                  "👆",
                  "🖕",
                  "👇",
                  "🖖",
                  "🤘",
                  "🖐",
                  "👌",
                  "👍",
                  "👎",
                  "✊",
                  "👊",
                  "👏",
                  "🙌",
                  "🙏",
                  "🐵",
                  "🐶",
                  "🐇",
                  "🐥",
                  "🐸",
                  "🐌",
                  "🐛",
                  "🐜",
                  "🐝",
                  "🍉",
                  "🍄",
                  "🍔",
                  "🍤",
                  "🍨",
                  "🍪",
                  "🎂",
                  "🍰",
                  "🍾",
                  "🍷",
                  "🍸",
                  "🍺",
                  "🌍",
                  "🚑",
                  "⏰",
                  "🌙",
                  "🌝",
                  "🌞",
                  "⭐",
                  "🌟",
                  "🌠",
                  "🌨",
                  "🌩",
                  "⛄",
                  "🔥",
                  "🎄",
                  "🎈",
                  "🎉",
                  "🎊",
                  "🎁",
                  "🎗",
                  "🏀",
                  "🏈",
                  "🎲",
                  "🔇",
                  "🔈",
                  "📣",
                  "🔔",
                  "🎵",
                  "🎷",
                  "💰",
                  "🖊",
                  "📅",
                  "✅",
                  "❎",
                  "💯",
                  "✉️"
                ]
              },
                fontFamily: {
                    inDropdown: false,
                },
            }}
            onEditorStateChange={(editorState) => {
                setEditorState(editorState);
                const fieldValue = draftToHtml(convertToRaw(editorState?.getCurrentContent()));

                const statePath = fieldId
                    .concat('-', systemPropertyField.name)
                    .split('-');
                const newSystemProperty = Object.assign({}, systemPropertiesData);

                const newState = setData(
                    statePath,
                    newSystemProperty,
                    fieldValue
                );

                dispatch(setStaging(newState));
            }}
            placeholder={'Enclose text with {{}} to make it as variable.'}
        />
    )
}

export default EditorField
