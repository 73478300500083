import { BASE_API_URL } from 'api/config';
import { createAxios } from './axios';

const queueEntriesApi = createAxios({
    baseURL: BASE_API_URL + '/queue-entries',
});

export const getQueueEntries = (params) => {
    return queueEntriesApi.get('', {
        params: {
            ...params,
            queueId: params.queue,
            status: params.status,
            grouped: true,
            id: params.id
        }
    });
};

export const changeQueue = (entryId) => {
    return queueEntriesApi.post(`/${entryId}/change-queue`);
};

export const updateStatus = (entryId, status, data) => {
    return queueEntriesApi.post(`/${entryId}/${status}`, data);
};

export const deleteQueueEntryFlag = async (id,flag) => {
    return await queueEntriesApi.delete(id+'/flags/'+flag);
}