import React from 'react';
import {POWERED_BY_LOGO} from "../../api/config";

const Footer = () => {
  return (
    <>
      <div className='text-right' style={{position: 'absolute', right: '12px', bottom: '12px'}}>
        <small>
          <a href='https://fivefaces.com.au' target="_blank">
            <img src={POWERED_BY_LOGO}
                 alt='Five Faces Logo'
                 style={{height: '30px', width: 'auto'}}
            />
          </a>
        </small>
      </div>
    </>
  );
};

export default Footer;