import * as Msal from "msal";
import {BASE_URL, msalConfig} from "./config";
import {saveAuth} from "../utils/helpers";
import {store} from "../index";
import {setTokens} from "../redux/actions/auth";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only


const msal = new Msal.UserAgentApplication({
    auth: {
        clientId: msalConfig.appId,
        authority: `https://login.microsoftonline.com/${msalConfig.tenantId}`,
        redirectUri: BASE_URL
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    }
});

function login() {
    clearCookies();
    msal.loginRedirect({ scopes: msalConfig.scopes, } );
}

function logout() {
    console.log('logging out')
    msal.logout();
}
const clearCookies = () => {
    console.log('clearCookies')
    // clear all msal related data in local storage
    for(let i = 0 ; i < localStorage.length; ++ i) {
        if(localStorage.key(i).startsWith("msal")
            || localStorage.key(i).indexOf(msalConfig.appId) >= 0) {
            localStorage.removeItem(localStorage.key(i));
        }
    }

    // // clear all cookies on this domain
    document.cookie.split(';')
      .filter(cookie => {
          return cookie.indexOf("msal") > -1
      })
      .forEach(cookie => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    });
}
function validate() {
    return msal.ssoSilent({ scopes: msalConfig.scopes, sid: msalConfig.appId });
}

const refreshTokenMSAL = async() =>  { 
    const response = await msal.acquireTokenSilent({ scopes: [msalConfig.appId], account: msal.getAccount() });
    if (expireSoon(response)) {
        forceRefreshToken(response);
    }
    return {
        accessToken: response.accessToken,
        idToken: response.idToken?.rawIdToken
    };
}

const expireSoon = (response) => {
    const someMinutes = 25;
    const someMinutesInMillis = someMinutes * 60 * 1000;
    const minutesToTokenExpiry = (new Date(response.idToken.expiration * 1000 - new Date().getTime()))/1000/60;
    console.info("Token expires in: " + minutesToTokenExpiry.toFixed(1) + " mins",
      "Will refresh in: " + (minutesToTokenExpiry - someMinutes).toFixed(1) + " mins")
    const someMinutesBeforeExpiry = new Date(response.idToken.expiration * 1000 - someMinutesInMillis).getTime();
    const nowTime = new Date().getTime();
    return nowTime >= someMinutesBeforeExpiry;
}

const forceRefreshToken = (response) => {
    const request = {
        scopes: [msalConfig.appId],
        account: msal.getAccount(),
        forceRefresh: true
    };
    msal.acquireTokenSilent(request).then(val => {
        console.info("Got some token with forceRefresh")
        const access_token = val?.idToken?.rawIdToken;
        const refresh_token = 'test';
        const expiryDate = val?.expiresOn;
        const isUserAuthenticated = true
        saveAuth(access_token, refresh_token, expiryDate);

        store.dispatch(setTokens({
            access_token,
            refresh_token,
            isUserAuthenticated,
        }));
    }).catch(error => {
        console.info("Failed to get new token", error)
        msal.acquireTokenRedirect(request)
    })
}

export { msal, login, logout, validate, refreshTokenMSAL };