import { useState, useEffect, useRef } from 'react';
import Aux from 'components/hoc/AuxFunction';
import { Card, message, Tag, Typography, Select, Space, Button, Input, Statistic, Badge, Spin, Table, Divider, Tooltip } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CRUDDataTable from 'components/DataTable/CRUDDataTable';
import { getQueues, getQueueRoomById, loginToRoomById, logoutOfRoomById } from 'api/queue';
import RoomSelectionModal from 'components/Modals/RoomSelectionModal';
import { AiOutlineLogin, AiOutlineQuestionCircle } from 'react-icons/ai';
import { CgChevronDoubleUpO } from 'react-icons/all';
import { changeQueue, getQueueEntries, updateStatus } from 'api/queueEntries';
import { QueueEntryStatusTypes } from 'utils/enums'
import { isCurrentPermissionKeyPermitted, permissifyTableColumns } from 'utils';
import CalculateTime from './CalculateTime';
import { getAllSettings } from 'api/systemSettings';
import {
    getAppointmentFlagMessageStatus,
    getQueueEntryFlagMessageStatus,
    onAppointmentFlagRemove,
    onQueueEntryFlagRemove
} from 'utils/helpers';
import { getAppointmentTypes } from 'api/appointments';
import { Link } from 'react-router-dom';
import { CheckedInPatientStatusTypes } from 'utils/enums';
import { getAllLocations } from 'api/locations';
import confirm from 'antd/lib/modal/confirm';
import SockJsClient from 'react-stomp';
import { BASE_WS_URL } from 'api/config';
import CloseableTag from "../../components/ClosableTag";
import CallPatientInfoModal from "./components/CallPatientInfoModal.js";
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';
import "styles/CheckedInPatient.scss";
import ActionMenuDropDown from 'components/ActionMenuDropdown';

const { Text } = Typography;
const { Option } = Select;

const DEFAULT_FILTERS = {
    locationId: undefined,
    status: '',
    appointmentType: '',
    patientName: '',
    contactNumber: '',
    mrn: '',
    attendingClinicianName: '',
    secondaryClinicianName: ''
};

const STORAGE_KEY = "queue_entry_id";

const CheckedInPatientList = (props) => {
    const permissionState = useSelector(state => state.permissions);
    const userState = useSelector(state => state.user);
    const sysPropertiesState = useSelector((state) => state.sysProperties);
    const [draw, setDraw] = useState(0);
    const [isActionPerform, setActionPerform] = useState(false);
    const [queueType, setQueueType] = useState([]);
    // const queueRef = useRef([]);
    const [roomModalVisible, setRoomModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [locations, setLocations] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [clinicList, setClinicList] = useState([]);
    const [roomsList, setRoomsList] = useState([]);
    const [activeRoom, setActiveRoom] = useState(null);
    const [activeFacility, setActiveFacility] = useState(null);
    const [activeDepartment, setActiveDepartment] = useState(null);
    const [isCurrentLoginUserIntoRoom, setCurrentLoggedInUserIntoRoom] = useState(false);
    const [isLoginButtonLoading, setLoginButtonLoading] = useState(false);
    const [currentQueueUser, setCurrentQueueUser] = useState('N/A');
    const [activeClinic, setActiveClinic] = useState(null);
    const waitTimeInterval = useRef(1000);
    const timeInConsultInterval = useRef(1000);
    const currentLoggedInRoom = useRef(0);
    const [openCallPatient, setOpenCallPatient] = useState(false);
    const [facilityValue, setFacilityValue] = useState(null);
    const [departmentValue, setDepartmentValue] = useState(null);
    const [clinicValue, setClinicValue] = useState(null);
    const [locationCalled, setLocationCalled] = useState(0);
    const initial = useRef(true);
    const [spin, setSpin] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const appointmentsType = useRef([]);
    const [customFilters, setCustomFilters] = useState(Object.keys(DEFAULT_FILTERS).map((paramName) => ({
        paramName,
        value: DEFAULT_FILTERS[paramName]
    })));
    const [search, setSearch] = useState(1);
    const [dataTableParams, setDataTableParams] = useState(DEFAULT_FILTERS);
    let sockJSRef = null;

    useEffect(() => {
        if (sockJSRef) {
            sockJSRef.connect();
        }
    }, [sockJSRef])



    const fetchTimeInterval = async () => {
        const response = await getAllSettings('TENANT');
        if (response?.data?.TENANT?.length > 0) {
            const waitTime = response.data.TENANT.find(tenant => tenant.name === 'WAIT_TIME_INTERVAL');
            const timeInConsult = response.data.TENANT.find(tenant => tenant.name === 'TIME_IN_CONSULT_INTERVAL');
            waitTimeInterval.current = waitTime?.value ? Number(waitTime?.value) * 1000 : 1000;
            timeInConsultInterval.current = timeInConsult?.value ? Number(timeInConsult?.value) * 1000 : 1000;
        }
    }

    const fetchAppointmentTypes = async () => {
        let responseAppType = await getAppointmentTypes();
        appointmentsType.current = responseAppType?.data?.data ?? [];
    }

    const setLocationParams = (data) => {
        const locationObj = getLocationValues(data);

        let locationId = '';
        if (locationObj.facility) {
            setFacilityValue(locationObj.facility);
            locationId = locationObj.facility;
        }
        if (locationObj.department) {
            setDepartmentValue(locationObj.department);
            locationId = locationObj.department;
        }
        if (locationObj.clinic) {
            setClinicValue(locationObj.clinic);
            locationId = locationObj.clinic;
        }
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) =>  {
        if(paramName === 'locationId') {
            return ({
            paramName,
            value: locationId,
            })
        }
        else {
            return ({
            paramName,
            value: dataTableParams[paramName],
            })
        }
        });
        setDataTableParams((params) => ({
            ...params,
            locationId: locationId,
        }));
        setCustomFilters(updatedCustomFilters);
        setLocationCalled(1);
    }

    const fetchLocations = async () => {
        try {
            const response = await getAllLocations();

            setLocations(response.data.data);
            setLocationParams(response.data.data);
        } catch (err) {
            message.error('Error occured while getting all the locations');
        }
    }

    const generateUserName = (user) => {
        return `${user.firstName} ${user.lastName}`;
    }

    const getRoomByClinicId = async (id) => {
        try {
            const response = await getQueues({
                clinicId: id
            });
            if (response.data?.data && response.data?.data.length > 0) {
                if (response.data.data[0].rooms && response.data.data[0].rooms.length > 0) {
                    setRoomsList(response.data?.data[0].rooms);
                } else {
                    setRoomsList([]);
                    message.error('No rooms are configured for this queue');
                }
            } else {
                setRoomsList([]);
                message.error('Queue not configured configured for this clinic');
            }
        } catch (error) {
            setRoomsList([]);
            message.error('An error occurred fetching rooms.');
        }
    }

    const fetchQueueRoomById = async (id) => {
        try {
            const response = await getQueueRoomById(id);

            setActiveRoom(response.data);

            if (response.data?.loggedInUser) {
                setCurrentQueueUser(generateUserName(response.data?.loggedInUser));
                currentLoggedInRoom.current = response.data?.id;
            } else {
                setCurrentQueueUser('N/A');
            }

            if (response.data?.loggedInUser && generateUserName(response.data?.loggedInUser) === generateUserName(userState.details)) {
                setCurrentLoggedInUserIntoRoom(true);
            } else {
                setCurrentLoggedInUserIntoRoom(false);
            }
        } catch (err) {
            message.error('Error occured while getting the active room details.');
        }
    }

    const onFacilityChanged = (value) => {
        const activeFacility = locations.find(location => location.id === value);

        setActiveFacility(value);
        setActiveDepartment(null);
        setActiveClinic(null);
        setDepartmentList([]);
        setClinicList([]);
        setRoomsList([]);
        clearLogInUserSession();
        setDepartmentList(activeFacility.children);
    }

    const onDepartmentChanged = (value) => {
        const activeDepartment = departmentList.find(department => department.id === value);

        setActiveDepartment(value);
        setActiveClinic(null);
        setClinicList([]);
        setRoomsList([]);
        clearLogInUserSession();
        setClinicList(activeDepartment.children);
    }

    const onClinicChanged = (value) => {
        setRoomsList([]);
        clearLogInUserSession();
        setActiveClinic(value);
        getRoomByClinicId(value);
    }

    const onRoomsChanged = async (value) => {
        await fetchQueueRoomById(value);
    }

    const onLoginClick = async () => {
        if (!activeRoom) {
            message.error('Please select a room to log in.');
            return;
        }

        setLoginButtonLoading(true);
        try {
            if (activeRoom.loggedInUser
                && activeRoom.loggedInUser !== generateUserName(userState.details)) {
                confirm({
                    title: 'Override logged in user?',
                    icon: <AiOutlineQuestionCircle />,
                    content: `${generateUserName(activeRoom.loggedInUser)} is currently logged in to this room, override?`,
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: async () => {
                        await loginToRoomById(activeRoom.id, true);
                        setCurrentQueueUser(generateUserName(userState.details));
                        currentLoggedInRoom.current = activeRoom.id
                        setCurrentLoggedInUserIntoRoom(true);
                        getRoomByClinicId(activeClinic);
                    }
                })
            } else {
                await loginToRoomById(activeRoom.id);
                setCurrentQueueUser(generateUserName(userState.details));
                currentLoggedInRoom.current = activeRoom.id
                setCurrentLoggedInUserIntoRoom(true);
                getRoomByClinicId(activeClinic);
            }
        } catch (err) {
            message.error('Error occured while trying to log you in to the room.');
        }
        setLoginButtonLoading(false);
    }

    const clearLogInUserSession = () => {
        setCurrentLoggedInUserIntoRoom(false);
        setCurrentQueueUser('N/A');
        setActiveRoom(null);
    }

    const onLogoutClick = async () => {
        setLoginButtonLoading(true);
        try {
            let currentActiveRoom = activeRoom;

            await logoutOfRoomById(activeRoom.id);

            message.success('You have been logged out of the room.');
            clearLogInUserSession();
            delete currentActiveRoom.loggedInUser;
            setActiveRoom(currentActiveRoom);
            currentLoggedInRoom.current = 0;

            getRoomByClinicId(activeClinic);
        } catch (err) {
            message.error('Error occured while trying to log you out.');
        }
        setLoginButtonLoading(false);
    }

    useEffect(() => {
        fetchTimeInterval();
        (async () => {
            let responseQueueType = await getQueues();
            // setQueueType(responseQueueType.data.data);
            let queueTypes = [
                {
                    id: '',
                    clinic: { name: 'ALL' }
                }
            ];
            responseQueueType.data.data.map((f) => {
                queueTypes = [
                    ...queueTypes,
                    f
                ]
            });

            setQueueType(queueTypes);
        })();
        fetchAppointmentTypes();
        fetchLocations();
    }, []);

    useEffect(() => {
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => ({
            paramName,
            value: dataTableParams[paramName],
        }))
        setCustomFilters(updatedCustomFilters)
    }, [search])

    const criteriaNotSearched = () => {
        if(!initial.current) {
            for (let filter of customFilters) {
                if (dataTableParams[filter.paramName] !== filter.value) {
                    return true;
                }
            }
        }
        return false;
    }

    const updateAppointmentConsultStatus = async (id, statusValue, text, data) => {
        try {
            if (statusValue) {
                const response = await updateStatus(id, statusValue, data);
                setActionPerform(true);
                setDraw((draw) => draw + 1)
                message.success(`Successfully changed status to ${text}.`);
                return response;
            }
        } catch {
            return null
        }
    }
    const attemptChangeQueue = async (record) => {
        try {
            const response = await changeQueue(record.id);
            setDraw((draw) => draw + 1)
            setActionPerform(true);
            setOpenCallPatient(false);
            message.success(`Successfully changed queue to ${record.appointment.location.name}.`);
        } catch (error) {
            message.destroy();
            message.error(error.response.data.message);
        }
    }

    const allowedActions = function (record) {
        let allowed = new Set();
        if (record.queue?.clinic?.reception) {
            if (record.status === 'CHECKED_IN') {
                allowed.add('CALL')
                if (record.appointment) {
                    allowed.add('CHANGE_QUEUE')
                }
                allowed.add('CHECKED_OUT')
            } else if (record.status === 'START_CONSULT') {
                // if (record.appointment) {
                //     allowed.add('CHANGE_QUEUE')
                // }
                allowed.add('CHECKED_OUT')
            }
            // else if (record.status === 'START_CONSULT') {
            //     allowed.add('CHECKED_OUT')
            // }
        } else {
            if (record.status === 'CHECKED_IN') {
                allowed.add('CALL_TO_ROOM')
                allowed.add('CHECKED_OUT')
            } else if (record.status === 'START_CONSULT') {
                // allowed.add('START_CONSULT')
                allowed.add('CHECKED_OUT')
            }
            // else if (record.status === 'START_CONSULT') {
            //     allowed.add('CHECKED_OUT')
            // }
        }
        if (record.status !== 'CHECKED_IN' && record.queue) {
            allowed.add('RETURN_QUEUE')
        }
        return [...allowed];
    }

    const changeToCalledAppointment = async (roomId) => {
        setRoomModalVisible(false);
        const resp = await updateAppointmentConsultStatus(currentRecord.id, 'START_CONSULT', 'In Consult', roomId);
        if (resp?.status === 200){
            localStorage.setItem(STORAGE_KEY, currentRecord.id);
            setOpenCallPatient(true);
        }
    }

    const getQueueEntryDetail = async (queueEntryId) => {
        setSpin(true);
        try {
            const response = await getQueueEntries({ id: queueEntryId });
            const record = response.data.data[0];
            if (record && record.status === "START_CONSULT") {
                setCurrentRecord(response.data.data[0]);
                setOpenCallPatient(true);
                return;
            }
            localStorage.removeItem(STORAGE_KEY);
        } catch (error) {
            message.error(error.response.data.message);
        } finally {
            setSpin(false);
        }
    }

    useEffect(() => {
        const record = localStorage.getItem(STORAGE_KEY);
        if (record && !currentRecord) {
            getQueueEntryDetail(record);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem(STORAGE_KEY)])

    const callPatientToRoom = async (currentRecord) => {
        if (currentLoggedInRoom.current > 0) {
            const resp = await updateAppointmentConsultStatus(currentRecord.id, 'START_CONSULT', 'In Consult', { roomId: currentLoggedInRoom.current });
            setDraw((draw) => draw + 1)
            if (resp?.status === 200){
                setOpenCallPatient(true);
            }
        } else {
            setRoomModalVisible(true);
        }
    }

    const returnToQueue = async (id) => {
        const resp = await updateAppointmentConsultStatus(id, 'CHECKED_IN', 'Checked In');
        setDraw((draw) => draw + 1)
        if (resp?.status === 200){
            setOpenCallPatient(false);
            localStorage.removeItem(STORAGE_KEY)
        }
    }

    const endConsult = async (id) => {
        const resp = await updateAppointmentConsultStatus(id, 'CHECKED_OUT', 'Checked Out');
        setDraw((draw) => draw + 1)
        if (resp?.status === 200){
            setOpenCallPatient(false);
            localStorage.removeItem(STORAGE_KEY)
        }
    }

    const formatDuration = (millis) => {
        if (millis >= 3600000) {
            return moment(millis).utcOffset(0).format('H:mm:ss');
        }
        if (millis < 0) {
            return '';
        }
        return moment(millis).utcOffset(0).format('m:ss');
    }

    const calculateWaitTime = (record) => {
        if (record.statusTimestamps && record.statusTimestamps['checkedIn']) {
            let compareTo = moment();
            if (record.statusTimestamps['startConsult']) {
                compareTo = moment(record.statusTimestamps['startConsult'])
            } else if (record.statusTimestamps['checkedOut']) {
                compareTo = moment(record.statusTimestamps['checkedOut'])
            }
            return formatDuration(compareTo.valueOf() - moment(record.statusTimestamps['checkedIn']).valueOf())
        }
        return '';
    }

    const calculateTimeInConsult = (record) => {
        if (record.statusTimestamps && record.statusTimestamps['startConsult']) {
            let compareTo = moment();
            if (record.statusTimestamps['checkedOut']) {
                compareTo = moment(record.statusTimestamps['checkedOut'])
            }
            return formatDuration(compareTo.valueOf() - moment(record.statusTimestamps['startConsult']).valueOf())
        }
        return '';
    }

    const collapseTable = (record) => {
        let newExpandedRowKeys = [...expandedRowKeys];

        const filteredExpandedRowKeys = newExpandedRowKeys.filter(newExpandedRowKeys => newExpandedRowKeys !== record.id);

        setExpandedRowKeys(filteredExpandedRowKeys);
    }

    const columns = [
        !sysPropertiesState.rowColor && { // show bars instead of the background color
            permissionKey: 'appointments:list:column:first_name',
            title: <strong></strong>,
            dataIndex: '-',
            key: '-',
            render: (text, record, index) => {
                return (
                    <div
                        className={record?.status}
                        style={{
                            position: 'absolute',
                            height: '96%',
                            width: '12px',
                            left: '1px',
                            top: '2%',
                            marginLeft: '5px'
                        }}
                    ></div>
                );
            },
        },
        {
            permissionKey: 'checkedinPatients:list:column:mrn',
            title: <strong>MRN/<br />Patient ID</strong>,
            dataIndex: 'patient.mrn',
            key: 'patient.mrn',
            sorter: true,
            render: (text, record, value) => {
                if (record?.patient?.mrn || record?.patient?.uniqueId) {
                    return (record?.patient?.mrn ? record?.patient?.mrn : '-') + '/\n' + (record?.patient?.uniqueId ? record?.patient?.uniqueId : '-')
                }
                return '';
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:name',
            title: <strong>Patient Name</strong>,
            dataIndex: 'patient.firstName',
            key: 'patient.firstName',
            sorter: true,
            render: (text, record, index) => {
                return (
                        <>
                            {record.appointment?.id ?
                            <Link
                                to={`/checked-in-patient/${record?.appointment?.id}`}
                            >
                                {record?.patient?.firstName} {record?.patient?.lastName}
                            </Link> :
                              (record.patient ? `${record?.patient?.firstName} ${record?.patient?.lastName}` : '')}
                        </>
                );
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:appointmentDateTime',
            title: <strong>Adm<br />Date & Time</strong>,
            dataIndex: 'appointment.appointmentDateTime',
            key: 'appointment.appointmentDateTime',
            sorter: true,
            defaultSortOrder: "ascend",
            render: (text, record, value) => record.appointment?.appointmentDateTime ? moment(record.appointment?.appointmentDateTime).format('DD/MMM/YY[\n]h:mm A') : ''
        },
        {
            permissionKey: 'checkedinPatients:list:column:appointmentType',
            title: <strong>Specialty</strong>,
            dataIndex: 'appointment.appointmentType',
            key: 'appointment.appointmentType',
            sorter: true,
            render: (text, record, value) => {
                return record?.appointment?.appointmentType
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:doctorName',
            title: <strong>Clinician Name</strong>,
            dataIndex: 'appointment.attendingClinicianName',
            key: 'appointment.attendingClinicianName',
            sorter: true,
            render: (text, record, value) => {
                return record?.appointment?.attendingClinicianName
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:doctorName',
            title: <strong>Resource</strong>,
            dataIndex: 'appointment.secondaryClinicianName',
            key: 'appointment.secondaryClinicianName',
            sorter: true,
            render: (text, record, value) => {
                return record?.appointment?.secondaryClinicianName
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:status',
            title: <strong>Status</strong>,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (text, record, value) => {
                let flags = record?.appointment?.flags;
                let renderTags = [];
                console.log()
                if (flags && flags.length) {
                    flags.map((flag) => {
                        renderTags.push(<><br /><CloseableTag
                            closable={isCurrentPermissionKeyPermitted('checkedinPatients:list:column:status:edit_tag', permissionState)}
                            tag={getAppointmentFlagMessageStatus(flag)}
                            index={flag}
                            id={`CheckedInFlag${getAppointmentFlagMessageStatus(flag)}`}
                            onClose={() => {
                                onAppointmentFlagRemove(record.appointment, flag, () => {
                                    setDraw((draw) => draw + 1)
                                })
                            }} /></>);
                    })
                }
                let queueEntryFlags = record?.flags;
                if (queueEntryFlags && queueEntryFlags.length) {
                    queueEntryFlags.map((flag) => {
                        renderTags.push(<><br /><CloseableTag
                            tag={getQueueEntryFlagMessageStatus(flag)}
                            index={flag}
                            closable={isCurrentPermissionKeyPermitted('checkedinPatients:list:column:status:edit_tag', permissionState)}
                            id={`CheckedInFlag${getQueueEntryFlagMessageStatus(flag)}`}
                            onClose={() => {
                                onQueueEntryFlagRemove(record.id, flag, () => {
                                    setDraw((draw) => draw + 1)
                                })
                            }} /></>);
                    })
                }
                return <>{QueueEntryStatusTypes.find(item => (item.value === text))?.displayText}{renderTags}</>;
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:queueNumber',
            title: <strong>Ticket Number</strong>,
            dataIndex: 'ticketNumber',
            key: 'ticketNumber',
            sorter: true,
            render: (text, record, value) => {
                return <>
                    <div>{record.ticketNumber}</div>
                    {record.queue?.clinic?.reception && <Tag color={'blue'}>
                        Reception
                    </Tag>}
                </>
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:queueNumber',
            title: <strong>Assigned room</strong>,
            dataIndex: 'room.name',
            key: 'room.name',
            sorter: true,
            render: (text, record, value) => record?.room ? record.room.name : ''
        },
        {
            permissionKey: 'checkedinPatients:list:column:actions',
            title: <strong>Actions</strong>,
            dataIndex: 'actions',
            key: 'actions',
            sorter: false,
            render: (text, record, value) => {
                let appointmentOptions = [];
                let allowed = allowedActions(record);

                if (allowed.includes('CALL_TO_ROOM')) {
                    appointmentOptions.push({
                        id: 'CallPatientRoomCheckedIn',
                        label: 'Call Patient',
                        onClick: async () => {
                            setCurrentRecord(record);
                            setActionPerform(false);

                            callPatientToRoom(record);
                        }
                    })
                }
                if (allowed.includes('CALL')) {
                    appointmentOptions.push({
                        id: 'CallPatientCheckedIn',
                        label: 'Call Patient',
                        onClick: async () => {
                            setCurrentRecord(record);
                            setActionPerform(false);
                            const resp = await updateAppointmentConsultStatus(record.id, 'START_CONSULT', 'In Consult');
                            if (resp?.status === 200){
                                localStorage.setItem(STORAGE_KEY, record.id);
                                setOpenCallPatient(true);
                            }
                        }
                    })
                }
                // if (allowed.includes('START_CONSULT')) {
                //     appointmentOptions.push({
                //         id: 'StartConsultCheckedIn',
                //         permissionKey: 'checkinPatient:list:column:actions:start_consult',
                //         label: 'Start Consult',
                //         onClick: () => {
                //             setActionPerform(false);
                //             updateAppointmentConsultStatus(record.id, 'START_CONSULT', 'Start Consult');
                //         }
                //     })
                // }
                if (allowed.includes('CHANGE_QUEUE')) {
                    appointmentOptions.push({
                        id: 'ChangeQueueCheckedIn',
                        label: 'Change Queue',
                        onClick: () => {
                            setActionPerform(false);
                            attemptChangeQueue(record);
                        }
                    })
                }

                if (allowed.includes('CHECKED_OUT')) {
                    let appointmentQueueTicket = record.appointment && !record.queue?.clinic?.reception;
                    appointmentOptions.push({
                        id: appointmentQueueTicket ? 'EndConsultCheckedIn' : 'CompleteCheckedIn',
                        permissionKey: 'checkinPatient:list:column:actions:CHECKED_OUT',
                        label: appointmentQueueTicket ? 'End Consult' : 'Complete',
                        onClick: () => {
                            setActionPerform(false);
                            updateAppointmentConsultStatus(record.id, 'CHECKED_OUT', 'Checked Out');
                        }
                    })
                }

                if (allowed.includes('RETURN_QUEUE')) {
                    appointmentOptions.push({
                        id: 'ReturnQueueCheckedIn',
                        label: 'Return to Queue',
                        onClick: async () => {
                            setActionPerform(false);
                            updateAppointmentConsultStatus(record.id, 'CHECKED_IN', 'Checked In');
                        }
                    })
                }

                return (
                  appointmentOptions.length > 0 && (
                    <>
                      <Tooltip
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        title={
                          record.patientBusy
                            ? record.patientBusyWithClinicianName
                              ? `This patient is in another consult with ${record.patientBusyWithClinicianName}`
                              : 'This patient is at reception'
                            : ''
                        }
                        placement={'top'}
                        visible={appointmentOptions[0].isInAnotherCall}
                      >
                        <div>
                          <ActionMenuDropDown
                            menus={appointmentOptions}
                            className={
                              appointmentOptions[0].id ===
                                'CallPatientRoomCheckedIn' ||
                              appointmentOptions[0].id ===
                                'CallPatientCheckedIn'
                                ? !record.patientBusy
                                  ? 'animate'
                                  : ''
                                : undefined
                            }
                            type='primary'
                            id={appointmentOptions[0]?.id}
                            disabled={
                              record.status === 'CHECKED_IN'
                                ? record?.patientBusy
                                : false
                            }
                          >
                            {appointmentOptions[0]?.label}
                          </ActionMenuDropDown>
                        </div>
                      </Tooltip>
                    </>
                  )
                );
            },
        },
        {
            permissionKey: 'checkedinPatients:list:column:contactNumber',
            title: <strong>Contact Number</strong>,
            dataIndex: 'patient.contactNumber',
            key: 'patient.contactNumber',
            sorter: true,
            render: (text, record, value) => {
                return record?.patient?.contactNumber
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:queueTime',
            title: <strong>Check In Timestamp</strong>,
            dataIndex: 'statusTimestamps.checkedIn',
            key: 'statusTimestamps.checkedIn',
            sorter: true,
            render: (text, record, value) => {
                if (record.statusTimestamps && record.statusTimestamps['checkedIn']) {
                    return moment(record.statusTimestamps['checkedIn']).format('HH:mm');
                }
                return '';
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:waitTime',
            title: <strong>Wait Time</strong>,
            dataIndex: 'timeSummary.timeInQueueSec',
            key: 'statusTimestamps.CHECKED_IN',
            sorter: true,
            render: (text, record, value) => {
                return <CalculateTime
                    record={record}
                    calculateTime={calculateWaitTime}
                    timeInterval={waitTimeInterval.current}
                    isSetInterval={!(record.statusTimestamps && (record.statusTimestamps['startConsult'] || record.statusTimestamps['checkedOut']))}
                />
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:consultTime',
            title: <strong>Start Consult Time Stamp</strong>,
            dataIndex: 'statusTimestamps.startConsult',
            key: 'statusTimestamps.startConsult',
            sorter: true,
            render: (text, record, value) => {
                if (record.statusTimestamps) {
                    if (record.statusTimestamps['startConsult']) {
                        return moment(record.statusTimestamps['startConsult']).format('HH:mm');
                    } else if (record.statusTimestamps['checkedOut']) {
                        return moment(record.statusTimestamps['checkedOut']).format('HH:mm');
                    }
                }
                return '';
            }
        },
        {
            permissionKey: 'checkedinPatients:list:column:consultTime',
            title: <strong>Time in Consult</strong>,
            dataIndex: 'timeSummary.timeInConsultSec',
            key: 'statusTimestamps.consultTime',
            sorter: true,
            render: (text, record, value) => {
                return <CalculateTime
                    record={record}
                    calculateTime={calculateTimeInConsult}
                    timeInterval={timeInConsultInterval.current}
                    isSetInterval={!(record.statusTimestamps && record.statusTimestamps['checkedOut'])}
                />
            }
        }
    ];

    const checkCurrentInConsult = (records) => {
        const response = localStorage.getItem(STORAGE_KEY);
        if (!response) {
            return;
        }
        const existingRecord = records.find((record) => record.id === Number(response));
        if (existingRecord && existingRecord.status !== "START_CONSULT") {
            localStorage.removeItem(STORAGE_KEY);
            setOpenCallPatient(false);
        }
    }

    const fetchQueueEntries = async (params) => {
        const response = await getQueueEntries(params);
        checkCurrentInConsult(response.data.data);
        const arr = []
        response?.data?.data.forEach((d) =>{
            arr.push(d.id)
        })
        setExpandedRowKeys(arr);
        
        return response;
    }
 
    // console.log(fetchQueueEntries())
    if (queueType?.length === 0) {
        return null;
    }

    return (
        <Spin spinning={spin}>
            <Aux>
                <SockJsClient
                    url={`${BASE_WS_URL}/checkedin-patients`}
                    topics={['/topic/checkedin']}
                    onMessage={(appointment) => {
                        if (appointment?.fetch) {
                            setDraw(draw + 1);
                        }
                    }}
                    onConnect={() => console.log('connected')}
                    onConnectFailure={() => console.log('not connected')}
                    ref={(client) => sockJSRef = client}
                />
                {isCurrentPermissionKeyPermitted('checkedinPatients:assignroom', permissionState) ? <Row>
                    <Col xs={12} lg={12}>
                        <Card title={<><AiOutlineLogin />&nbsp;&nbsp;Assign Room</>} className='mt-4 mb-4'>
                            <Row>
                                <Col>
                                    <label style={{ marginTop: '0' }}>Select Facility:</label><br />
                                    {/* <Statistic title={'Facility'} value={''} /> */}
                                    <Select
                                        id="CheckedInPatientFacilitySelect"
                                        onChange={onFacilityChanged}
                                        value={activeFacility}
                                        style={{ width: '100%' }}
                                        placeholder={'Select a facility'}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {locations.length > 0 && locations.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })).map((location, i) => {
                                            return <Select.Option key={i} value={location.id}>{location.name}</Select.Option>
                                        })}
                                    </Select>
                                </Col>
                                <Col>
                                    <label style={{ marginTop: '0' }}>Select Department:</label><br />
                                    {/* <Statistic title={'Department'} value={'N/A'} /> */}
                                    <Select
                                        id="CheckedInPatientDepartmentSelect"
                                        onChange={onDepartmentChanged}
                                        value={activeDepartment}
                                        placeholder={'Select a department'}
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {departmentList.length > 0 && departmentList.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })).map((department, i) => {
                                            return <Select.Option key={i} value={department.id}>{department.name}</Select.Option>
                                        })}
                                    </Select>
                                </Col>
                                <Col>
                                    <Statistic id="CurrnetLoggedInLabel" title={'Currently Logged in'} value={currentQueueUser} />
                                </Col>
                            </Row><br />
                            <Row>
                                <Col xs={12} md={4}>
                                    <label style={{ marginTop: '0' }}>Select Clinic:</label><br />
                                    <Select
                                        id="CheckedInPatientClinicSelect"
                                        onChange={onClinicChanged}
                                        value={activeClinic}
                                        placeholder={'Select a clinic'}
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {clinicList.length > 0 && clinicList.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })).map((clinic, i) => {
                                            return <Select.Option key={i} value={clinic.id}>{clinic.name}</Select.Option>
                                        })}
                                    </Select>&nbsp;&nbsp;
                                </Col>
                                <Col xs={12} md={4}>
                                    <label style={{ marginTop: '0' }}>Select Room:</label><br />
                                    <Select
                                        id="CheckedInPatientRoomSelect"
                                        onChange={onRoomsChanged}
                                        value={activeRoom?.id || null}
                                        placeholder={'Select a room'}
                                        style={{ width: '100%' }}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {roomsList.length > 0 && roomsList.map((room, i) => {
                                            return <Select.Option key={i} value={room.id} name={room.name}>{room.loggedInUser ? <Badge color={'red'} /> : <Badge color={'green'} />} {room.name}</Select.Option>
                                        })}
                                    </Select>&nbsp;&nbsp;
                                </Col>
                                <Col xs={12} md={4}>
                                    {!isCurrentLoginUserIntoRoom ? <Button
                                        id="CheckedInPatientLoginRoomButton"
                                        onClick={onLoginClick}
                                        loading={isLoginButtonLoading}
                                        style={{ marginTop: '25px' }}
                                        type='primary'
                                        disabled={!isCurrentPermissionKeyPermitted('checkedinPatients:assignroom:room_login', permissionState)}
                                    >
                                        <AiOutlineLogin />&nbsp;&nbsp;Log In to Room
                                    </Button> :
                                        <Button
                                            id="CheckedInPatientLogoutRoomButton"
                                            onClick={onLogoutClick}
                                            loading={isLoginButtonLoading}
                                            style={{ marginTop: '25px' }}
                                            type='danger'
                                            disabled={!isCurrentPermissionKeyPermitted('checkedinPatients:assignroom:room_logout', permissionState)}
                                        >
                                            <AiOutlineLogin />&nbsp;&nbsp;Log me out
                                        </Button>}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>: ''}
                <RoomSelectionModal appointment={currentRecord} visible={roomModalVisible} setVisibility={setRoomModalVisible} changeAppointment={changeToCalledAppointment} />
                {openCallPatient && <CallPatientInfoModal visible={openCallPatient} appointment={currentRecord} setVisibility={setOpenCallPatient} returnToQueue={returnToQueue} endConsult={endConsult} changeQueue={attemptChangeQueue} />}
                <Row>
                    <Col>
                        <LocationSelect
                            locationData={locations}
                            facilityValue={facilityValue}
                            departmentValue={departmentValue}
                            clinicValue={clinicValue}
                            showSearchValue={true}
                            width='275px'
                            direction='horizontal'
                            onChange={(value) => {
                                initial.current = false;
                                setDataTableParams((params) => ({
                                    ...params,
                                    locationId: value,
                                }))
                            }}
                            isClinicVisible={true}
                            clinicLabel={"Clinic Queue"}
                        />
                    </Col>
                </Row>
                <br />
                <Space direction='vertical' size="middle">
                    <Row>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>Status</Text>
                                <Select
                                    id="CheckedInStatusSelect"
                                    className='w-100'
                                    showSearch
                                    defaultValue={"ALL"}
                                    onChange={(value) => {
                                        initial.current = false;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            status: value,
                                        }))}
                                    }
                                >
                                    <Option value={'ALL'}>All</Option>
                                    {CheckedInPatientStatusTypes.map((status, i) => {
                                        return <Option key={i} value={status.value}>{status.displayText}</Option>
                                    })}
                                </Select>
                            </Space>
                        </Col>
                        <Col xs={12} lg={3}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>Specialty</Text>
                                <Select
                                    id="CheckedInAppointmentTypeSelect"
                                    className='w-100'
                                    showSearch
                                    defaultValue={"ALL"}
                                    onChange={(value) => {
                                        initial.current = false;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            appointmentType: value,
                                        }))}
                                    }
                                >
                                    <Option value={'ALL'}>All</Option>
                                    {appointmentsType.current.map((type, i) => {
                                        return <Option key={i} value={type.key}>{type.display}</Option>
                                    })}
                                </Select>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>MRN</Text>
                                <Input
                                    id="CheckedInMRNInput"
                                    className='w-100'
                                    allowClear
                                    value={dataTableParams.mrn}
                                    placeholder={"Search by MRN"}
                                    type='text'
                                    name='mrn'
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        initial.current = false;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            mrn: value,
                                        }))
                                    }
                                    }
                                />
                            </Space>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>Patient Name</Text>
                                <Input
                                    id="CheckedInPatientNameInput"
                                    className='w-100'
                                    allowClear
                                    value={dataTableParams.patientName}
                                    placeholder={"Search by name"}
                                    type='text'
                                    name='patientName'
                                    onChange={(e) => {
                                        initial.current = false;
                                        const value = e.target.value;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            patientName: value,
                                        }))
                                    }
                                    }
                                />
                            </Space>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>Phone Number</Text>
                                <Input
                                    id="CheckedInPhoneInput"
                                    className='w-100'
                                    allowClear
                                    value={dataTableParams.contactNumber}
                                    placeholder={"Search by phone number"}
                                    maxLength={10}
                                    type='text'
                                    name='contactNumber'
                                    onChange={(e) => {
                                        initial.current = false;
                                        const value = e.target.value;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            contactNumber: value,
                                        }))
                                    }
                                    }
                                />
                            </Space>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>Clinician</Text>
                                <Input
                                    id="CheckedInClinicianInput"
                                    className='w-100'
                                    allowClear
                                    value={dataTableParams.attendingClinicianName}
                                    placeholder={"Search by clinician"}
                                    maxLength={40}
                                    type='text'
                                    name='attendingClinicianName'
                                    onChange={(e) => {
                                        initial.current = false;
                                        const value = e.target.value;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            attendingClinicianName: value,
                                        }))
                                    }
                                    }
                                />
                            </Space>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>Resource</Text>
                                <Input
                                    id="CheckedInResourceInput"
                                    className='w-100'
                                    allowClear
                                    value={dataTableParams.secondaryClinicianName}
                                    placeholder={"Search by secondary staff"}
                                    maxLength={40}
                                    type='text'
                                    name='secondaryClinicianName'
                                    onChange={(e) => {
                                        initial.current = false;
                                        const value = e.target.value;
                                        setDataTableParams((params) => ({
                                            ...params,
                                            secondaryClinicianName: value,
                                        }))
                                    }
                                    }
                                />
                            </Space>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Space direction='vertical' size="small" className='w-100'>
                                <Text strong>&nbsp;</Text>
                                <Button
                                    id="appointmentsSearch"
                                    type={`${criteriaNotSearched() ? 'primary' : '' }`}
                                    className={`w-100 ${!criteriaNotSearched() ? 'not-searched': ''}`}
                                    onClick={() => setSearch(current => current + 1)}>
                                    Search
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                    <Row></Row>
                </Space>

                {locationCalled > 0 ? <CRUDDataTable
                    draw={draw}
                    entity=''
                    fetchDataFunction={fetchQueueEntries}
                    continuousFetchInterval={30000}
                    isActionPerform={isActionPerform}
                    columns={permissifyTableColumns(columns, permissionState)}
                    customFilters={customFilters}
                    sortOrder='appointment.appointmentDateTime'
                    button={<></>}
                    highlightRow={(rec, i) => {
                        return (<></>);
                    }}
                    defaultExpandAllRows={true}
                    expandable={{
                        expandedRowKeys: expandedRowKeys,
                        expandedRowRender: record => {
                            if (record?.relatedItems && record?.relatedItems?.length > 0) {
                                const expandedColumns = [
                                    {
                                        permissionKey: 'checkedinPatients:list:column:appointmentDateTime',
                                        title: null,
                                        dataIndex: 'appointment.appointmentDateTime',
                                        key: 'appointment.appointmentDateTime',
                                        sorter: true,
                                        defaultSortOrder: "ascend",
                                        render: (text, record, value) => record.appointment?.appointmentDateTime ? moment(record.appointment?.appointmentDateTime).format('DD/MMM/YY[\n]h:mm A') : ''
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:appointmentType',
                                        title: <strong>Appointment Type</strong>,
                                        dataIndex: 'appointment.appointmentType',
                                        key: 'appointment.appointmentType',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            return record?.appointment?.appointmentType
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:doctorName',
                                        title: <strong>Clinician Name</strong>,
                                        dataIndex: 'appointment.attendingClinicianName',
                                        key: 'appointment.attendingClinicianName',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            return record?.appointment?.attendingClinicianName
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:status',
                                        title: <strong>Status</strong>,
                                        dataIndex: 'status',
                                        key: 'status',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            let flags = record?.appointment?.flags;
                                            let renderTags = [];
                                            console.log()
                                            if (flags && flags.length) {
                                                flags.map((flag) => {
                                                    renderTags.push(<><br/><CloseableTag
                                                      closable={isCurrentPermissionKeyPermitted('checkedinPatients:list:column:status:edit_tag', permissionState)}
                                                      tag={getAppointmentFlagMessageStatus(flag)}
                                                      index={flag}
                                                      id={`CheckedInFlag${getAppointmentFlagMessageStatus(flag)}`}
                                                      onClose={() => {
                                                          onAppointmentFlagRemove(record.appointment, flag, () => {
                                                              setDraw((draw) => draw + 1)
                                                          })
                                                      }}/></>);
                                                })
                                            }
                                            let queueEntryFlags = record?.flags;
                                            if (queueEntryFlags && queueEntryFlags.length) {
                                                queueEntryFlags.map((flag) => {
                                                    renderTags.push(<><br/><CloseableTag
                                                      tag={getQueueEntryFlagMessageStatus(flag)}
                                                      closable={isCurrentPermissionKeyPermitted('checkedinPatients:list:column:status:edit_tag', permissionState)}
                                                      index={flag}
                                                      id={`CheckedInFlag${getQueueEntryFlagMessageStatus(flag)}`}
                                                      onClose={() => {
                                                          onQueueEntryFlagRemove(record.id, flag, () => {
                                                              setDraw((draw) => draw + 1)
                                                          })
                                                      }}/></>);
                                                })
                                            }
                                            return <>{QueueEntryStatusTypes.find(item => (item.value === text))?.displayText}{renderTags}</>;
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:queueNumber',
                                        title: <strong>Ticket Number</strong>,
                                        dataIndex: 'ticketNumber',
                                        key: 'ticketNumber',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            return <>
                                                <div>{record.ticketNumber}</div>
                                                {record.queue?.clinic?.reception && <Tag color={'blue'}>
                                                    Reception
                                                </Tag>}
                                            </>
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:queueNumber',
                                        title: <strong>Assigned room</strong>,
                                        dataIndex: 'room.name',
                                        key: 'room.name',
                                        sorter: true,
                                        render: (text, record, value) => record?.room ? record.room.name : ''
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:actions',
                                        title: <strong>Actions</strong>,
                                        dataIndex: 'actions',
                                        key: 'actions',
                                        sorter: false,
                                        render: (text, record, value) => {
                                            let appointmentOptions = [];
                                            let allowed = allowedActions(record);

                                            if (allowed.includes('CALL_TO_ROOM')) {
                                                appointmentOptions.push({
                                                    id: 'CallPatientRoomCheckedIn',
                                                    label: 'Call Patient',
                                                    onClick: async () => {
                                                        setCurrentRecord(record);
                                                        setActionPerform(false);

                                                        callPatientToRoom(record);
                                                    }
                                                })
                                            }
                                            if (allowed.includes('CALL')) {
                                                appointmentOptions.push({
                                                    id: 'CallPatientCheckedIn',
                                                    label: 'Call Patient',
                                                    onClick: async () => {
                                                        setCurrentRecord(record);
                                                        setActionPerform(false);
                                                        const resp = await updateAppointmentConsultStatus(record.id, 'START_CONSULT', 'In Consult');
                                                        if (resp?.status === 200) {
                                                            localStorage.setItem(STORAGE_KEY, record.id);
                                                            setOpenCallPatient(true);
                                                        }
                                                    }
                                                })
                                            }
                                            if (allowed.includes('CHANGE_QUEUE')) {
                                                appointmentOptions.push({
                                                    id: 'ChangeQueueCheckedIn',
                                                    label: 'Change Queue',
                                                    onClick: () => {
                                                        setActionPerform(false);
                                                        attemptChangeQueue(record);
                                                    }
                                                })
                                            }

                                            if (allowed.includes('CHECKED_OUT')) {
                                                let appointmentQueueTicket = record.appointment && !record.queue?.clinic?.reception;
                                                appointmentOptions.push({
                                                    id: appointmentQueueTicket ? 'EndConsultCheckedIn' : 'CompleteCheckedIn',
                                                    permissionKey: 'checkinPatient:list:column:actions:CHECKED_OUT',
                                                    label: appointmentQueueTicket ? 'End Consult' : 'Complete',
                                                    onClick: () => {
                                                        setActionPerform(false);
                                                        updateAppointmentConsultStatus(record.id, 'CHECKED_OUT', 'Checked Out');
                                                    }
                                                })
                                            }

                                            if (allowed.includes('RETURN_QUEUE')) {
                                                appointmentOptions.push({
                                                    id: 'ReturnQueueCheckedIn',
                                                    label: 'Return to Queue',
                                                    onClick: async () => {
                                                        setActionPerform(false);
                                                        updateAppointmentConsultStatus(record.id, 'CHECKED_IN', 'Checked In');
                                                    }
                                                })
                                            }

                                            return (
                                              appointmentOptions.length > 0 && (
                                                <>
                                                    <Tooltip
                                                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                                      title={
                                                          record.patientBusy
                                                            ? record.patientBusyWithClinicianName
                                                              ? `This patient is in another consult with ${record.patientBusyWithClinicianName}`
                                                              : 'This patient is at reception'
                                                            : ''
                                                      }
                                                      placement={'top'}
                                                      visible={appointmentOptions[0].isInAnotherCall}
                                                    >
                                                        <div>
                                                            <ActionMenuDropDown
                                                              menus={appointmentOptions}
                                                              className={
                                                                  record.status === 'CHECKED_IN' && !record?.patientBusy
                                                                    ? 'animate'
                                                                    : ''
                                                              }
                                                              type='primary'
                                                              id={
                                                                  appointmentOptions[0]
                                                                    ?.id
                                                              }
                                                              disabled={record.status === 'CHECKED_IN' ? record?.patientBusy : false}
                                                            >
                                                                {appointmentOptions[0]?.label}
                                                            </ActionMenuDropDown>
                                                        </div>
                                                    </Tooltip>
                                                </>
                                              )
                                            );
                                        },
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:contactNumber',
                                        title: <strong>Contact Number</strong>,
                                        dataIndex: 'patient.contactNumber',
                                        key: 'patient.contactNumber',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            return record?.patient?.contactNumber
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:queueTime',
                                        title: <strong>Check In Timestamp</strong>,
                                        dataIndex: 'statusTimestamps.checkedIn',
                                        key: 'statusTimestamps.checkedIn',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            if (record.statusTimestamps && record.statusTimestamps['checkedIn']) {
                                                return moment(record.statusTimestamps['checkedIn']).format('HH:mm');
                                            }
                                            return '';
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:waitTime',
                                        title: <strong>Wait Time</strong>,
                                        dataIndex: 'timeSummary.timeInQueueSec',
                                        key: 'statusTimestamps.CHECKED_IN',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            return <CalculateTime
                                              record={record}
                                              calculateTime={calculateWaitTime}
                                              timeInterval={waitTimeInterval.current}
                                              isSetInterval={!(record.statusTimestamps && (record.statusTimestamps['startConsult'] || record.statusTimestamps['checkedOut']))}
                                            />
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:consultTime',
                                        title: <strong>Start Consult Time Stamp</strong>,
                                        dataIndex: 'statusTimestamps.startConsult',
                                        key: 'statusTimestamps.startConsult',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            if (record.statusTimestamps) {
                                                if (record.statusTimestamps['startConsult']) {
                                                    return moment(record.statusTimestamps['startConsult']).format('HH:mm');
                                                } else if (record.statusTimestamps['checkedOut']) {
                                                    return moment(record.statusTimestamps['checkedOut']).format('HH:mm');
                                                }
                                            }
                                            return '';
                                        }
                                    },
                                    {
                                        permissionKey: 'checkedinPatients:list:column:consultTime',
                                        title: <strong>Time in Consult</strong>,
                                        dataIndex: 'timeSummary.timeInConsultSec',
                                        key: 'statusTimestamps.consultTime',
                                        sorter: true,
                                        render: (text, record, value) => {
                                            return <CalculateTime
                                              record={record}
                                              calculateTime={calculateTimeInConsult}
                                              timeInterval={timeInConsultInterval.current}
                                              isSetInterval={!(record.statusTimestamps && record.statusTimestamps['checkedOut'])}
                                            />
                                        }
                                    }
                                ]
                                const expandedDataSource = record.relatedItems;

                                return  <div style={{ marginLeft: '11%' }}>
                                            <Button type='link' style={{ color: '#ddd' }} onClick={() => {
                                               collapseTable(record);
                                            }}>
                                                <CgChevronDoubleUpO />&nbsp;&nbsp;Hide additional appointments
                                            </Button>
                                            <Space
                                                direction={'vertical'}
                                            />
                                            <Table 
                                                showHeader={false}
                                                columns={permissifyTableColumns(expandedColumns, permissionState)}
                                                bordered
                                                pagination={false}
                                                dataSource={expandedDataSource} 
                                                rowClassName={(record) =>`${  record?.status }`}
                                            />
                                        </div>
                            }
                        },
                        onExpand: (expanded, record) => {
                            if (expanded) {
                                setExpandedRowKeys((expandedRowKeys) => [...expandedRowKeys, record.id]);
                            } else {
                                collapseTable(record);
                            }
                        },
                        rowExpandable: record => record?.relatedItems &&  record?.relatedItems.length > 0 ,
                    }}
                    searchText="Scan patient's QR code or type to search"
                    searchAutoFocus={true}
                    showSearchBar={false}
                    rowKey={record => record.id}
                    isRowColor={sysPropertiesState.rowColor}
                /> : <></>}
            </Aux>
        </Spin>
    );
};

export default CheckedInPatientList;
