import { refreshToken } from 'api/auth';
import {deleteAppointmentFlag} from "../api/appointments";
import {message} from "antd";
import {deleteQueueEntryFlag} from "../api/queueEntries";

export const groupBy = (items, prop) => {
  var arr = [];
  for (var i = 0, _len = items.length; i < _len; i++) {
    var bfound = false;
    for (var j = 0; j < arr.length; j++) {
      if (arr[j][prop] === items[i][prop]) {
        bfound = true;
      }
    }
    if (bfound === false) {
      arr.push({ [prop]: items[i][prop] });
    }
  }
  return arr;
};

export const saveAuth = (token, refresh_token, expires_in) => {
  const expiryDate = new Date(new Date().getTime() + expires_in * 1000);

  sessionStorage.setItem('token', token);
  sessionStorage.setItem('refresh_token', refresh_token);
  sessionStorage.setItem('expiryDate', expiryDate);
};

export const removeAuth = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('expiryDate');
  sessionStorage.removeItem('LastUsedJwtToken')
};

export const tokenExpired = () => {
  const token = sessionStorage.getItem('token');
  const expiryDate = sessionStorage.getItem('expiryDate');

  //;

  //return token && expiryDate && new Date(expiryDate) <= new Date();
  // change 1 minutes ago
  return (
    token &&
    expiryDate &&
    new Date(new Date(expiryDate).getTime() - 1 * 60000) <= new Date()
  );
};

export const refreshTheToken = async () => {
  try {
    const refreshtoken = sessionStorage.getItem('refresh_token');

    const payload = {
      refreshToken: refreshtoken,
    };
    const response = await refreshToken(payload);
    const { access_token, refresh_token, expires_in } = response.data;
    // save the new credentials
    saveAuth(access_token, refresh_token, expires_in);
    return { access_token, refresh_token, expires_in };
  } catch (err) {
    const access_token = null,
      refresh_token = null,
      expires_in = null;
    return { access_token, refresh_token, expires_in };
  }
};

export const getAppointmentFlagMessageStatus = (type) => {
  let message = '';
  switch (type) {
    case 'PAT_DETAILS_CHANGED':
      message = 'Patient Details Changed';
      break;
    case 'FIRST_APT':
      message = 'First Appointment';
      break;
    case 'CHECK_FIN_CONSENT_FORM':
      message = 'Check Financial Consent Form';
      break;
    case 'REFERRAL_EXPIRED':
      message = 'Referral Expired';
      break;
    case 'MEDICARE_EXPIRED':
      message = 'Medicare Expired';
      break;
    case 'INCOMPLETE_PAT_TASK':
      message = 'Incomplete Task';
      break;
    case 'NOT_MEDICARE':
      message = 'Check Medicare';
      break;
    default:
      message = 'Other';
      break;
  }
  return message;
}

export const getQueueEntryFlagMessageStatus = (type) => {
  let message = '';
  switch (type) {
    case 'INCORRECT_APPOINTMENT':
      message = 'Incorrect Appointment';
      break;
    default:
      message = 'Other';
      break;
  }
  return message;
}

export const onAppointmentFlagRemove = async (appointment, flag, callback) => {
  await deleteAppointmentFlag(appointment.id, flag).then((resp) => {
    message.success('Flag Deleted Successfully');
    callback();
  })
}

export const onQueueEntryFlagRemove = async (queueEntryId, flag, callback) => {
  await deleteQueueEntryFlag(queueEntryId, flag).then((resp) => {
    message.success('Flag Deleted Successfully');
    callback();
  })
}