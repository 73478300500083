import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {getAllLocations, getLocationChildren, getLocation} from 'api/locations';
import {Select, Space} from 'antd';
import { setHospitalLocations } from 'redux/actions/ui';

const HospitalSelect = props => {
  const { Option } = Select;
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [selectedParentHospital, setSelectedParentHospital] = useState();
  const [childValue, setChildValue] = useState();
  const [children, setChildren] = useState();
  const [parentValue, setParentValue] = useState();
  const facilityRef = useRef();
  const departmentRef = useRef();
  const useEntity = props.useEntity;
  const hideLabel = props.hideLabel;
  const {showSearchValue = false} = props;
  const dispatch = useDispatch();

  function fetchHospitalOptions() {
    (async () => {
      let response = await getAllLocations();
      const locations = response?.data?.data || [];

      // sort by name
      setHospitalOptions(locations.sort((a, b) =>  a.name.localeCompare(b.name, 'en', { numeric: true })));

      if (props?.storeDataGlobally) {
        dispatch(setHospitalLocations(locations));
      }
    })();
  }

  useEffect(() => {
    fetchHospitalOptions();
  }, [])

  useEffect(() => {
    let location = props.value;
    // TODO not working as intended
    if (location !== undefined) {
      getLocation(location.id)
        .then(res => {
          let selectedLocation = res.data.data;
          if (!selectedLocation.parentId) {
            setParentValue(selectedLocation.id)
            onChange(selectedLocation.id, false,11)
          } else {
            setParentValue(selectedLocation.parentId);
            onChange(selectedLocation.id, false, 12)
            setChildValue(selectedLocation.id)
          }

        })
    }
  }, [props.value])
  const [clinicOptions, setClinicOptions] = useState();
  const [departmentClinicOptions, setDepartmentClinicOptions] = useState();
  const [clinicValue, setClinicValue] = useState(null);

  const optionAll = props.removeAllOption
    ? ''
    : <Option value='ALL'>All</Option>

  const onChange = async (value, isDepartment = false, action = 0) => {
    if(value === 'ALL' && action === 3) {
      props.onChange(departmentRef.current, isDepartment, action-1);
      return;
    }
    if(value === 'ALL' && action === 2) {
      setDepartmentClinicOptions(undefined);
      props.onChange(facilityRef.current, isDepartment, action-1);
      return;
    }
    if (value === 'ALL' && action === 1) {
      setClinicOptions(undefined);
      setDepartmentClinicOptions([]);
      setParentValue('ALL');
      props.onChange(undefined, isDepartment, action);
      return;
    }
    if (hospitalOptions.length === 0) {
      fetchHospitalOptions()

    }
    let hospital = hospitalOptions.find(hospital => hospital.id === value);
    if (hospital !== undefined) {
      let children = await getLocationChildren(hospital.id)
      let childrenTypeName = hospital.childrenTypeName;
      if (children.data.data.length > 0) {
        let newClinicOptions =
          <>
            <div>
              {hideLabel ?? <>
                <label>Department</label><br /></>}
              <Select
                // showSearch
                id="LocationSelectDepartment"
                showSearch={showSearchValue? true : false}
                style={props.width ?? { width: '100%' }}
                placeholder='Select Location'
                optionFilterProp='children'
                onChange={(value) => {
                  departmentRef.current = value;
                  onChange(value, true, 2);
                  setClinicValue(null);
                  let facilityDeaprtmentData = children.data.data;
                  if (props?.isClinicVisible && value !== 'ALL' && facilityDeaprtmentData && facilityDeaprtmentData.length > 0) {
                    let departmentClinic = facilityDeaprtmentData?.find(item => item.id === value)
                    if (departmentClinic.children.length > 0) {
                      setDepartmentClinicOptions(departmentClinic.children)
                    } else {
                      setDepartmentClinicOptions([])
                    }
                  }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={childValue}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {children.data.data.map(item => {
                  return <Option key={item.id} value={item.id}>{item.name}</Option>
                }
                )}
                {optionAll}
              </Select>
            </div>
          </>
        setClinicOptions(newClinicOptions)
      } else {
        setClinicOptions(null)
      }
    }
    if (hospital !== undefined && useEntity) {
      setSelectedParentHospital(hospital);
      props.onChange(hospital, isDepartment, action)
    } else if (hospital === undefined && selectedParentHospital !== undefined && useEntity) {
      let child = selectedParentHospital.children.find(hospital => hospital.id === value);
      props.onChange(child, isDepartment, action)
    } else if (useEntity) {
      let childHospital = hospitalOptions.find(hospital => {
        if (hospital.children) {
          let location = hospital.children.find(child => child.id === value)
          if (location !== undefined)
            return location;
        }
      })
      props.onChange(childHospital, isDepartment, action);
    } else {
      props.onChange(value, isDepartment, action);
    }

  }

  const onBlur = () => {
  }

  const onFocus = () => {
  }

  const onSearch = (val) => {
  }

  return (
    <>
      <Space direction={props.direction ?? 'vertical'} style={{ width: '100%' }}>
        <div>
          {hideLabel ?? <><label>Facility</label><br /></>}
          <Select
            // showSearch
            id="LocationSelectDropDown"
            showSearch={showSearchValue? true : false}
            style={props.width ?? { width: '100%' }}
            placeholder='Select Location'
            optionFilterProp='children'
            onChange={(value) => {
              facilityRef.current = value;
              onChange(value, false, 1);
              setParentValue(value);
              setClinicOptions(undefined);
              setDepartmentClinicOptions([]);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            defaultValue={props?.defaultValue || parentValue}
            value={(hospitalOptions.length) > 0 && props?.defaultValue || parentValue}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {optionAll}
            {hospitalOptions.map(item =>
              <Option key={item.id} value={item.id}>{item.name}</Option>
            )}
          </Select>
        </div>
        {clinicOptions}
        {departmentClinicOptions && departmentClinicOptions.length > 0 && <div>
            {hideLabel ?? <><label>{props?.clinicLabel ?? 'Clinic'}</label><br/></>}
            <Select
              id='LocationSelectClinicDropDown'
              showSearch={showSearchValue? true : false}
              style={props.width ?? {width: '100%'}}
              placeholder='Select Clinic'
              optionFilterProp='children'
              onChange={(value) => {
                setClinicValue(value)
                onChange(value, false, 3)
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              value={clinicValue}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {departmentClinicOptions.map(item => {
                  return <Option key={item.id} value={item.id}>{item.name}</Option>
                }
              )}
              {optionAll}
            </Select>
          </div>}
      </Space>
    </>
  )


}
export default HospitalSelect;
