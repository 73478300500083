import { BASE_API_URL, REQUEST_TIMEOUT } from 'api/config';
import { createAxios } from './axios';

const appointmentApi = createAxios({
    baseURL: BASE_API_URL + '/appointments',
});

export const getAppointmentById = id => {
    return appointmentApi.get(`/${id}`);
};
export const getAppointmentTypes = () => {
    return appointmentApi.get('/list/type');
};

export const updateAppointmentStatus = payload => {
    return appointmentApi.patch('/status', payload);
};

export const addAppointment = appointment => {
    return appointmentApi.post('', appointment);
};

export const getAppointmentsDatatable = params => {
    if (params && params.filters && params.filters.length > 0) {
        params.doctorFilter = params.filters[3][0];
        params.appointmentTypeFilter = params.filters[4][0];
        params.statusFilter = params.filters[5][0];
    }

    return appointmentApi.get('', {
        timeout: REQUEST_TIMEOUT,
        params: params,
    });
};

export const getUserDetailBaseOnId = (appointmentId) => {
    return appointmentApi.get('/patientDetails/'+appointmentId);
}

export const deleteAppointmentFlag = async (id,flag) => {
    return await appointmentApi.delete(id+'/flags/'+flag);
}
