import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const DeleteMetadataModal = ({ setVisibility, visible, data, setData, selectedMetadata }) => {

    const deleteMetadata = () => {
        let metaData = data?.filter((val) => {
            if (val.key !== selectedMetadata.key) {
                return val
            }
        });
        setData(metaData);
        setVisibility(false);
    }

    return <Modal
        visible={visible}
        onOk={deleteMetadata}
        onCancel={() => setVisibility(false)}
        okText={"Ok"}
        cancelText={"Cancle"}
        closeIcon={(<CloseOutlined id='closeDeleteMetaDataModal' />)}
    >
        Are you want to delete metadata <b>{selectedMetadata?.key}</b> ?
    </Modal>
}

export default DeleteMetadataModal;