import { useState, useEffect, useRef } from "react";
import {Button, DatePicker, Modal, Select, Space, Tag, Tooltip, Typography, Input, message} from "antd";
import TaskRejectionForm from "./TaskRejectionForm";
import {Link} from "react-router-dom";
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";
import {getTaskDatatable, updateStatus} from "../../api/tasks";
// import PermissifiedComponent from "components/PermissifiedComponent";
import { useSelector } from 'react-redux';
import { isCurrentPermissionKeyPermitted, permissifyTableColumns } from "utils";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { getAllLocations } from 'api/locations';
import LocationSelect, { getLocationValues } from 'components/location/LocationSelect';
import { CloseOutlined } from "@ant-design/icons";

const Tasks = () => {
  const [rejectionVisible, setRejectionVisibility] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [facilityValue, setFacilityValue] = useState(null);
  const [departmentValue, setDepartmentValue] = useState(null);
  const [clinicValue, setClinicValue] = useState(null);
  const [locationCalled, setLocationCalled] = useState(0);
  const [locations, setLocations] = useState([]);
  const [entityId, setEntityId] = useState();
  const [status, setStatus] = useState('NEW');
  const { Text } = Typography;
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const DEFAULT_FILTERS = {
    facility: undefined,
    department: undefined,
    clinic: undefined,
    status: 'NEW',
    startDate: null,
    endDate: null,
    patientName: '',
    mrn: ''
  };

  const [customFilters, setCustomFilters] = useState(Object.keys(DEFAULT_FILTERS).map((paramName) => ({
    paramName,
    value: DEFAULT_FILTERS[paramName]
  })));
  const [search, setSearch] = useState(1);
  const [dataTableParams, setDataTableParams] = useState(DEFAULT_FILTERS);
  const initial = useRef(true);

//   const {TabPane} = Tabs;
  const [modalMessage, setModalMessage] = useState('');
  const [permissifiedTabs, setPermissifiedTabs] = useState([
    {
        key: 1,
        status: 'NEW',
        permissionKey: 'tasks:new'
    },
    {
        key: 2,
        status: 'COMPLETED',
        permissionKey: 'tasks:completed'
    },
    {
        key: 3,
        status: 'REJECTED',
        permissionKey: 'tasks:rejected'
    }
  ]);

  const setLocationParams = (data) => {
    const locationObj = getLocationValues(data);

    if (locationObj.facility) {
        setFacilityValue(locationObj.facility);
    }
    if (locationObj.department) {
        setDepartmentValue(locationObj.department);
    }
    if (locationObj.clinic) {
        setClinicValue(locationObj.clinic);
    }
    let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => {
        if (paramName === 'facility' || paramName === 'department' || paramName === 'clinic') {
            return ({
                paramName,
                value: locationObj[paramName],
            })
        }
        else {
            return ({
                paramName,
                value: dataTableParams[paramName],
            })
        }
    })
    setCustomFilters(updatedCustomFilters);
    setDataTableParams((params) => ({
      ...params,
      facility: locationObj['facility'],
      department: locationObj['department'],
      clinic: locationObj['clinic']
    }));

    setLocationCalled(1);
  }

  const fetchLocations = async () => {
    try {
        const response = await getAllLocations();

        setLocations(response.data.data);
        setLocationParams(response.data.data);
    } catch (err) {
        message.error('Error occured while getting all the locations');
    }
  }

  useEffect(() => {
    fetchLocations();
  }, []);

  // yes, confusing naming convention. will fix later sorry
  const [newStatus, setNewStatus] = useState('NEW');
  const permissionState = useSelector(state => state.permissions);

  const statusPermissions = [
      {
        status: 'NEW',
        permissionKey: 'tasks:new'
      },
      {
        status: 'COMPLETED',
        permissionKey: 'tasks:completed'
      },
      {
        status: 'REJECTED',
        permissionKey: 'tasks:rejected'
      }
  ]

    // useEffect(() => {
    //     const newStatusPermissions = [];

    //     statusPermissions.map((statusPermission, index) => {
    //         if (isCurrentPermissionKeyPermitted(statusPermission.permissionKey, permissionState)) {
    //             statusPermission.key = (index + 1);
    //             newStatusPermissions.push(statusPermission);
    //         }
    //     });

    //     //newStatusPermissions.length > 0 && setStatus(newStatusPermissions[0].status);
    //     //setPermissifiedTabs(newStatusPermissions);
    // }, []);

    const renderActionColumn = (activeTab) => {
        let actionColumn = null;

        if ((activeTab === 'NEW' && isCurrentPermissionKeyPermitted('tasks:new:options', permissionState))
        || (activeTab === 'COMPLETED' && isCurrentPermissionKeyPermitted('tasks:completed:options', permissionState))
        || (activeTab === 'REJECTED' && isCurrentPermissionKeyPermitted('tasks:rejected:options', permissionState))) {
            actionColumn = <strong>Actions</strong>;
        }

        return actionColumn;
    }

    useEffect(() => {
        let updatedCustomFilters = Object.keys(dataTableParams).map((paramName) => ({
          paramName,
          value: dataTableParams[paramName],
        }))
        setCustomFilters(updatedCustomFilters)
      }, [search])
    
      const criteriaNotSearched = () => {
        if(!initial.current) {
          for (let filter of customFilters) {
            if (dataTableParams[filter.paramName] !== filter.value) {
              return true;
            }
          }
        }
        return false;
      }

    const columns = [
        {
            permissionKey: 'tasks:mrn',
            title: <strong>MRN</strong>,
            dataIndex: 'mrn',
            key: 'mrn',
            render: (text, record, value) => {
                return record?.appointment?.mrn;
            }
        },
        {
            permissionKey: 'tasks:patient_name',
            title: <strong>Patient Name</strong>,
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.appointment?.firstName?.localeCompare(b.appointment?.firstName),
            defaultSortOrder: 'ascend',
            render: (text, record, value) => {
                return <Link id={record?.appointment?.id} to={`appointments/${record?.appointment?.id}`}>{record?.appointment?.firstName + ' ' + record?.appointment?.lastName}</Link>
            }
        },
        {
            permissionKey: 'tasks:description',
            title: <strong>Description</strong>,
            dataIndex: 'description',
            key: 'description',
            render: (text, record, value) => {
                let color = record.status === 'COMPLETED' ? 'green' : record.status === 'NEW' ? 'geekblue' : 'red';
                return <Tooltip title={record.rejectionReason}
                                style={{display: record.status === 'REJECTED' ? 'display' : 'none'}}>
                {text} <Tag color={color}>{record.status}</Tag><br/>
                {/* <Link to={`appointments/${record?.appointment?.id}`}>{record?.appointment?.appointmentType}</Link> */}
                {record?.appointment?.appointmentType}
                </Tooltip>
            }
        },
        {
            permissionKey: 'tasks:date_received',
            title: <strong>Date Received</strong>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (text, record, value) => {
                return moment(text).format("DD MMM YYYY hh:mm a");
            }
        },
        {
            permissionKey: 'tasks:clinic',
            title: <strong>Specialty</strong>,
            dataIndex: 'locationName',
            key: 'locationName',
            render: (text, record, value) => {
                return record?.appointment?.locationName;
            }
        },
        // {
        //     title: <strong>Assignee</strong>,
        //     dataIndex: 'assigneeType',
        //     key: 'assigneeType',
        //     render: (text, record, value) => {
        //         return text?.replaceAll('_', ' ');
        //     }
        // },
        {
            // title: () => renderActionColumn(status),
            permissionKey: 'tasks:actions',
            title: <strong>Actions</strong>,
            dataIndex: 'id',
            key: 'id',
            render: (text, record, value) => {
                let buttons = (status) => {
                    switch (status) {
                        case 'NEW':
                            return isCurrentPermissionKeyPermitted('tasks:new', permissionState) &&
                            <>
                                <Button
                                    id="TaskCompleteButton"
                                    type='primary'
                                    onClick={() => {
                                        setEntityId(text)
                                        setModalMessage('Mark task as Complete?')
                                        setNewStatus('COMPLETED')
                                        setConfirmationVisible(true)
                                        // showCompleteConfirmation(text)
                                    }}
                                >
                                    Complete
                                </Button>
                                <Button id="TaskRejectedButton" danger onClick={() => {
                                    setRejectionVisibility(true)
                                    setEntityId(text);
                                }}>
                                    Reject
                                </Button>
                            </>
                        case 'COMPLETED': 
                            return isCurrentPermissionKeyPermitted('tasks:completed', permissionState) && <Button id="TaskCompleteButton" onClick={() => {
                                setEntityId(text)
                                setModalMessage('Undo Completed Task?')

                                setNewStatus('NEW')
                                setConfirmationVisible(true)
                                // showCompleteConfirmation(text)
                            }}
                            >
                                Undo
                            </Button>
                        case 'REJECTED': 
                            return isCurrentPermissionKeyPermitted('tasks:rejected', permissionState) && <Button id="TaskUndoButton" onClick={() => {
                                setEntityId(text)
                                setModalMessage('Undo Rejected Task?')
                                setNewStatus('NEW')
                                setConfirmationVisible(true)
                                // showCompleteConfirmation(text)
                            }}
                            >
                                Undo
                            </Button>
                    }
                }

                return (
                    <Space>
                        {buttons(record.status)}
                    </Space>
                )
            }
        }
    ];
  const [draw, setDraw] = useState(0);
  const changeTab = key => {
    // setActiveTab(key)
    switch (key) {
      case '1':
        setStatus('NEW')
        setDraw(draw + 1);
        break;
      case '2':
        setStatus('COMPLETED')
        setDraw(draw + 1);
        break;
      case '3':
        setStatus('REJECTED')
        setDraw(draw + 1);
        break;
    }
  }
  return (
    <>
      <Modal
        visible={confirmationVisible}
        onCancel={() => setConfirmationVisible(false)}
        title={null}
        okButtonProps={{id: 'ConfirmTaskOkButton'}}
        cancelButtonProps={{id: 'ConfirmTaskCancelButton'}}
        closeIcon={<CloseOutlined id="ConfirmModalCloseIcon" />}
        onOk={() => {
          try{
            updateStatus(entityId, {
              rejectionReason: '',
              status: newStatus
            }).then((res) => {
              message.destroy();
              message.success(`${newStatus === 'NEW' ? 'Task has been successfully Updated': 'Task has been successfully Completed'}`);
              setDraw(draw + 1);
              setConfirmationVisible(false);
            });
          }
          catch {
            message.destroy();
            message.error(`${newStatus === 'NEW' ? 'Unable to Update a task' : 'Unable to Complete a task'}`);
          }
        }}
      >
        <strong>{modalMessage}</strong>
      </Modal>
      <TaskRejectionForm
        visible={rejectionVisible}
        setVisibility={setRejectionVisibility}
        id={entityId}
        onSuccess={() => {
          setDraw(draw + 1);
        }}
      />
      <Row>
        <Col>
          <LocationSelect
            locationData={locations}
            facilityValue={facilityValue}
            departmentValue={departmentValue}
            clinicValue={clinicValue}
            width='275px'
            direction='horizontal'
            onChange={(value, isDepartment, action, locationObj) => {
                initial.current = false;
                if(locationObj === null) {
                  if (action === 1) {
                    setDataTableParams((params) => ({
                      ...params,
                      facility: value,
                      department: undefined,
                      clinic: undefined
                    }));
                  }
                  if (action === 2) {
                    setDataTableParams((params) => ({
                      ...params,
                      department: value,
                      clinic: undefined
                    }));
                  }
                  if (action === 3) {
                    setDataTableParams((params) => ({
                      ...params,
                      clinic: value,
                    }));
                  }
                }
                else {
                  setDataTableParams((params) => ({
                    ...params,
                    facility: locationObj['facility'],
                    department: locationObj['department'],
                    clinic: locationObj['clinic']
                  }));
                }
              }
            }
            showSearchValue={true}
            isClinicVisible={true}
          />
        </Col>
      </Row>
      <br />
      <Space direction='vertical' size='middle'>
        <Row>
          <Col xs={12} lg={6}>
            <Space direction='vertical' size='small' className='w-100'>
              <Text strong>Date Range</Text>
              <RangePicker
                id='TaskDateRangeInput'
                format={'DD/MM/YYYY'}
                className='w-100'
                popupStyle={{
                  minWidth: '0px !important',
                }}
                defaultValue={[null, null]}
                onCalendarChange={(dates, _dateStrings, _info) => {
                  initial.current = false;
                  if (dates) {
                    setDataTableParams((params) => ({
                      ...params,
                      startDate: dates[0]?.format('YYYY-MM-DD'),
                      endDate: dates[1]?.format('YYYY-MM-DD'),
                    }));
                  } else {
                    setDataTableParams((params) => ({
                      ...params,
                      startDate: null,
                      endDate: null,
                    }));
                  }
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={4}>
            <Space direction='vertical' size='small' className='w-100'>
              <Text strong>Status</Text>
              <Select
                id="TaskStatusSelect"
                className='w-100'
                showSearch
                defaultValue={'NEW'}
                onChange={(value) => {
                  initial.current = false;
                  setDataTableParams((params) => ({
                    ...params,
                    status: value,
                  }))
                }
                }
              >
                <Option key={1} value={'ALL'}>
                  All
                </Option>
                <Option key={2} value={'NEW'}>
                  New
                </Option>
                <Option key={3} value={'COMPLETED'}>
                  Completed
                </Option>
                <Option key={4} value={'REJECTED'}>
                  Rejected
                </Option>
              </Select>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <Space direction='vertical' size='small' className='w-100'>
              <Text strong>MRN</Text>
              <Input
                id="TaskMRNInput"
                className='w-100'
                allowClear
                value={dataTableParams.mrn}
                placeholder={'Search by MRN'}
                type='text'
                name='mrn'
                onChange={(e) => {
                  initial.current = false;
                  const value = e.target.value;
                  setDataTableParams((params) => ({
                    ...params,
                    mrn: value,
                  }));
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={4}>
            <Space direction='vertical' size='small' className='w-100'>
              <Text strong>Patient Name</Text>
              <Input
                id="TaskPatientNameInput"
                className='w-100'
                allowClear
                value={dataTableParams.patientName}
                placeholder={'Search by name'}
                type='text'
                name='patientName'
                onChange={(e) => {
                  initial.current = false;
                  const value = e.target.value;
                  setDataTableParams((params) => ({
                    ...params,
                    patientName: value,
                  }));
                }}
              />
            </Space>
          </Col>
          <Col xs={12} lg={4}>
            <Space direction='vertical' size='small' className='w-100'>
              <Text strong>&nbsp;</Text>
              <Button
                id='appointmentsSearch'
                type={`${criteriaNotSearched() ? 'primary' : '' }`}
                className={`w-100 ${!criteriaNotSearched() ? 'not-searched': ''}`}
                onClick={() => setSearch((current) => current + 1)}
              >
                Search
              </Button>
            </Space>
          </Col>
        </Row>
        <br />
      </Space>
      {/* <Tabs
        onChange={changeTab}
    >
        {permissifiedTabs && permissifiedTabs.map(permissifiedTab => (
            <TabPane tab={permissifiedTab.status} key={permissifiedTab.key}> */}
      {locationCalled > 0 ? <CRUDDataTable
        draw={draw}
        entity='Tasks'
        customFilters={customFilters}
        continuousFetchInterval={30000}
        fetchDataFunction={async (params) => {
          //params.status = status;
          return await getTaskDatatable(params);
        }}
        createModal={<></>}
        columns={permissifyTableColumns(columns, permissionState)}
        sortOrder='createdAt'
        button={<></>}
        showSearchBar={false}
      /> : <></>}
      {/* </TabPane>
        ))}
    </Tabs> */}
    </>
  );
}
export default Tasks