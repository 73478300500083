import { SET_FACILITY, SET_DEPARTMENT, SET_CLINIC } from 'redux/actions/types/collateralList';

const INITIAL_STATE = {
    facilities: [],
    departments: [],
    clinics: []
};

const collateralListReducer = (state = INITIAL_STATE, action) => {
    const {type, payload, error } = action;
    switch(type) {
        case SET_FACILITY: {
            return {
                ...state,
                facilities: payload
            }
        }
       
        case SET_DEPARTMENT: {
            return {
                ...state,
                departments: payload
            }
        }

        case SET_CLINIC: {
            return {
                ...state,
                clinics: payload
            }
        }

        default:
            return state;
    }
};

export default collateralListReducer;
