import { RingProgress } from '@ant-design/charts';
import _ from 'lodash';

export const DemoRingProgress = ({ animation, data, width = 100, height = 120, fontSize = "10px", lineHeight = "14px" }) => {
    var config = {
        height,
        width,
        autoFit: false,
        percent: data?.value,
        innerRadius: 0.85,
        color: [data?.color, '#E8EDF3'],
        radius: 0.98,
        statistic: {
            title: false,
            content: {
                style: {
                    color: '#363636',
                    fontSize,
                    lineHeight,
                    fontWeight: 'bold',
                    padding: '8px 5px 0px 5px',
                    marginBottom: "5px",
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                },
                content: `${data?.title} \n ${data?.description} `,
            },
        },
    }
    return <RingProgress animation={animation} {...config} />
}