import React, {useRef} from "react";
import {Button, Col, message, Modal, Row} from "antd";
import {Formik} from "formik";
import {Form, Input, SubmitButton} from "formik-antd";
import {updateStatus} from "../../api/tasks";
import { CloseOutlined } from "@ant-design/icons";

const TaskRejectionForm =
  ({id, visible, setVisibility, onSuccess}) => {

    const create = values => {
      try{
        updateStatus(id, {
          rejectionReason: values.reason,
          status: 'REJECTED'
        })
          .then(res => {
            message.destroy();
            message.success('Task has been successfully Rejected');
            setVisibility(false);
            onSuccess();
          })
      }
      catch {
        message.destroy();
        message.error('Unable to Reject a task')
      }
    }
    const formikRef = useRef(null);

    return (<>
      <Modal
        title='Please enter reason for rejecting this task'
        visible={visible}
        closeIcon={(<CloseOutlined id='closeTaskRejectionModal' />)}
        onCancel={() => {
          setVisibility(false)
        }}
        footer={null}
        destroyOnClose
      >
        <Formik
          innerRef={formikRef}
          onSubmit={values => create(values)}
          initialValues={{}}
          render={() => (
            <Form layout='vertical' colon={false}>
              <Form.Item label='Reason' name='reason' className='mb-4'>
                <Input.TextArea id='taskRejectionReasonTextarea' name='reason'/>
              </Form.Item>
              <Row gutter={4} className="d-flex justify-content-end">
                <Col>
                  <Button id='taskRejectionCancelButton' type="dashed" shape="round" onClick={() => setVisibility(false)}>Cancel</Button>
                </Col>
                <Col>
                  <SubmitButton id='taskRejectionRejectButton'>Reject</SubmitButton>
                </Col>
              </Row>
            </Form>
          )}
        />

      </Modal>

    </>)

  }

export default TaskRejectionForm;