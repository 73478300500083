import { Row ,Col , Modal, Typography , Input, Select } from "antd";
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import * as Yup from 'yup';

const RoomSelectionModal = ({ appointment, visible, setVisibility, changeAppointment}) => {
    const { Text } = Typography;
    const { Option } = Select;

    const RoomValidate = Yup.object().shape({
        roomId: Yup.mixed().required('Please select a room.')
    });

    return (
        <Modal
        title={<Text id="RoomSelectionTitle">Room Selections</Text>}
        visible={visible}
        onCancel={() => setVisibility(false)}
        destroyOnClose={true}
        footer={null}>
            <Formik
            initialValues={{ roomId: null }}
            onSubmit={async (value) => { changeAppointment(value) }}
            validationSchema={RoomValidate}>
            {({
                setFieldValue
            }) => (
                <Form layout='vertical' colon={false}>
                    <Form.Item name='roomId' label='Select Room'>
                        <Select 
                        showSearch
                        filterOption={(inputValue, option) =>
                        option.props.children
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        } 
                        id="RoomSelectModalSelect" name='roomId' onChange={(value) => {setFieldValue('roomId',value)}}>
                            {
                                appointment.queue.rooms.map(room => {
                                    return <Option value={room.id}>{room.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Row gutter={4} className='d-flex justify-content-end'>
                        <Col >
                            <SubmitButton id="RoomSelectModalSubmit">Submit</SubmitButton>
                        </Col>
                    </Row>
                </Form>
            )}
            </Formik>
            
        </Modal>
    )
}

export default RoomSelectionModal;