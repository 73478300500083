import { SET_STAGING, SET_HELP_SCREEN_DATA, SET_ROW_COLOR } from 'redux/actions/types/systemProperties';

const INITIAL_STATE = {
    staging: {},
    helpScreenData: '',
    rowColor: true
};

const systemPropertiesReducer = (
    state = INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case SET_STAGING: {
            return {
                ...state,
                staging: action.data
            }
        }
        case SET_HELP_SCREEN_DATA: {
            return {
                ...state,
                helpScreenData: action.data
            }
        }
        case SET_ROW_COLOR: {
            return {
                ...state,
                rowColor: action.data
            }
        }

        default:
            return state;
    }
}

export default systemPropertiesReducer;
