import axios from 'axios';
import React from "react";
import {store} from "../index";
import {ERROR_ACTIONS} from "../redux/types/error-types";
import {refreshTokenMSAL} from 'api/msal';
import {setTokens} from "redux/actions/auth";
import {saveAuth} from "utils/helpers";

export function createPublicAxios (config) {
    return axios.create(config);
}

export const createAxios = (config) => {
    const instance = axios.create(config);
    instance.interceptors.request.use(async config => {
        let token = await refreshTokenMSAL()
        if (token) {
            sessionStorage.setItem('LastUsedJwtToken', JSON.stringify(token))
        } else {
            const lastUsedToken = sessionStorage.getItem('LastUsedJwtToken');
            if (lastUsedToken) {
                token = JSON.parse(lastUsedToken)
            }
        }
        if (token) {
            config.headers.common['Authorization'] = `Bearer ${token.idToken}`;
            config.headers.common['Accept'] = '*/*';
            config.headers.common['Access-Control-Allow-Origin'] = "*";
            return config
        } else {
            console.log("Didn't get a token from refreshMsal. logging out.")
            const access_token = "";
            const refresh_token = "";
            const expiryDate = "";
            const isUserAuthenticated = false;

            saveAuth(access_token, refresh_token, expiryDate);

            store.dispatch(setTokens({
                access_token,
                refresh_token,
                isUserAuthenticated,
            }));
        }
    }, error => {
        return error;
    })

    const onSuccess = () => {
        return response => response;
    }

    function onError(error) {
        if (error?.errorCode === "consent_required" || error?.errorCode === "interaction_required" 
            || error?.errorCode === "login_required" || error?.response?.status === 401) {

            const access_token = "";
            const refresh_token = "";
            const expiryDate = "";
            const isUserAuthenticated = false;
    
            saveAuth(access_token, refresh_token, expiryDate);
            console.log("Got error on axios response. Logging out.", error)
            store.dispatch(setTokens({
                access_token,
                refresh_token,
                isUserAuthenticated,
            }));
        } else {
            const errorResponseObject = error.response;
            const errorJSONObject = error.toJSON ? error.toJSON() : null;
            let message = errorResponseObject?.data?.message ? errorResponseObject?.data?.message : errorJSONObject?.message;
            let details = errorResponseObject?.data?.details ? errorResponseObject?.data?.details : errorJSONObject?.details;

            if (errorResponseObject?.data?.data?.message){
                message = errorResponseObject?.data?.data?.message
            }
            if (errorResponseObject?.data?.data?.details){
                details = errorResponseObject?.data?.data?.details
            }
            store.dispatch({
                type: ERROR_ACTIONS.SET_ERROR_MESSAGE,
                payload: {message, details}
            })
            return Promise.reject(error);
        }
    }

    instance.interceptors.response.use(onSuccess(), onError)

    return instance;
}