import { Modal, Typography, Row, Col } from "antd";
import { Formik } from 'formik';
import { Form, SubmitButton, Input } from 'formik-antd';

const ResolvePatientDetailsChangedModal = ({ visible, setVisibility, data, resolveChange }) => {
    const { Text } = Typography;

    return (
        <Modal
            title={<Text>Patient Details have changed. Please update in relevant systems</Text>}
            visible={visible}
            onCancel={() => setVisibility(false)}
            footer={null}
        >
            <Formik
                initialValues={{ description: data?.patientDetailsChangedDesc ?? '' }}
                onSubmit={resolveChange}
            >
                <Form layout='vertical' colon={false}>
                    <Form.Item name='description'>
                        <Input.TextArea className="disabledvaluetextarea" name='description' disabled={true} rows={4}/>
                    </Form.Item>
                    <Row gutter={4} className='d-flex justify-content-center'>
                        <Col>
                            <SubmitButton>Mark as Actioned</SubmitButton>
                        </Col>
                    </Row>
                </Form>
            </Formik>
        </Modal>
    )
}

export default ResolvePatientDetailsChangedModal;