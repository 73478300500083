import { combineReducers } from 'redux';
import uiReducer from './uiReducer';
import {
    systemProperties,
    systemPropertiesHasErrored,
    systemPropertiesIsLoading,
} from './systemProperties';
import {
    locations,
    locationsHasErrored,
    locationsIsLoading,
} from './locations';
import {
    collateralDetails,
    collateralDetailsHasErrored,
    collateralDetailsIsLoading,
    collateralListSuccess,
    collaterals,
    collateralsHasErrored,
    collateralsIsLoading,
} from './collaterals';
import {
    appointmentDetails,
    appointmentReducer,
    appointments,
    appointmentsHasErrored,
    appointmentsIsLoading,
} from './appointments';
import { visitors, visitorsHasErrored, visitorsIsLoading } from './visitors';
import { messages, messagesHasErrored, messagesIsLoading } from './messages';
import { notes, notesHasErrored, notesIsLoading } from './notes';
import {
    questions,
    questionsHasErrored,
    questionsIsLoading,
} from './questions';
import {
    documents,
    documentsHasErrored,
    documentsIsLoading,
} from './documents';
import { commonUtils } from './common-utils';
import zoomReducer from 'redux/reducers/zoomReducer';

import appointment from './appointment';
import formBuilderReducer from './formBuilderReducer';
import tenantReducer from './tenantReducer';
import permissionsReducer from './permissions-reducer';
import brandingReducer from './brandingReducer';
import messageTemplateReducer from './messageTemplateReducer';
import systemPropertiesReducer from './systemPropertiesReducer';
import appointmentFormsReducer from './appointmentFormsReducer';
import authReducer from './auth';
import userReducer from './userReducer';
import errorReducer from './error-reducer';
import collateralListReducer from './collateralListReducer';
import { filterReducer } from './filter';

const rootReducer = combineReducers({
    appointment,
    systemProperties,
    systemPropertiesHasErrored,
    systemPropertiesIsLoading,
    locations,
    locationsHasErrored,
    locationsIsLoading,
    collaterals,
    collateralsHasErrored,
    collateralsIsLoading,
    collateralDetails,
    collateralDetailsHasErrored,
    collateralDetailsIsLoading,
    collateralListSuccess,
    appointments,
    appointmentsHasErrored,
    appointmentsIsLoading,
    appointmentReducer,
    visitors,
    visitorsHasErrored,
    visitorsIsLoading,
    messages,
    messagesHasErrored,
    messagesIsLoading,
    notes,
    notesHasErrored,
    notesIsLoading,
    questions,
    questionsHasErrored,
    questionsIsLoading,
    documents,
    documentsHasErrored,
    documentsIsLoading,
    commonUtils: commonUtils,
    ui: uiReducer,
    zoom: zoomReducer,
    formBuilder: formBuilderReducer,
    tenant: tenantReducer,
    permissions: permissionsReducer,
    branding: brandingReducer,
    sysProperties: systemPropertiesReducer,
    appointmentForms: appointmentFormsReducer,
    messageTemplates: messageTemplateReducer,
    auth: authReducer,
    error: errorReducer,
    collateralList: collateralListReducer,
    filterLoading: filterReducer,
    user: userReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
