import { useEffect, useState } from 'react';

const CalculateTime = ({record, calculateTime, isSetInterval, timeInterval}) => {
    
    const [time, setTime] = useState(calculateTime(record));

    useEffect(() =>{
        let interval;
        setTime(calculateTime(record));
        if(isSetInterval) {
            interval = setInterval(()=> {
                setTime(calculateTime(record));
            }, timeInterval);
        }
        return () => {
            clearInterval(interval);
        }
    }, [record]);

    return time === '0:00' ? '' : time;
};

export default CalculateTime;