import { SET_FACILITY, SET_DEPARTMENT, SET_CLINIC } from 'redux/actions/types/collateralList';

export const setFacility = (facilities) => {
    return {
        type: SET_FACILITY,
        payload: facilities
    }
}

export const setDepartment = (departments) => {
    return {
        type: SET_DEPARTMENT,
        payload: departments
    }
}

export const setClinic = (clinics) => {
    return {
        type: SET_CLINIC,
        payload: clinics
    }
}