import {
    SET_BRANDING,
    SET_BRANDING_LOGO,
    SET_BRANDING_TENANT_UNIQUE_ID,
    SET_CENTRALISE_BRANDING,
    SET_BRANDING_LIST,
    RESET_BRANDING,
    SET_ACTIVE_BRANDING_TENANT_ID,
} from 'redux/actions/types/branding';

// const INITIAL_STATE = [{
//     centraliseBranding: true,
//     tenantUniqueId: null,
//     logo: null,
//     theme: null,
//     page: null
// }];

const INITIAL_STATE = {
    activeBrandingId: null,
    centraliseBranding: true,
    list: []
};

export default (state = INITIAL_STATE, action) => {
    const {
        id,
        type,
        branding,
        logo
    } = action;

    switch(type) {
        case SET_CENTRALISE_BRANDING: {
            return {
                ...state,
                centraliseBranding: action.payload
            }
        }

        case SET_BRANDING_TENANT_UNIQUE_ID: {
            return {
                ...state,
                tenantUniqueId: action.payload
            }
        }

        case SET_BRANDING: {
            return {
                ...state,
                ...branding
            }
        }

        case SET_ACTIVE_BRANDING_TENANT_ID: {
            return {
                ...state,
                activeBrandingId: id
            }
        }

        case SET_BRANDING_LIST: {
            return {
                ...state,
                list: [...branding]
            }
        }

        case SET_BRANDING_LOGO: {
            let newList = [...state.list];
            const currentTenantUniqueId = state.activeBrandingId;
            const currentBrandingIdx = newList.findIndex(brandingItem => brandingItem.tenantUniqueId === currentTenantUniqueId);

            const currentBranding = newList[currentBrandingIdx];

            const newBranding = {
                ...currentBranding,
                logo
            };

            newList[currentBrandingIdx] = newBranding;

            return {
                ...state,
                list: newList
            }
        }

        default:
            return state
    }
}