import React, { useEffect, useState } from 'react';
import 'styles/Nav.scss';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import MenuOpen from '@material-ui/icons/MenuOpen';
import Menu from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import { Button, Modal } from 'antd';
import { connect, useSelector, useDispatch } from 'react-redux';
import { getAllSettings } from 'api/systemSettings';
import { getPublicSetting } from 'api/public/settings';
import { toggleSideBarVisibility } from 'redux/actions/ui';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from "hooks";
import { removeAuth } from "utils/helpers";
import Interweave from 'interweave';
import { setHelpScreenData, setRowColor } from 'redux/actions/systemProperties';
import { CloseOutlined } from '@ant-design/icons';

function SlickTopMenu(props) {
  const { fullName, isSidebarVisible, toggleSideBarVisibility } = props;
  const { confirm } = Modal;
  const tenantState = useSelector((state) => state.tenant);
  const sysPropertiesState = useSelector((state) => state.sysProperties);
  const history = useHistory();
  const { logout } = useAuthContext();
  const [isFaqModalVisible, setFaqModalVisibility] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {

      //Keep Wait Time but change how its calculated (Start Consult time less Check In time) and show in real time updated every 60 secs
      await getPublicSetting({
        category: 'TENANT',
        dataType: 'NUMBER',
        name: 'WAIT_TIME_INTERVAL',
        defaultValue: 60
      })
      
      //Keep Time in Consult
      await getPublicSetting({
        category: 'TENANT',
        dataType: 'NUMBER',
        name: 'TIME_IN_CONSULT_INTERVAL',
        defaultValue: 30
      })


      const response = await getPublicSetting({
        category: 'TENANT',
        dataType: 'HTML',
        name: 'TENANT_FAQS_SCREEN',
        defaultValue: ''
      })
      
      if (response?.data?.value) {
        dispatch(setHelpScreenData(response.data.value));
      }


      const respRow = await getPublicSetting({
        category: 'TENANT',
        dataType: 'BOOLEAN',
        name: 'TENANT_SHOW_FULL_BACKGROUND_COLOR',
        defaultValue: true
      })
      
      if (respRow?.data?.value === 'false') {
        dispatch(setRowColor(false));
      } else {
        dispatch(setRowColor(true));
      }
    }
    fetchData();
  }, []);

  const logoutApp = () => {
    logout();
    removeAuth();
    history.push('/');
  };

  const showLogoutModal = (e) => {
    confirm({
      title: 'Confirm',
      content: 'Are you sure you want to logout',
      okText: 'Yes',
      cancelText: 'No',
      maskClosable: true,
      okButtonProps : { id:'logoutConfirmOkButton' },
      cancelButtonProps : {  id:'logoutConfirmCancelButton' },
      onOk: logoutApp,
    });
  };

  return (
    <>
      <Modal
          title='Help'
          visible={isFaqModalVisible}
          onCancel={() => setFaqModalVisibility(false)}
          closeIcon={<CloseOutlined id="FAQModalCloseIcon"/>}
          destroyOnClose={true}
          footer={null}
          centered
          width={'50%'}
          bodyStyle={{height: '90vh' , overflowY: 'auto'}}
      >
        <Interweave content={sysPropertiesState.helpScreenData} />
      </Modal>
      <Navbar className='fivef-navbar' expand='lg' bg='light' variant='light'>
        <Navbar.Brand
          className='fivef-navbar-brand text-center'
          href='#home'
          style={{
            textTransform: 'uppercase',
            lineHeight: '15px',
          }}
        >
          {tenantState.branding.page.navbarHeaderTitle}
          <br />
          <small
            style={{
              color: `${tenantState.branding.theme.palette.primary.light}`,
              fontSize: '11px',
              fontWeight: '700',
              letterSpacing: '1px',
            }}
          >
            {tenantState?.branding?.page?.appSubTitle || 'Digital Patient Journey'}
          </small>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        <Button
          id="SideBarTogglerButton"
          className='btn-toggle-mb-sidebar d-block d-sm-block d-lg-none'
          onClick={() => toggleSideBarVisibility()}
        >
          {isSidebarVisible ? (
            <MenuOpen fontSize='large' />
          ) : (
            <Menu fontSize='large' />
          )}
        </Button>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link onClick={() => toggleSideBarVisibility()}>
              {isSidebarVisible ? (
                <MenuOpen fontSize='large' />
              ) : (
                <Menu fontSize='large' />
              )}
            </Nav.Link>
          </Nav>
          <Nav className='ml-auto'>
            <Nav.Link className='fivef-user-dropdown'>
              <PersonIcon id="TopMenuUserIconButton" fontSize='large' />
            </Nav.Link>
            <NavDropdown
              className='fivef-user-dropdown'
              title={`Welcome ${fullName} `}
              id='collasible-nav-dropdown'
            >
              <NavDropdown.Item href='#' id="topMenuLogout" onClick={showLogoutModal} active={false}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
              <HelpIcon
                id="TopMenuHelpIconButton"
                fontSize='large'
                className='hover:cursor-pointer mt-2 ml-2'
                onClick={() => setFaqModalVisibility(true)}
              />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default connect(
  (state) => {
    return {
      isSidebarVisible: state.ui.isSidebarVisible,
    };
  },
  (dispatch) => {
    return {
      toggleSideBarVisibility: () => dispatch(toggleSideBarVisibility()),
    };
  }
)(SlickTopMenu);
